import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function validateEmail(emailListString: string): boolean {
  let regex =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let value = String(emailListString).toLowerCase().trim();
  value = value.replace(";", ",");
  let emailList: string[] = value.split(',');
  let valid = true;
  emailList.forEach(email => {
    if (email === "" || !regex.test(email.trim())) {
      valid = false;
    }
  })
  return valid;
}

export function emailListValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
        let valid = validateEmail(control.value);
        return !valid ? {emailList: {value: control.value, message: "Not a valid email list!"}}: null;
    }


}
