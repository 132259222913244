import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile/profile.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { FullScreenComponent } from './fullscreen/fullscreen.component';
import { CoreModule } from './core.module';
import { NotificationComponent } from './notification/notification.component';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { RouterModule } from '@angular/router';
import { SpinnerComponent } from './spinner/spinner.component';
import { VerticalMenuComponent } from "./menu/vertical-menu/vertical-menu.component";

@NgModule({
  imports: [CommonModule, CoreModule, RouterModule],
  declarations: [
    ProfileComponent,
    UserMenuComponent,
    FullScreenComponent,
    NotificationComponent,
    TitleBarComponent,
    SpinnerComponent,
    VerticalMenuComponent,
  ],
  exports: [
    ProfileComponent,
    UserMenuComponent,
    FullScreenComponent,
    NotificationComponent,
    TitleBarComponent,
    SpinnerComponent,
    VerticalMenuComponent,
  ]
})
export class ToolbarModule {}
