import { Component, Inject, OnInit, Optional } from '@angular/core';
import * as moment from 'moment';
import { CadSystemService, SYSTEM_SERVICE } from './cad-system-service.interface';

@Component({
  selector: 'cad-lib-system-time-footer',
  templateUrl: './system-time-footer.component.html',
  styleUrls: ['./system-time-footer.component.scss']
})
export class SystemTimeFooterComponent implements OnInit {

  private FORMAT: string = 'MM/DD/YYYY LT';
  private TIME_UPDATE: number = 10000;
  private SYSTEM_TIME_UPDATE: number = 600000;

  public time: string = "Syncing..."

  private serverTime: moment.Moment;
  private msOffset: number;

  constructor(@Inject(SYSTEM_SERVICE) @Optional() protected systemService: CadSystemService) { }

  ngOnInit(): void {
    this.getSystemTime();

    this.updateTime();
  }

  updateTime(): void {
    if (this.msOffset) {
      let machineTime: moment.Moment = moment();
      machineTime.add(this.msOffset, 'ms');
      this.time = machineTime.format(this.FORMAT);
    }

    setTimeout(() => {
      this.updateTime();
    }, this.TIME_UPDATE);
  }

  getSystemTime(): void {
    this.systemService.getSystemDateTime().subscribe((date: Date) => {
      this.serverTime = moment(date);
      this.time = this.serverTime.format(this.FORMAT);
      this.msOffset = this.serverTime.toDate().getTime() - moment().toDate().getTime();

      setTimeout(() => {this.getSystemTime()}, this.SYSTEM_TIME_UPDATE);
      });
  }
}
