export class Menu {
  public canViewAnyChildren: boolean = true;  //dynamic indicator to tell us if one or more children are visible.
  public id: number;
  public title: string;
  public roles: string[];
  public routerLink: string;
  public queryParams: any;
  public href: string;
  public icon: string;
  public target: string;
  public hasSubMenu: boolean;
  public parentId: number;

  constructor(id: number,
    title: string,
    roles: string[],
    routerLink: string,
    queryParams: any,
    href: string,
    icon: string,
    target: string,
    hasSubMenu: boolean,
    parentId: number
  ) {
    this.id = id;
    this.title = title;
    this.roles = roles;
    this.routerLink = routerLink;
    this.queryParams = queryParams;
    this.href = href;
    this.icon = icon;
    this.target = target;
    this.hasSubMenu = hasSubMenu;
    this.parentId = parentId;
  }

}
