import { Injectable } from '@angular/core';
import {ColDef, ColumnApi, GridApi} from "ag-grid-community";
import * as moment from 'moment';
import { PhonePipe } from '../../pipes/phone.pipe';
import { CheckboxEditorComponent } from 'libs/shared-ui/src/lib/table/checkbox-editor/checkbox-editor.component';
import { DropdownEditorComponent } from 'libs/shared-ui/src/lib/table/drop-down/drop-down-editor';
import { DateEditorComponent } from 'libs/shared-ui/src/lib/table/date-editor/date-editor.component';
import { DoubleEditorComponent } from 'libs/shared-ui/src/lib/table/double-editor/double-editor.component';
import { cad } from '@comm-apps/common';
import TableState = cad.TableState;
import * as _ from 'lodash-es';
import { MatDialog } from '@angular/material/dialog';
import { TableLayoutComponent } from 'libs/shared-ui/src/lib/dialogs/table-layout/table-layout.component';
import { map, Observable, of, tap } from 'rxjs';
import { AutoCompleteEditorComponent } from 'libs/shared-ui/src/lib/table/auto-complete-editor/auto-complete-editor.component';

@Injectable({
  providedIn: 'root'
})
export class AggridService {

  constructor(
    private dialog: MatDialog
  ) {}

  public columnTypes = {
    editable: {editable: true},
    dateColumn: {
      valueFormatter: AggridService.yyyymmddToMinuteFormatter,
      // cellEditor: 'datePicker',
      cellEditorFramework: DateEditorComponent,
    },
    autoComplete: {
      cellEditorFramework: AutoCompleteEditorComponent,
    },
    checkbox: {
      cellRenderer: "checkboxEditor"
    },
    dropdown: {
      cellEditorFramework: DropdownEditorComponent,
    },
    currencyColumn: {
      valueFormatter: this.twoDigitCurrencyFormatter,
      excludeSortByValueFormatter: true
    },
    percentageColumn: {
      valueFormatter: this.percentageFormatter,
      excludeSortByValueFormatter: true
    },
    commaColumn: {
      valueFormatter: this.commaFormatter,
      excludeSortByValueFormatter: true
    },
    yesNo: {
      valueFormatter: this.yesNoFormatter
    },
  };
  
  public sortAndFilterOnValueFormatter(columnDefs: ColDef[], columnTypes: any) {
    columnDefs.forEach(columnDef => {
      if (Array.isArray(columnDef.type)) {
        (columnDef.type as string[]).forEach(t => {
          if (columnTypes[t] && columnTypes[t].valueFormatter) {
            columnDef.getQuickFilterText = columnTypes[t].valueFormatter;
            columnDef.filter = 'agSetColumnFilter';
            columnDef.filterParams = { valueFormatter: columnTypes[t].valueFormatter };
            if (!columnTypes[t].excludeSortByValueFormatter && !columnTypes[t].comparator) {
              columnDef.comparator = this.getComparator(columnTypes[t].valueFormatter, columnDef);
            }
          }
        });
      }
      if (!columnDef.comparator) {
        columnDef.comparator = this.defaultComparator;
      }
    });
  }

  private getComparator = (valueFormatter: (params: any) => any, colDef: ColDef) =>
    (valueA, valueB, nodeA, nodeB) => {
      const paramsA = { value: valueA, colDef };
      if (nodeA) {
        paramsA['data'] = nodeA.data;
      }
      const paramsB = { value: valueB, colDef };
      if (nodeB) {
        paramsB['data'] = nodeB.data;
      }
      const formattedValueA = valueFormatter(paramsA) || valueA;
      const formattedValueB = valueFormatter(paramsB) || valueB;
      return this.defaultComparator(formattedValueA, formattedValueB);
  }

  private defaultComparator = (valueA, valueB) => {
    const isValueANumber = !isNaN(valueA);
    const isValueBNumber = !isNaN(valueB);
    if (!(valueA || isValueANumber)) valueA = '';
    if (!(valueB || isValueBNumber)) valueB = '';
    const isValueAString = typeof valueA === 'string';
    const isValueBString = typeof valueB === 'string';
    if (isValueAString && isValueBString) return valueA.toLowerCase().trim().localeCompare(valueB.toLowerCase().trim());
    else if (isValueAString && isValueBNumber) return valueA ? 1 : -1;
    else if (isValueANumber && isValueBString) return valueB ? -1 : 1;
    else if (isValueANumber || isValueBNumber) {
      valueA = valueA || Number.MIN_SAFE_INTEGER;
      valueB = valueB || Number.MIN_SAFE_INTEGER;
      if (valueA === valueB) return 0;
      return (valueA > valueB) ? 1 : -1;
    } else return 0;
  }

  public exportUsingRendererGridOptions = {
    defaultExcelExportParams: {
      processCellCallback: params => this.processCellCallback(params)
    },
    defaultCsvExportParams: {
      processCellCallback: params => this.processCellCallback(params)
    }
  };

  private processCellCallback = params => {
    if (!params.value) return params.value;
    const colDef = params.column.getColDef();
    if (colDef.cellRenderer === 'agGroupCellRenderer') {
      const groupValSeparator = ' -> ';
      const groupColDefs = params.context.columnDefs.filter(colDef => colDef.rowGroup);
      const splitVal = params.value.split(groupValSeparator);
      for (let i = 1; i < splitVal.length; i++) {
        const groupColDef = groupColDefs[i - 1];
        if (groupColDef.type) {
          for (const type of groupColDef.type) {
            const columnType = params.context.columnTypes[type];
            const renderer = columnType.cellRenderer;
            const formatter = columnType.valueFormatter;
            const hasRenderer = typeof renderer === 'function';
            if (hasRenderer || typeof formatter === 'function') {
              const rendFormParams = {value: splitVal[i], node: params.node, colDef: groupColDef};
              splitVal[i] = typeof renderer === 'function' ? renderer(rendFormParams) : formatter(rendFormParams);
              break;
            }
          }
        }
      }
      return splitVal.join(groupValSeparator);
    }
    const renderer = colDef.cellRenderer;
    const formatter = colDef.valueFormatter;
    const rendFormParams = {value: params.value, data: params.node.data, colDef};
    return typeof renderer === 'function' ? renderer(rendFormParams) : typeof formatter === 'function' ? formatter(rendFormParams) : params.value;
  }

  public frameworkComponents = {
    checkboxEditor: CheckboxEditorComponent,
    doubleEditor: DoubleEditorComponent
  };

  public bestFit(columnApi: ColumnApi, gridApi: GridApi): void {
    columnApi.autoSizeAllColumns();

    let gridWidth: number = gridApi.getHorizontalPixelRange().right - gridApi.getHorizontalPixelRange().left - 4;
    let totalColWidth: number = 0;
    let numVisCols: number = 0;
    columnApi.getColumns().forEach(function (column) {
      if (column.isVisible()) {
        totalColWidth += column.getActualWidth();
        numVisCols++;
      }
    });

    let colDif: number = Math.round((gridWidth - totalColWidth) / numVisCols);
    columnApi.getColumns().forEach(column => {
      columnApi.setColumnWidth(column.getColId(), column.getActualWidth() + colDif);
    });
  }

  public restoreState(state: TableState = {}, gridColumnApi: any, gridOptions: any, timeout: number = 50): void {
    setTimeout(() => {
      if (state.columnState) gridColumnApi.applyColumnState({state: state.columnState, applyOrder: true});
      if (state.groupState) gridColumnApi.setColumnGroupState(state.groupState);
      if (state.filterState) gridOptions.api?.setFilterModel(state.filterState);
    }, timeout);
  }

  public resetState(gridColumnApi: any, gridOptions: any): void {
    gridColumnApi.resetColumnState();
    gridColumnApi.resetColumnGroupState();
    gridOptions.api?.setFilterModel(null);
  }

  public buildTableState(name: string, gridColumnApi: any, gridOptions: any): TableState {
    let state: TableState = { name };
    state.columnState = gridColumnApi.getColumnState();
    state.groupState = gridColumnApi.getColumnGroupState();
    state.filterState = gridOptions.api?.getFilterModel();
    return state;
  }

  public loadOrDeleteState(userPrefs: Object, tableStateProp: string, currentStateNameProp: string): Observable<any> {
    const dialogRef = this.dialog.open(TableLayoutComponent, {
      height: '220px',
      width: '400px',
      data: {
        tableStates: userPrefs[tableStateProp],
        currTableState: userPrefs[currentStateNameProp]
      }
      
    });

    return dialogRef.afterClosed().pipe(tap(res => {
      if (!userPrefs[tableStateProp]) userPrefs[tableStateProp] = [];
      if (res && res.name) {
        if (res.delete) {
          _.remove(userPrefs[tableStateProp], { name: res.name });
          if (res.name === userPrefs[currentStateNameProp]) delete userPrefs[currentStateNameProp];
        }
        else userPrefs[currentStateNameProp] = res.name;
      }
      return res;
    }));
  }

  public saveState(isTableStateDefault: boolean, userPrefs: Object, tableStateProp: string, currentStateNameProp: string, gridColumnApi: any, gridOptions: any): Observable<any> {
    const dialogRef = this.dialog.open(TableLayoutComponent, {
      height: '220px',
      width: '400px',
      data: {
        saveMode: true,
        tableStates: userPrefs[tableStateProp],
        currTableState: ! isTableStateDefault ? userPrefs[currentStateNameProp] : ''
      }
      
    });
    return dialogRef.afterClosed().pipe(tap(res => {
      if (!userPrefs[tableStateProp]) userPrefs[tableStateProp] = [];
      if (res && res.name) {
        let crspTableStateIndex = _.findIndex(userPrefs[tableStateProp], { name: res.name });

        if (crspTableStateIndex >= 0) {
          userPrefs[tableStateProp][crspTableStateIndex] = this.buildTableState(res.name, gridColumnApi, gridOptions);
        } else {
          userPrefs[tableStateProp].push(this.buildTableState(res.name, gridColumnApi, gridOptions));
        }

        userPrefs[currentStateNameProp] = res.name;
      }
      return res;
    }));
  }

  public scrollToItem(gridApi, nodePropertyName: string, valueToFind) {
    if (valueToFind) {
      let targetNode = null;

      //Locate the node we want to scroll to
      gridApi.forEachLeafNode(function(node, index) {
        if (node[nodePropertyName] == valueToFind) {
          targetNode = node;
        }
      });

      //if we found a node to scroll to
      if(targetNode) {
        //expand all relevant parents
        while (targetNode.parent) {
          gridApi.setRowNodeExpanded(targetNode, true);
          targetNode = targetNode.parent;
        }

        //scroll to the node
        gridApi.ensureNodeVisible(function (a) {
          return a[nodePropertyName] == valueToFind;
        }, 'top')
      }
    }
  }

  public static yyyymmddFormatter(params) {
    if (params && params.node && params.node.group) {
      return undefined;
    }

    return (params.value) ? moment(params.value).format('YYYYMMDD') : '';
  }

  public static yyyymmddDashFormatter(params) {
    if (params && params.node && params.node.group) {
      return undefined;
    }

    return (params.value) ? moment(params.value).format('YYYY-MM-DD') : '';
  }

  public static yyyymmddToMinuteFormatter(params) {
    if (params && params.node && params.node.group) {
      return undefined;
    }

    return (params.value) ? moment(params.value).format('YYYY-MM-DD HH:mm') : '';
  }


  public static phoneFormatter(params) {
    if (params && params.value) {
      return new PhonePipe().transform(params.value, null);
    }
    return '';
  }

  public twoDigitCurrencyFormatter(params: any) {
    let usdFormat = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
    if (params && (params.value || params.value === 0)) {
      return usdFormat.format(params.value);
    }
  }

  public percentageFormatter(params: any) {
    if (params && (params.value)) {
      return (+params.value).toFixed(2)+'%';
    }
  }

  public commaFormatter(params: any) {
    if (params && (params.value)) {
      return (params.value).toLocaleString('en-US');
    }
  }

  public yesNoFormatter(params: any) {
    switch (params.value) {
      case true:
      case 'true':
        return 'Yes';
      case false:
      case 'false':
        return 'No';
      default:
        return '';
    }
  }

}
