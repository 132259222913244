import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewVersionSnackbarComponent } from './new-version-snackbar/new-version-snackbar.component';
import {CoreModule} from "../toolbar/core.module";
import {RouterModule} from "@angular/router";
import {FormsModule} from "@angular/forms";
import { VersionCheckerComponent } from './version-checker/version-checker.component';
import { SystemTimeFooterComponent } from './system-time-footer/system-time-footer.component';

@NgModule({
    declarations: [NewVersionSnackbarComponent, VersionCheckerComponent, SystemTimeFooterComponent,],
    imports: [CommonModule, CoreModule, RouterModule, FormsModule],
    exports: [NewVersionSnackbarComponent, VersionCheckerComponent, SystemTimeFooterComponent,]
})
export class ToolsModule { }
