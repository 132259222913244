import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {AgEditorComponent} from 'ag-grid-angular';
import {MatSelect} from "@angular/material/select";
import * as _ from 'lodash-es';

@Component({
  template: `
    <mat-select #dropdown (selectionChange)="selectionChange($event)"
                [(value)]="selectedValue">
      <mat-option *ngFor="let data of items"
                  [value]="data">
        {{ data.name }}
      </mat-option>
    </mat-select>
  `
})
export class DropdownEditorComponent implements AgEditorComponent, AfterViewInit {

  public items: any[];
  @ViewChild('dropdown') dropdown: MatSelect;
  public params: any;
  public selectedValue: any;
  constructor() {
    this.items = [];
  }

  ngAfterViewInit() {
    this.dropdown.focus();
  }

  agInit(params: any): void {
    if (this.items.length == 0) {
      this.params = params;
      const initItems = (items) => {
        this.items = items.map((item) => {
          if (params.fieldName) {
            return {name: item[params.fieldName], id: item[params.id]};
          }
          return {name: item, id: item};
        });
        this.findItem(params);
      }
      if (this.params.request) {
        this.params.request.subscribe(result => {
          initItems(result);
        });
      }
      else if (this.params.values) {
        initItems(this.params.values)
      }
    }
  }

  getValue(): any {
    return (this.selectedValue) ? this.selectedValue.id: this.selectedValue ;
  }

  isPopup(): boolean {
    return false;
  }

  selectionChange(event: any): void {
    this.selectedValue = event.value;
    console.log("selected val is", this.selectedValue);
    this.params.data[this.params.modelId] = event.value.id;
    this.params.api.stopEditing();
    if (this.params && this.params.context && this.params.context.selectionChangeCallback) {
      this.params.context.selectionChangeCallback();
    }
  }

  private findItem(params: any): void {
    const foundItem = _.find(this.items, {id: params.value});
    if (foundItem) {
      this.selectedValue = foundItem;
      console.log("---selected val is", this.selectedValue);
    }
  }

}
