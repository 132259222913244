import { Component, OnInit } from '@angular/core';
import {AutoUnsubscribables, AutoUnsubscriber, Theme, ThemeService} from '@comm-apps/common';
import { AppSettings } from '../../../app.settings';
import {CodeData, CARUser, UserPrefs} from '../../../core/classes/data-types';
import {CARUserService} from '../../../services/user.service';
import { UserPrefsService } from '../../../services/user-prefs.service';
import * as _ from 'lodash-es';
import { CodeDataService } from '../../../services/code-data.service';

@Component({
  selector: 'car-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  public user: CARUser;
  public themeList: Theme[];
  public currentTheme: Theme;
  private userPrefs: UserPrefs;
  public appCodes: CodeData[] = [];
  public groupToUserRoleMap: Map<String, String[]> = new Map<String, String[]>();

  constructor(
    private userService: CARUserService, 
    private userPrefService: UserPrefsService, 
    private themeService: ThemeService,
    private appSettings: AppSettings,
    private codeService: CodeDataService,
  ) { }

  ngOnInit() {
    this.user = this.userService.currentUser$.getValue();
    this.subs.newSub = this.userService.currentUser$.subscribe((user: CARUser) => {
      this.user = user;
      this.populateGroupMap();
    });
    this.loadAppCodes();

    this.currentTheme = this.getThemeFromName(this.appSettings.settings.themeList[0]);
    this.themeList = Array.from(ThemeService.themes.values());

    this.subs.newSub = this.userPrefService.userPrefs$.subscribe((prefs: UserPrefs) => {
      this.userPrefs = prefs;
      if (prefs.theme) {
        this.currentTheme = ThemeService.themes.get(prefs.theme);
      }
    });
  }

  private loadAppCodes() {
    this.subs.newSub = this.codeService.findAllCodesByType('APPLICATION').subscribe((codes: CodeData[]) => {
      this.appCodes = codes;
      this.populateGroupMap();
    })
  }

  private populateGroupMap() {
    this.groupToUserRoleMap = new Map<String, String[]>();
    if (!(this.user && this.user.roles)) return;
    
    this.user.roles.forEach(role => {
      let pgFound: boolean = false;

      this.appCodes.forEach(code => {
        if (role.startsWith('UA_CAR_' + code.code)) {
          pgFound = true;
          this.addToMapWithList(this.groupToUserRoleMap, code.code, role);
        }
      });

      if (!pgFound) {
        this.addToMapWithList(this.groupToUserRoleMap, 'General', role);
      }

    });
  }

  private addToMapWithList(map: Map<String, String[]>, key: String, val: String) {
    let array: String[] = map.get(key) ? map.get(key) : [];
    array.push(val);
    map.set(key, array);
  }


  private getThemeFromName(themeName: string): Theme {
    let rv: Theme = null;
    for (var theme of ThemeService.themes.values()) {
      if (theme.themeName === themeName) {
        rv = theme;
      }
    }
    return rv;
  }

  public setCurrentThemeVal(val) {
    if (this.currentTheme) {
      // this.themeService.updateTheme(this.currentTheme.description, this.appSettings.settings);
      this.userPrefs.theme = this.currentTheme.description;
      this.userPrefService.saveUserPrefs(this.userPrefs);
    }
  }

  public resetPrefs(): void {
    this.userPrefService.clearUserPrefs();
  }

}
