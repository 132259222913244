import {
  Component, ElementRef, ViewChild,
} from '@angular/core';
import {ICellEditorAngularComp} from 'ag-grid-angular';
import * as moment from 'moment';

@Component({
  selector: 'input-cell',
  templateUrl: './date-editor.component.html',
  styleUrls: ['./date-editor.component.scss']
})
export class DateEditorComponent implements ICellEditorAngularComp {
  private params: any;
  public inputDate: Date;

  @ViewChild('inputField')
  public input: ElementRef;

  agInit(params: any): void {
    this.params = params;
    this.inputDate = (this.params.value) ? moment(this.params.value).toDate() : this.params.value;
  }

  // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
  ngAfterViewInit() {
    this.focusOnInputNextTick(this.input);
  }

  private focusOnInputNextTick(input: ElementRef) {
    window.setTimeout(() => {
      input.nativeElement.focus();
    }, 0);
  }

  getValue() {
    // if the value is "unchanged" then return original
    if (this.params.value && this.inputDate) {
      if (moment(this.params.value).toDate().toString() == this.inputDate.toString()) {
        return this.params.value;
      }
    }
    return this.inputDate;
  }

  isPopup(): boolean {
    return true;
  }

}
