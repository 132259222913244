import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function validateNumber(numberListString: string): boolean {
  const regex =  /^\d+$/;
  const numberList: string[] = String(numberListString).trim().split(',');
  let valid = true;
  numberList.forEach(number => {
    if (number === "" || !regex.test(number.trim())) {
      valid = false;
    }
  })
  return valid;
}

export function numberListValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
        let valid = validateNumber(control.value);
        return (!valid && control.value?.length > 0) ? {numberList: {value: control.value, message: "Not a valid number list!"}}: null;
    }


}
