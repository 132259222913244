<cad-lib-auto-complete
    [selectIfOneItem]="selectIfOneItem"
    [data]="_data"
    [displayProperty]="displayProperty"
    [resultProperty]="resultProperty"
    [sort]="sort"
    [selectableFunc]="selectableFunc"
    [(selectedData)]="_selectedData"
    [restrictToList]="restrictToList"
    [panelWidth]="panelWidth"
    [autoWidth]="autoWidth"
    [mustStartTyping]="mustStartTyping"
    (valueChanged)="selectionChange($event)">
</cad-lib-auto-complete>