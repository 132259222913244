import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {AgEditorComponent} from 'ag-grid-angular';
import * as _ from 'lodash-es';
import { AutoCompleteComponent } from '../../form/auto-complete/auto-complete.component';

@Component({
  selector: 'auto-complete-editor',
  templateUrl: './auto-complete-editor.component.html',
  styleUrls: ['./auto-complete-editor.component.scss']
})
export class AutoCompleteEditorComponent implements AgEditorComponent, AfterViewInit, OnDestroy {
  public params: any;
  public _data: any[] = [];
  public _selectedData: any;
  public selectIfOneItem: boolean = true;
  public displayProperty: string;
  public sort = true;
  selectableFunc: (obj: any) => boolean;
  public resultProperty: string;
  @ViewChild(AutoCompleteComponent) autoComplete: AutoCompleteComponent;
  public restrictToList: boolean = true;
  public panelWidth: string = "";
  public autoWidth: boolean = true;
  public mustStartTyping: boolean = false;
  
  constructor() {
  }

  ngAfterViewInit() {
    setTimeout(() => this.autoComplete.inputField.nativeElement.focus());
  }

  ngOnDestroy(): void {
    //bound events seem to automatically unsubscribe before ngOnDestroy is called, so resubscribe just to set the value
    this.autoComplete.valueChanged.subscribe(event => {
      this.params.node.setDataValue(this.params.column.colId, event);
    })
    this.autoComplete.blur();
    setTimeout(() => { this.autoComplete.valueChanged.unsubscribe()} );
  }

  agInit(params: any): void {
    this.params = params;

    this.resultProperty = params.id;
    this.displayProperty = params.fieldName;
    this._data = params.values;
    this.selectableFunc = params.selectableFunc;
    if (params.sort || params.sort === false) {
      this.sort = params.sort;
    }
    let dataToSetTo: any = '';
    if (this.resultProperty) {
      const crspValue = _.find(params.values, { [this.resultProperty]: params.value });
      if (crspValue) {
        dataToSetTo = crspValue[this.resultProperty];
      }
    } else {
      dataToSetTo = _.find(params.values, item => item === params.value);
    }
    this._selectedData = dataToSetTo;
  }

  getValue(): any {
    return this._selectedData;
  }

  isPopup(): boolean {
    return false;
  }

  selectionChange(event: any): void {
    if (event) { //we want to stay in edit mode when clearing
      this.params.api.stopEditing();
    }
    if (this.params && this.params.context && this.params.context.selectionChangeCallback) {
      this.params.context.selectionChangeCallback();
    }
  }

 

}
