import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {AutoUnsubscribables, AutoUnsubscriber, cad} from '@comm-apps/common';
import Settings = cad.Settings;
import {CARUserService} from '../../../services/user.service';
import {CARUser} from '../../classes/data-types';
import { verticalMenuItems } from '../menu/menu';
import { CAREnvironment } from '../../classes/car-environment';

@Component({
  selector: 'cad-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: []
})
export class SidenavComponent implements OnInit {
  public menuItems: Array<any>;
  public settings: Settings;
  public userRoles: string[];

  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public appSettings: AppSettings,
    private userSvc: CARUserService,
    @Inject('environment') private env: CAREnvironment
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    verticalMenuItems.forEach(menu => {
      if (menu.href && this.env[menu.href]) {
        menu.href = this.env[menu.href];
      }
    });
    this.menuItems = verticalMenuItems;
    this.iconRegistry.addSvgIcon(
      'cad-logo2',
      this.sanitizer.bypassSecurityTrustResourceUrl(this.settings.logo)
    );
    this.subs.newSub = this.userSvc.currentUser$.subscribe((user: CARUser): void => {
      this.userRoles = user.roles;
    });
  }

  public closeSubMenus() {
    const menu = document.querySelector('.sidenav-menu-outer');
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        const child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

  public resizeMenu(menuSize: string): void {
    this.settings.menuType = menuSize;
  }
}
