import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuService } from '../menu.service';
import { AutoUnsubscribables, AutoUnsubscriber, cad } from '@comm-apps/common';
import { LogService } from '@comm-apps/log';
import { Menu } from '@comm-apps/shared-ui';
import Settings = cad.Settings;

export enum ElementId {
  MainContent = 'main-content',
}

@Component({
  selector: 'cad-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService]
})
export class VerticalMenuComponent implements OnInit, AfterViewInit {
  @Input()
  public menuItems: Menu[];

  @Input()
  public menuParentId: number;

  @Input()
  public settings: Settings;

  @Input()
  public routerLinkActiveOptions: any = {exact:true};

  private _userActions: string[];

  @Input()
  public set userActions(actions: string[]) {
    this._userActions = actions;
    this.buildLayout();
  }

  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  parentMenu: Array<Menu>;

  constructor(
    public menuService: MenuService,
    public router: Router,
    public log: LogService,
  ) { }

  ngOnInit() {
    this.buildLayout();
  }

  buildLayout() {
    this.parentMenu = this.menuItems.filter((item: Menu): boolean => {
      return item.parentId === this.menuParentId
    });
    this.parentMenu.forEach(menu => menu.canViewAnyChildren = this.hasSubEnabledSubMenus(menu));
  }

  public hasSubEnabledSubMenus(menu: Menu) {
    if (!menu.hasSubMenu) {
      return false;
    }

    let subItems: Menu[] = this.menuItems.filter(
      item => item.parentId === menu.id
    )

    subItems = subItems.filter(
      item => this.canView(item)
    );

    return subItems.length > 0;
  }

  public canView(menu: Menu): boolean {
    if (!menu.roles) return true;
    let found: boolean = false;

    menu.roles.forEach((role: string): void => {
      if (this._userActions && this._userActions.indexOf(role) >= 0) {
        found = true;
      }
    });

    return found;
  }

  ngAfterViewInit() {
    this.subs.newSub = this.router.events.subscribe((event: any): void => {
      if (event instanceof NavigationEnd) {
        if (this.settings.fixedHeader) {
          const mainContent = document.getElementById(ElementId.MainContent);
          if (mainContent) {
            mainContent.scrollTop = 0;
          }
        } else {
          document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
        }
      }
    });
    Promise.resolve().then(() => {
      this.menuService.expandActiveSubMenu(this.menuItems);
    });
  }

  onClick(menuId: number): void {
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);
  }
}
