import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wait',
  templateUrl: './wait.component.html',
  styleUrls: ['./wait.component.scss']
})
export class WaitComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
    
  }

}
