<button mat-icon-button [matMenuTriggerFor]="messagesMenu" (click)="openMessagesMenu()" 
      matBadgeColor="accent" [matBadge]="newMessageCount" [matBadgeHidden]="newMessageCount==0">
  <mat-icon *ngIf="newMessageCount==0">notifications</mat-icon>
  <mat-icon *ngIf="newMessageCount>0" ngClass="{{severityClass}}">notifications_active</mat-icon>
</button>
<mat-menu #messagesMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu messages">
  <mat-card appearance="outlined" class="content">
    <mat-card-title>
      <span *ngIf="messages.length < 1" class="text muted-text">No messages</span>
      <button *ngIf="messages.length > 0" mat-icon-button matTooltip="Delete All Messages" (click)="deleteAll()">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-card-title>
    <div fxLayout="column" fxLayoutAlign="none">
        <div *ngFor="let message of messages" fxLayout="column" fxLayoutAlign="none" class="w-100">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" >
            <mat-icon ngClass="{{message.alertClass}}">{{message.icon}}</mat-icon>
            <div>{{message.date.toLocaleTimeString()}}</div>
          </div>
          <div fxLayout="row" fxLayoutAlign="start center">
            <span class="text muted-text">{{message.msg}}</span>
          </div>
          <hr/>
        </div>
    </div>
  </mat-card>
</mat-menu>
