<div fxFlex class="card-container">
    <mat-card appearance="outlined" fxFlex fxLayout="column">
        <mat-card-header fxLayout="row">
          <mat-card-title fxFlex>
            <div fxFlexLayout="row">
              <div fxFlex>Application Configuration</div>             
              <div class="menu-wrapper" fxFlex fxLayoutAlign="end center">
                <button [matMenuTriggerFor]="menu" mat-mini-fab color="primary">
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button (click)="new()" mat-menu-item>
                    <mat-icon>add</mat-icon>
                    <span>New</span>
                  </button>
                  <button (click)="save()" [disabled]="dirtyRowSet.size < 1" mat-menu-item>
                    <mat-icon>save</mat-icon>
                    <span>Save</span>
                  </button>
                  <button (click)="delete(selectedRow)" [disabled]="!selectedRow"  mat-menu-item>
                    <mat-icon>delete</mat-icon>
                    <span>Delete</span>
                  </button>
                </mat-menu>
              </div>
            </div>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content fxFlex fxLayout="column">
          <div fxLayout="column" class="outer">
            <!--(rowSelected)="rowSelected($event)"-->
            <div fxFlex fxFill fxLayout="row">
              <div fxFlex style="overflow: hidden; flex-grow: 1">
                <ag-grid-angular
                  [animateRows]="true"
                  [columnDefs]="columnDefs"
                  [columnTypes]="columnTypes"
                  [gridOptions]="gridOptions"
                  [defaultColDef]="defaultColDef"
                  (gridReady)="onGridReady($event)"
                  [enableCellChangeFlash]="true"
                  [components]="frameworkComponents"
                  [rowData]="rowData"
                  rowSelection="single"
                  (rowSelected)="onRowSelected($event)"
                  (cellValueChanged)="cellValueChanged($event)"
                  (modelUpdated)="modelUpdated($event)">
                </ag-grid-angular>
              </div>
            </div>
          
          </div>
        </mat-card-content>
      </mat-card>
</div>
