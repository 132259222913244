import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { PhonePipe } from './pipes/phone.pipe';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { UniqueValueDirective } from './form/directives/unique-value.directive';
import { CurrencyDirective } from './form/directives/currency.directive';
import { EmailListDirective } from './form/directives/email-list.directive';
import { LogModule } from '@comm-apps/log';
import { NumericDirective } from './form/directives/numeric.directive';
import { PreventDoubleClickDirective } from './form/directives/prevent-dbl-clk.directive';
import { EmailAddressDirective } from './form/directives/email-address.directive';
import { NumberListDirective } from './form/directives/number-list.directive';

@NgModule({
    imports: [
      CommonModule,
      LogModule,
    ],
    declarations: [
      PhonePipe,
      UniqueValueDirective,
      CurrencyDirective,
      NumericDirective,
      EmailAddressDirective,
      EmailListDirective,
      NumberListDirective,
      PreventDoubleClickDirective
  ],
    exports: [
        PhonePipe,
        UniqueValueDirective,
        CurrencyDirective,
        NumericDirective,
        EmailAddressDirective,
        EmailListDirective,
        NumberListDirective,
        PreventDoubleClickDirective
    ],

})
export class CadCommonModule {}

export namespace cad {
  export class Settings {
    constructor(
      public name: string,
      public shortName: string,
      public loadingSpinner: boolean,
      public fixedHeader: boolean,
      public sidenavIsOpened: boolean,
      public sidenavUserBlock: boolean,
      public menu: string,
      public menuType: string,
      public themeList: string[],
      public tableTheme: string,
      public logo: string
    ) {}

    public setSpinner(spinning: boolean) : void {
      //make this safe for change detection
      setTimeout(() => {
        this.loadingSpinner = spinning;
      });
    }
  }
  export interface Alert {
    type: string;
    msg: string;
    alertClass?: string;
    error?: HttpErrorResponse;
    dismissOnTimeout?: number;
    icon?: string;
    severity?: number;
    href?: string;
    linkDesc?: string;
    date?: Date;
    screen?: string;
    url?: string;
    id?: number;
    //These positions recommend not using, only used for special cases.
    horizontalPosition?: MatSnackBarHorizontalPosition;
    verticalPosition?: MatSnackBarVerticalPosition;
  }

  export interface AlertType {
    label: string;
    alerts: Alert[];
  }

  export interface ValidationResultMessage {
    message?: string;
    severity?: number;
  }

  export interface ValidationResult {
    successful: boolean;
    //I am not sure why we are populating this object manually.  This is not on the server side (errorMessages is)
    messages?: ValidationResultMessage[];
    errorMessages?: ValidationResultMessage[];
    validatedObject?: any;
  }

  export interface UserPreferences {
    dashboards: DashboardConfig[];
  }

  export interface DashboardConfig {
    name: string;
    index: number;
  }

  export interface TableState {
    name?: any;
    columnState?: any;
    groupState?: any;
    sortState?: any;
    filterState?: any;
  }

  export class Environment {
    public production: boolean;
    public localDevelopment: boolean;
    public baseUrl: string;
    public wsUrl?: string;
    public logLevel: string;
    public name: string;
  }
}
