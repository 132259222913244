import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash-es';
import { AutoCompleteComponent } from '../auto-complete/auto-complete.component';

@Component({
  selector: 'cad-lib-auto-complete-multi',
  templateUrl: './auto-complete-multi.component.html',
  styleUrls: ['./auto-complete-multi.component.scss']
})
export class AutoCompleteMultiComponent extends AutoCompleteComponent {

  public floatLabel: 'auto' | 'always' | 'never' = 'auto';
  public _selectedItems: any[] = [];


  @Input()
  public set selectedItems(items: any[]) {this.initSelectedItems(items);}

  @Output()
  public selectedItemsChange = new EventEmitter<any>();

  public itemStringList: string[] = null;

  constructor() {
    super();
    this.selectedDataChange.subscribe(data => this.itemSelected(data));
   }

  private initSelectedItems(items: any[]) {
    this.itemStringList = null;
    this._selectedItems = items ? items : [];
    this._selectedItems.forEach(item => {
      let foundItem: any;
      if (this.resultProperty) {
        foundItem = this._data.find(dataItem => dataItem[this.resultProperty] == item)
      } else {
        foundItem = this._data.find(dataItem => dataItem == item)
      }

      this.checkInitializeList();
      if (foundItem) {
        if (this.displayProperty) {
          this.itemStringList.push(foundItem[this.displayProperty]);
        } else {
          this.itemStringList.push(foundItem);
        }
      } else {
        this.itemStringList.push(item);
        console.log("Could not find matching item for ", item);
      }
    });
  }

  private checkInitializeList() {
    if (!this.itemStringList) {
      this.itemStringList = [];
    }
  }

  private itemSelected(event: any) {
    if (!event) return;

    if (!this._selectedItems.find(item => item == event)) {
      this._selectedItems.push(event);
    }

    if (this.displayProperty && this.resultProperty) {
      this.checkInitializeList();
      let item: any = this._data.find(dataItem => dataItem[this.resultProperty] == event)
      this.itemStringList.push(item[this.displayProperty]);
    } else {
      this.itemStringList.push(event);
    }

    if (this.itemStringList) {
      this.itemStringList = _.uniq(this.itemStringList);
    }

    this.selectedItemsChange.emit(this._selectedItems);
    this.clear();
  }

  public remove(item: string): void {
    const index = this.itemStringList.indexOf(item);

    if (index >= 0) {
      this.itemStringList.splice(index, 1);
      this._selectedItems.splice(index, 1);
    }
    if (this.itemStringList.length == 0) {
      this.itemStringList = null;
    }
    this.selectedItemsChange.emit(this._selectedItems);
    this.clear();
  }

  public getOffMe(event) {
    this.inputField.nativeElement.focus();
  }

  public blur(event: FocusEvent): void {
    //super.blur(event); 
    //-- removed this as clicking on autocomplete list blurs (fine in original autocomplete) 
    //and causes us to fire an event with the first item in the list.  This will add a chip and the user could 
    //have been selecting different item
    this.clear();
  }

  public clear(): void {
    //timeout because we are clearing the input before we get a chance to see what the user cicked on in the list and we are preventing
    //the autocomplete from firing.
    setTimeout(() => {
      this.floatLabel = 'auto';
      super.clear();
    }, 100);

  }

  public onFocus(event: FocusEvent) {
    this.floatLabel = 'always';
    super.onFocus(event);
  }

}
