import {Menu} from '@comm-apps/shared-ui';
import { Roles } from '../../../models/roles.enum';

export const verticalMenuItems = [
  new Menu(1, 'Dashboard', [Roles.USER], '/restr-user/dash', null, null, 'person', null, false, 0),
  new Menu(2, 'History', [Roles.USER], '/restr-user/history', null, null, 'history', null, false, 0),
  new Menu(5, 'Admin', null, '/restr-admin/admin', null, null, 'people', null, true, 0),
  new Menu(51, 'App Config', [Roles.ADMIN], '/restr-admin/admin/app-config', null, null, 'settings', null, false, 5),
  new Menu(52, 'Codes', [Roles.CODE_ADMIN], '/restr-admin/admin/codes', null, null, 'list', null, true, 5),
  new Menu(520, 'Codes', [Roles.CODE_ADMIN], '/restr-admin/admin/codes/codes', null, null, 'list', null, false, 52),
  new Menu(53, 'Security', [Roles.ADMIN], '/restr-admin/admin/role', null, null, 'recent_actors', null, false, 5),
  new Menu(54, 'User Activity', [Roles.ADMIN], '/restr-admin/admin/view-user-activity', null, null, 'directions_walk', null, false, 5),
];

