<div fxFlex class="card-container">
  <mat-card appearance="outlined">
    <div fxLayout="column">
      <div fxLayout="row" fxLayoutAlign="start, center" fxLayoutGap="20px">
        <div>
          <mat-form-field>
            <mat-label>User</mat-label>
            <input type="text" matInput [(ngModel)]="userSearch.userId"/>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label>Activity From</mat-label>
            <input matInput required [matDatepicker]="pickerStart" name="from"
                   [(ngModel)]="searchFrom">
            <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
            <mat-datepicker #pickerStart ></mat-datepicker>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label>Activity To</mat-label>
            <input matInput required [matDatepicker]="pickerEnd" name="to"
                   [(ngModel)]="searchTo">
            <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
            <mat-datepicker #pickerEnd ></mat-datepicker>
          </mat-form-field>
        </div>

        <div>
          <button mat-button (click)="doSearch()">Search</button>
        </div>
      </div>

      <div fxFlex fxLayout="row" class="table-container">
      <cad-lib-csvdownload fileName="UserActivity" [headers]="displayedColumns"
                           [data]="tableData.data"></cad-lib-csvdownload>
      <table mat-table [dataSource]="tableData" matSort class="mat-elevation-z8">
        <ng-container matColumnDef="userId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
          <td mat-cell *matCellDef="let userActivity">{{ userActivity.userId }}</td>
        </ng-container>

        <ng-container matColumnDef="activityStartDt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity Start Dt</th>
          <td mat-cell *matCellDef="let userActivity">{{ userActivity.activityStartDt | date:"short"}}</td>
        </ng-container>

        <ng-container matColumnDef="activityEndDt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity End Dt</th>
          <td mat-cell *matCellDef="let userActivity">{{ userActivity.activityEndDt | date:"short" }}</td>
        </ng-container>

        <ng-container matColumnDef="durationMins">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity mins</th>
          <td mat-cell *matCellDef="let userActivity">{{ userActivity.durationMins }}</td>
        </ng-container>

        <ng-container matColumnDef="assetNbr">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Asset</th>
          <td mat-cell *matCellDef="let userActivity">{{ userActivity.assetNbr }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      </div>
    </div>
  </mat-card>
</div>
<div fxFlex class="card-container">
  <mat-card appearance="outlined">
    <div fxLayout="row" fxLayout.lt-md="column">
      <div #chartRef fxFlex="50" fxFlex.lt-md="100" class="chart"></div>
      <div #barChartRef fxFlex="50" fxFlex.lt-md="100" class="chart"></div>
    </div>
  </mat-card>
</div>

