import {Injectable} from '@angular/core';
import {HttpHelperService} from '@comm-apps/http';
import {Observable} from 'rxjs';
import {CodeData} from '../core/classes/data-types';
import {map, share} from 'rxjs/operators';
import { CACHE_AGE_MINS, } from '../core/enums/enums'
import * as _ from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class CodeMappingService {

  /**
   * will look something like this:
   * 
   * codeMappings = {
   *    'portfolio': {
   *        'AccMgmt': 'Access Management (Morgan)',
   *        'BO': 'Business Objects',
   *     },
   *    'CharterType': {
   *        'Off-Cycle': 'Off Cycle',
   *        'Annual': 'Annual Plan',
   *    },
   * }
   * 
   */
  private codeMappings: any = {};  

  constructor() {
  }

  public addMapping(mappingType: string, mappings: any): void {
    this.codeMappings[mappingType] = mappings;
  }

  public addMappingFromCodes(mappingType: string, codes: CodeData[]): void {
    let codeMappings:any = _.mapValues(_.keyBy(codes, 'code'), 'description');
    this.addMapping(mappingType, codeMappings);
  }

  public getMapping(mappingType: string): any {
    return _.clone(this.codeMappings[mappingType]);
  }

  public clearMapping(mappingType: string): void  {
    this.codeMappings[mappingType] = null;
  }

}
