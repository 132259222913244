import {AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'cad-lib-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, AfterViewInit, OnDestroy {
 
  @ViewChild('barTextLabel') private barTextLabel: ElementRef<HTMLLabelElement>;
  public barHeight = '1.5em';
  private windowEventFunction;
  private progressBarInner: any; //TODO: find out specific type

  //value of the bar
  private _value: number = 0;
  public get value() {
    return this._value;
  }
  @Input() set value(value: number) {
    this._value = value || 0;
    this.updatePercentValue();
  }

  //maximum value of the bar. value CAN exceed it
  private _max: number = 100;
  public get max() {
    return this._max;
  }
  @Input() set max(value: number) {
    this._max = value || 0;
    this.updatePercentValue();
  }

  //determines the color of the bar/icon
  private _status: 'not-started' | 'healthy' | 'warn' | 'alert';
  public get status() {
    return this._status;
  }
  @Input() set status(value: any) {
    this._status = value;
    switch (value) {
      case 'not-started':
      case 'NA':
        this._status = 'not-started';
        this.icon = 'circle';
        break;
      case 'healthy':
        this.icon = 'check_circle';
        break;
      case 'warn':
        this.icon = 'warning';
        break;
      case 'alert':
        this.icon = 'error';
        break;
    }
  };

  //determines whether to show % above the bar
  @Input()
  showPercentage: boolean = true;

  //determines whether to show x / y on the bottom right hand corner of the bar
  @Input()
  showDetail: boolean = false;

  //text that shows up below the bar
  @Input()
  footer: string;

  //text that shows up inside the bar (or next to the icon if in compact mode)
  @Input()
  text: string;

  //text that shows up in the tooltip (if in compact mode)
  @Input()
  tooltip: string;

  //if set to true, only shows text and corresponding icon
  @Input()
  compact = false;

  //if set to true, break up into multiple lines if text is long enough
  @Input()
  lineBreak = false;

  public icon: string;

  public percentValue: number = 0;

  private isStatusInputSet: boolean;

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit() {
    this.isStatusInputSet = !!this.status;
    this.updatePercentValue();
  }

  ngAfterViewInit() {
      if (!this.compact && this.text && this.lineBreak) {
        this.progressBarInner = this.elementRef.nativeElement.querySelector('.mdc-linear-progress__bar-inner');
        this.windowEventFunction = () => this.updateBarHeight();
        window.addEventListener('resize', this.windowEventFunction);
        setTimeout(() => { this.updateBarHeight() });
      }
  }

  ngOnDestroy() {
    if (this.windowEventFunction) {
      window.removeEventListener('resize', this.windowEventFunction);
    }
  }

  private updatePercentValue() {
    if (this.max == 0) {
      this.percentValue = this.value == 0 ? 0 : 999999;
    } else {
      this.percentValue = Math.round((this.value / this.max) * 100);
    }
    if (this.isStatusInputSet === false) {
      this.updateStatus();
    }
  }

  private updateStatus() {
    if (this.percentValue <= 0) {
      this.status = 'not-started';
    } else if (this.percentValue <= 100) {
      this.status = 'healthy';
    } else if (this.percentValue <= 110) {
      this.status = 'warn';
    } else {
      this.status = 'alert';
    }
  }

  private updateBarHeight() {
    const height = `${+this.barTextLabel.nativeElement.offsetHeight}px`
    this.progressBarInner.style.borderTopWidth = height;
    this.barHeight = height;
  }
}
