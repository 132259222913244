import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AutoUnsubscribables, AutoUnsubscriber} from '@comm-apps/common';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {AlertService} from '@comm-apps/alert';
import {LogService} from '@comm-apps/log';
import * as _ from 'lodash-es';
import {HttpErrorResponse} from '@angular/common/http';
import * as moment from 'moment';
import { UserActivitySearch, UserActivitySummary } from '../../../core/classes/user-activity';
import { UserActivityService } from '../../../services/user-activity.service';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'opdata-view-user-activity',
  templateUrl: './view-user-activity.component.html',
  styleUrls: ['./view-user-activity.component.scss']
})
export class ViewUserActivityComponent implements OnInit, OnDestroy {
  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;
  @ViewChild(MatSort, { static: true })
  set sort(s: MatSort) {
    this.tableData.sort = s;
  };

  public Highcharts: typeof Highcharts = Highcharts;

  @ViewChild('barChartRef', {static: true}) barChartRef: ElementRef;
  @ViewChild('chartRef', {static: true}) chartRef: ElementRef;

  public chart: any;
  public barChart: any;

  //this is the scale/grouping of data on the chart - 1 min is just to fine.
  private GROUPING_MINS: number = 10;

  private secondaryColor = 'grey';
  private backgroundColor = '#424242';

  public chartOptions: Highcharts.Options = {
    chart: {
      type: 'line',
      backgroundColor: this.backgroundColor
    },
    credits: {
      enabled: false
    },
    title: {
      text: `User Actvity per ${this.GROUPING_MINS} mins`,
      style: {
        color: this.secondaryColor
      }
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%b %e',
      },
      labels: {
        style: {
          color: this.secondaryColor
        }
      }
    },
    series: [],
    yAxis: {
      title: {
        text: 'Activity',
        style: {
          color: this.secondaryColor
        }
      },
      labels: {
        style: {
          color: this.secondaryColor
        }
      }
    },
    legend: {
      itemStyle: {
        color: this.secondaryColor
      }
    }
  };

  public barChartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      backgroundColor: this.backgroundColor
    },
    credits: {
      enabled: false
    },
    title: {
      text: `Top 10 Mins activity by user`,
      style: {
        color: this.secondaryColor
      }
    },
    xAxis: {
      type: 'category'
    },
    series: [],
    yAxis: {
      min: 0,
      title: {
        text: `Top 10 Mins activity by user`
      }
    },
    legend: {
      itemStyle: {
        color: this.secondaryColor
      }
    }
  };

  public userSearch = new UserActivitySearch();
  public userActivity: UserActivitySummary[] = [];
  public tableData: MatTableDataSource<UserActivitySummary> = new MatTableDataSource([]);

  public displayedColumns: string[] = [
    'userId',
    'activityStartDt',
    'activityEndDt',
    'durationMins',
    'assetNbr',
  ];

  constructor(
    private userActivitySvc: UserActivityService,
    private alertService: AlertService,
    private log: LogService,

  ) {}

  ngOnInit() {
    this.userSearch.to = new Date();
    this.userSearch.from =  new Date(this.userSearch.to.getTime() - (1000 * 60 *60 * 24 * 10)); //ten days

    this.resetSearchHours();
    this.doSearch();
  }

  ngOnDestroy() {
  }


  public get searchFrom(): Date {
    return this.userSearch.from;
  }

  public set searchFrom(date: Date) {
    this.userSearch.from = date;
    this.syncToDate(date);
  }

  public get searchTo(): Date {
    return this.userSearch.to;
  }

  public set searchTo(date: Date) {
    this.userSearch.to = date;
    this.syncFromDate(date);
  }

  public doSearch(): void {

    this.subs.newSub = this.userActivitySvc.search(this.userSearch).subscribe((response: UserActivitySummary[]): void => {
      this.userActivity = response;
      this.userActivity.forEach(item =>{
        item.durationMins = this.getMins(item.activityStartDt, item.activityEndDt);
      })
      this.tableData.data = [].concat(this.userActivity);

      this.buildChartData();
      this.buildChartByUserData();

    }, (err: HttpErrorResponse): void => {
      this.alertService.danger('Could not retrieve user activity.');

      if (err.error && err.error.message) {
        this.alertService.danger(err.error.message);
      }
      this.log.error(err);
    });
  }

  private buildChartData(): void {

    let data: any[] = this.blowOutData();

    let chartSeries: any[] = [{
      type: 'line',
      data: data,
      name: `User Actvity per ${this.GROUPING_MINS} mins`,
    }];

    this.chartOptions.series = chartSeries

    this.chart = Highcharts.chart(
      this.chartRef.nativeElement,
      this.chartOptions
    )
    this.chart.reflow();
  }
  private buildChartByUserData(): void {
    let data: any[] = this.blowOutDataByUser();
    let chartSeries: any[] = [{
      data: data,
      name: `Top 10 Mins activity by user`,
      dataLables: {
        enabled: true
      }
    }];

    this.barChartOptions.series = chartSeries;
    this.barChart = Highcharts.chart(
      this.barChartRef.nativeElement,
      this.barChartOptions
    )
    this.barChart.reflow();
  }

  private blowOutData(): any[]  {
    let dates: any[] = [];
    let data: any[] = [];
    let timeScale: number = 10000 * 60;
    let timeGroup: number = timeScale * this.GROUPING_MINS;

    this.userActivity.forEach((activity:UserActivitySummary) => {
      let startDt:Date = new Date(activity.activityStartDt);
      startDt.setSeconds(0, 0);
      dates.push(_.clone(startDt));
      startDt = new Date(startDt.getTime() + timeScale);
      while (startDt < new Date(activity.activityEndDt)) {
        dates.push(_.clone(startDt));
        startDt = new Date(startDt.getTime() + timeScale);
      }
    });

    dates.sort(function(a,b){return a - b});

    let lastDate:Date = dates[0];
    let count =  0;
    dates.forEach((date:Date) => {
      if ((lastDate.getTime() + timeGroup) >= date.getTime())   {
        count++;
      } else {
        data.push([lastDate.getTime(), count]);
        lastDate = date;
        count = 1;
      }
    });
    data.push([lastDate.getTime(), count]);
    return data;
  }
  private blowOutDataByUser(): any[]  {
    let userHash: any = {};
    let data: any[] = [];

    this.userActivity.forEach((activity:UserActivitySummary) => {
      let prev = userHash[activity.userId];
      if (prev) {
        userHash[activity.userId] = prev + activity.durationMins;
      } else {
        userHash[activity.userId] = activity.durationMins;
      }
    });

    let mins: number[] = [];
    for (let userId in userHash) {
      mins.push(userHash[userId]);
    }
    mins.sort(function(a,b){return a - b});
    let maxMins = 0;
    if (mins.length > 10) {
      maxMins = mins[mins.length - 10];
    }

    for (let userId in userHash) {
      if (userHash[userId] >= maxMins) {
        data.push([userId, Math.round(userHash[userId])])
      }
    }
    return data;
  }



  public getMins(start: Date, end: Date): number {
    return Math.round(moment.duration(moment.utc(end).diff(moment.utc(start))).asMinutes() * 100)/100;
  }
  private resetSearchHours(): void {
    this.userSearch.from.setHours(0, 0, 0, 0);
    this.userSearch.to.setHours(23, 59, 59);
  }

  public syncToDate(event: any) {
    if (this.badSearchDuration()) {
      this.userSearch.to =  new Date(this.userSearch.from.getTime() + (1000 * 60 *60 * 24 * 10)); //ten days
    }

    this.resetSearchHours();
  }
  public syncFromDate(event: any) {
    if (this.badSearchDuration()) {
      this.userSearch.from =  new Date(this.userSearch.to.getTime() - (1000 * 60 *60 * 24 * 10)); //ten days
    }

    this.resetSearchHours();
  }
  private badSearchDuration(): boolean  {
    //make sure duration is not more than 10 days or less than 0
    let bad: boolean = ((this.userSearch.to.getTime() - this.userSearch.from.getTime()) > (1000 * 60 *60 * 24 * 10) ||
    (this.userSearch.to.getTime() - this.userSearch.from.getTime()) < 0 );

    if (bad) {
      this.alertService.warning("You are only allowed to search for a 10 day duration (MAX)");
    }

    return bad;
  }
}
