import { Subscription } from 'rxjs';
import * as _ from 'lodash-es';

/**
 * The purpose of this property decorator is to cut down on subscribe/unsubscribe boilerplate
 * that is needed in components when making api calls/subscribing to observables.  It requires
 * a class that uses the angular lifecycles hooks in order to unsubscribe in ngOnDestroy.  In
 * other words, the class needs to be decorated with @Injectable() or @Component().
 *
 * example:
 *  @Component({
 *      selector: 'my-component',
 *      templateUrl: 'my-component.component.html',
 *  })
 *  export class MyComponent implements OnInit {
 *     @AutoUnsubscriber() private subs: AutoUnsubscribable;
 *
 *     constructor(private itemApi: ItemApi) {}
 *
 *     ngOnInit(): void {
 *       this.subs.newSub = this.itemApi.findAll(this.primaryKey);
 *     }
 *  }
 *
 * That is all the boilerplate needed for handling subscriptions
 * in your controller.
 *
 * There is no need to implement OnDestroy.  If you need OnDestroy
 * for other purposes, the decorator will handle all of the unsubscriptions
 * first, and then call your ngOnDestroy method.
 *
 */

export class AutoUnsubscribables {
  private subs: Subscription[] = [];

  set newSub(s: Subscription) {
    this.subs.push(s);
  }

  unsubscribe(): void {
    // if (this.subs.length) {
    //   console.log(
    //     `AutoUnsubscribables unsubscribing from ${this.subs.length} subscriptions`
    //   );
    // }
    _.forEach(this.subs, sub => sub.unsubscribe());
    this.subs = [];
  }
}

/* tslint:disable */
export function AutoUnsubscriber(): Function {
  return function(proto, prop): void | Function {
    let origDestroy = proto.ngOnDestroy;
    let p = `__${prop}__`;

    Object.defineProperty(proto, prop, {
      get: function() {
        if (!this[p] || !(this[p] instanceof AutoUnsubscribables)) {
          this[p] = new AutoUnsubscribables();
        }
        return this[p];
      }
    });

    proto.ngOnDestroy = function() {
      if (this[prop]) {
        this[prop].unsubscribe();
      }
      origDestroy &&
        typeof origDestroy === 'function' &&
        origDestroy.apply(this, arguments);
    };
  };
}
/* tslint:enable */
