import {Injectable} from '@angular/core';
import {HttpHelperService} from '@comm-apps/http';
import {Observable} from 'rxjs';
import {CodeData} from '../core/classes/data-types';
import {map, share} from 'rxjs/operators';
import { CACHE_AGE_MINS, } from '../core/enums/enums'
import { CodeMappingService } from './code-mapping.service';

@Injectable({
  providedIn: 'root'
})
export class CodeDataService {

  private publicEndpoint: string = 'public-api/code';
  private endpoint: string = 'api/code';

  constructor(private apiHelper: HttpHelperService, private codeMappingService: CodeMappingService) {
  }

  findAll(): Observable<CodeData[]> {
    return this.apiHelper.requestOrCache(CACHE_AGE_MINS.CODES,this.endpoint + '/all')
      .pipe(map((items: CodeData[]) => this.setAutoCompleteDescriptions(items)));
  }

  public findByType( type: string  ) : Observable<CodeData[]> {
    return this.apiHelper.requestOrCache<CodeData[]>(CACHE_AGE_MINS.DEFAULT, `${ this.publicEndpoint }/type/${type}`);
  }

  public delete(code: CodeData): Observable<CodeData> {
    this.apiHelper.clearCache(this.publicEndpoint + '/all');
    this.apiHelper.clearCache(this.endpoint + '/all');
    this.apiHelper.clearCache(this.publicEndpoint +  '/type/' + code.type);
    this.codeMappingService.clearMapping(code.type);

    return this.apiHelper.request(`${this.endpoint}/${code.id}`, { method: 'DELETE'});
  }

  public save( code: CodeData) : Observable<CodeData> {
    this.apiHelper.clearCache(this.publicEndpoint + '/all');
    this.apiHelper.clearCache(this.endpoint + '/all');
    this.apiHelper.clearCache(this.publicEndpoint +  '/type/' + code.type);
    this.codeMappingService.clearMapping(code.type);

    return this.apiHelper.request<CodeData>(`${ this.endpoint }`, { body: code } );
  }

  public saveAll(codes: CodeData[]): Observable<CodeData[]> {
    this.apiHelper.clearCache(this.publicEndpoint + '/all');
    this.apiHelper.clearCache(this.endpoint + '/all');
    codes.forEach(code => {
      this.apiHelper.clearCache(this.publicEndpoint +  '/type/' + code.type);
      this.codeMappingService.clearMapping(code.type);
    });

    return this.apiHelper.request(`${this.endpoint}/save-all`, {body: codes, method: 'POST'});
  }

  findAllCodesByType(codeType: string): Observable<CodeData[]> {
    return this.apiHelper.requestOrCache(CACHE_AGE_MINS.CODES,this.publicEndpoint + '/type/' + codeType)
      .pipe(map((items: CodeData[]) => this.setAutoCompleteDescriptions(items)));
  }

  public clearAllCodeCache(): void {
    this.apiHelper.clearCache(this.publicEndpoint + '/all');
    this.apiHelper.clearCache(this.endpoint + '/all');
    [''].forEach(code => {
      this.apiHelper.clearCache(this.publicEndpoint +  '/type/' + code);
      this.codeMappingService.clearMapping(code);
    });
  }

  private setAutoCompleteDescriptions(items: CodeData[]) {
    items.forEach((item: CodeData) => {
      item.autoCompleteDescription = item.code + ' - ' + item.description;
    })
    return items;
  }
}
