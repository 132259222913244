import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'cad-lib-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss']
})
export class TitleBarComponent implements OnInit {
  @Input() menuType;
  @Input() sidenavIsOpened;
  @Input() title;
  @Output() menuTypeChange = new EventEmitter();

  logo = '../../assets/img/app/white-logo.svg';

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.iconRegistry.addSvgIcon(
      'cad-logo',
      this.sanitizer.bypassSecurityTrustResourceUrl(this.logo)
    );
  }

  public closeSubMenus() {
    const menu = document.querySelector('.sidenav-menu-outer');
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        const child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

  public resizeMenu(menuSize: string): void {
    this.menuType = menuSize;
    this.menuTypeChange.emit(menuSize);
  }
}
