// Common enums used in multiple components.
export enum CodeType {
  ROLE = 'Role',
}

export enum CACHE_AGE_MINS {
  DEFAULT = 120,
  CODES = 30,
  ALL_USER = 30,
}

export enum AS_MILLIS {
  ONE_MIN =   60000,
  TWO_MIN =   120000,
  FIVE_MIN =  300000,
  TEN_MIN =   600000,
  THIRTY_MIN =1800000,
  ONE_HOUR =  3600000,
}

// export enum ElementId {
//   MainContent = 'main-content',
// }
// export enum ElementIdPrefix {
//   MenuItem = 'menu-item-',
//   SubMenu = 'sub-menu-',
//   DropArea = 'drop_area',
// }

export enum DialogSize {
  SmallDialogWidth = '525px',
  SmallDialogHeight = '525px',
  MediumDialogWidth = '700px',
  BigDialogWidth = '99%',
  BigDialogHeight = '80%',
}

export enum DialogResult {
  Save = 'save',
  Close = 'close',
}

export enum UserReqChangeOp {
  SAVE,
  NEXT_WORKFLOW,
  PREV_WORKFLOW,
  REJECT,
  DELETE
}

