<cad-lib-dialog-wrapper [title]="(saveMode ? 'Save' : 'Load/Delete') + ' Table Layout'" (closeClick)="cancel()" fxLayout="column">
  <form #form="ngForm">
    <div mat-dialog-content fxFlex fxLayout="column" >
      <cad-lib-auto-complete
        placeholder="Table Layout"
        [required]="true"
        [selectIfOneItem]="false"
        [data]="tableStateNames"
        [(selectedData)]="name"
        [restrictToList]="!saveMode">
      </cad-lib-auto-complete>
    </div>
</form>
<div fxLayoutAlign="end">
  <div mat-dialog-actions>
    <button type="submit" mat-raised-button color="primary" (click)="ok()" [disabled]="!form.form.valid" >{{saveMode ? 'SAVE' : 'LOAD'}}</button>
    <button mat-raised-button (click)="cancel()">CANCEL</button>
    <button *ngIf="!saveMode" mat-raised-button (click)="delete()">DELETE</button>
  </div>
</div>
</cad-lib-dialog-wrapper>

