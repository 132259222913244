import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cad-lib-apps-profile',
  templateUrl: './profile.component.html'
})
export class ProfileComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
