import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { uniqueValueValidator } from '../validators/unique-value.validator';

@Directive({
  selector: '[takenValues]',
  providers: [{provide: NG_VALIDATORS, useExisting: UniqueValueDirective, multi: true}]
})
export class UniqueValueDirective implements Validator {

  @Input('takenValues') takenValues: string[];


  validate(control: AbstractControl): ValidationErrors {
    this.takenValues = this.takenValues.map(x => x.toLocaleLowerCase().trim())
    return uniqueValueValidator(this.takenValues)(control);
  }

}
