import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from './toolbar/toolbar.module';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { CoreModule } from './toolbar/core.module';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './dialogs/login/login.component';
import { FormsModule } from '@angular/forms';
import { ConfirmationComponent } from './dialogs/confirmation/confirmation.component';
import {LengthHintDirective} from './form/mat-form-field/length-hint.directive';
import {MatFormFieldDirective} from './form/mat-form-field/mat-form-field.directive';
import { EmbedDirective } from './form/embed/embed.directive';
import { RunAsUserComponent } from './dialogs/run-as-user/run-as-user.component';
import { ToolsModule } from './tools/tools.module';
import {NewVersionSnackbarComponent} from './tools/new-version-snackbar/new-version-snackbar.component';
import {VersionCheckerComponent} from './tools/version-checker/version-checker.component';
import {AutoCompleteComponent} from './form/auto-complete/auto-complete.component';
import { CSVDownloadComponent } from './form/csvdownload/csvdownload.component';
import { EmailRendererComponent } from './table/email-renderer/email-renderer.component';
import {AddToNgFormDirective} from './form/addToNgForm/add-to-ng-form.directive';
import {DateEditorComponent} from './table/date-editor/date-editor.component';
import { PhoneComponent } from './form/phone/phone.component';
import { PhoneRendererComponent } from './table/phone-renderer/phone-renderer.component';
import { CheckboxEditorComponent } from './table/checkbox-editor/checkbox-editor.component';
import { DropdownEditorComponent } from './table/drop-down/drop-down-editor';
import { RadioComponent } from './form/radio/radio.component';
import { AutoCompleteMultiComponent } from './form/auto-complete-multi/auto-complete-multi.component';
import { InfoComponent } from './dialogs/info/info.component';
import { WaitComponent } from './wait/wait.component';
import { TableLayoutComponent } from './dialogs/table-layout/table-layout.component';
import { ProgressBarComponent } from './form/progress-bar/progress-bar.component';
import { SaveComponent } from './dialogs/save/save.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DialogWrapperComponent } from './dialogs/dialog-wrapper/dialog-wrapper.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DoubleEditorComponent } from './table/double-editor/double-editor.component';
import { ButtonRendererComponent } from './table/button-renderer/button-renderer.component';
import { AutoCompleteEditorComponent } from './table/auto-complete-editor/auto-complete-editor.component';
import { ButtonStringRendererComponent } from './table/button-renderer/button-string-renderer.component';

@NgModule({
    imports: [CommonModule, CoreModule, RouterModule, FormsModule, ToolsModule, DragDropModule, ScrollingModule],
    exports: [
        AddToNgFormDirective,
        ToolbarModule,
        BreadcrumbComponent,
        LoginComponent,
        ConfirmationComponent,
        SaveComponent,
        LengthHintDirective,
        MatFormFieldDirective,
        EmbedDirective,
        RunAsUserComponent,
        TableLayoutComponent,
        AutoCompleteComponent,
        AutoCompleteMultiComponent,
        EmailRendererComponent,
        DateEditorComponent,
        CSVDownloadComponent,
        ProgressBarComponent,
        AutoCompleteEditorComponent,
        CheckboxEditorComponent,
        DropdownEditorComponent,
        DoubleEditorComponent,
        PhoneRendererComponent,
        PhoneComponent,
        RadioComponent,
        InfoComponent,
        WaitComponent,
        DialogWrapperComponent,
    ],
    declarations: [
        AddToNgFormDirective,
        BreadcrumbComponent,
        LoginComponent,
        ConfirmationComponent,
        SaveComponent,
        LengthHintDirective,
        MatFormFieldDirective,
        EmbedDirective,
        RunAsUserComponent,
        TableLayoutComponent,
        AutoCompleteComponent,
        AutoCompleteMultiComponent,
        EmailRendererComponent,
        DateEditorComponent,
        CSVDownloadComponent,
        ProgressBarComponent,
        AutoCompleteEditorComponent,
        CheckboxEditorComponent,
        DropdownEditorComponent,
        DoubleEditorComponent,
        PhoneRendererComponent,
        PhoneComponent,
        RadioComponent,
        InfoComponent,
        WaitComponent,
        DialogWrapperComponent,
        ButtonRendererComponent,
        ButtonStringRendererComponent
    ]
})
export class SharedUiModule {}
