import {Component, Input, OnInit} from '@angular/core';
import {CSVBuilderService} from '@comm-apps/common';

@Component({
  selector: 'cad-lib-csvdownload',
  templateUrl: './csvdownload.component.html',
  styleUrls: ['./csvdownload.component.scss']
})
export class CSVDownloadComponent implements OnInit {

  @Input()
  fileName: string = "TableData";

  /**
   * array of properties that are the field names in the data.  This is typically the displayedColumns of mat-table
   */
  @Input()
  headers: string[];

  /**
   * Proper names of each field.  Should be a parallel array to headers.  This will be the column name in the CSV.
   * If omitted, the column names will be the header properties.   *
   */
  @Input()
  niceHeaders: string[];

  /**
   * If using ag-grid-angular - this is the columnDefs (which hold the property name and the nice name) - if using this input
   * headers and niceHeaders inputs will be ignored and calculated from the 'field' and 'headerName' values in the columnDefs
   */
  @Input()
  columnDefs: any[];

  /**
   * Array of objects.  ColumnDefs OR headers inputs should tie to the properties in this array.
   */
  @Input()
  data: any[];

  constructor(private csvExporter: CSVBuilderService) { }

  ngOnInit() {
  }

  public exportCSV(): void {
    if (this.columnDefs) {
      let headers: string[] = [];
      let niceHeaders: string[] = [];
      this.columnDefs.forEach((item) => {
        headers.push(item.field);
        niceHeaders.push(item.headerName);
      });
      this.csvExporter.downloadFileNice(this.fileName, niceHeaders, headers, this.data);
    } else if (this.niceHeaders) {
      this.csvExporter.downloadFileNice(this.fileName, this.niceHeaders, this.headers, this.data);
    } else {
      this.csvExporter.downloadFile(this.fileName, this.headers, this.data);
    }
  }
}
