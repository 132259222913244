<h1 mat-dialog-title>Run As User</h1>
<div mat-dialog-content (keyup.enter)="login()">
  <mat-form-field>
    <mat-label>User Id</mat-label>
    <input matInput [(ngModel)]="userId" type="text">
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="right">
  <div fxFlex fxLayoutAlign="end start">
    <button mat-raised-button color="primary" type="button" (click)="login()">Login</button>
    <button mat-raised-button type="button" (click)="closeDialog('Cancel')">Cancel</button>
  </div>
</div>
