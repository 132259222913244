import { Component, Input, OnInit } from '@angular/core';
import { PhonePipe } from '../../../../../common/src/lib/pipes/phone.pipe';

@Component({
  selector: 'cad-lib-phone-renderer',
  templateUrl: './phone-renderer.component.html',
  styleUrls: ['./phone-renderer.component.scss']
})
export class PhoneRendererComponent implements OnInit {

  @Input()
  public phoneNbr:string;

  @Input()
  public description: string;

  private phonePipe: PhonePipe = new PhonePipe();

  constructor() { }

  ngOnInit(): void {
    this.description = (this.description ? this.description : this.phoneNbr);
    this.formatDescription();
  }

  private formatDescription(): void {
    this.description = this.phonePipe.transform(this.description, []);
  }

}
