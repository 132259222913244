import {Component, Inject, OnInit} from '@angular/core';
import {NewVersionSnackbarComponent} from '../new-version-snackbar/new-version-snackbar.component';
import {HttpHelperService} from '@comm-apps/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import {cad} from '@comm-apps/common';
import Environment = cad.Environment;

@Component({
  selector: 'cad-lib-version-checker',
  template: ``,
  styles: []
})
export class VersionCheckerComponent implements OnInit {

  private displayTime: number = 60000;
  private checkInterval: number = 240000;
  private versioningScripts: string[] = ['main', 'runtime', 'polyfills'];

  constructor(
    private httpClient: HttpHelperService,
    private matSnackBar: MatSnackBar,
    @Inject('environment') public env: Environment
  ) { }

  ngOnInit() {
    if (this.env.localDevelopment) {
      return;
    }
      setTimeout(() => {
        this.checkForNewVersion();
      }, 6000);
  }

  public checkForNewVersion(): void {
    this.httpClient.request('index.html?unique=' + (new Date()).getTime(), {responseType:'text'}).subscribe((text) => {
      let nodeList: NodeListOf<Element> = document.querySelectorAll('body script[src]');

      let foundDiff: boolean = false;

      //iterate over the script elements and grab the source attribute.  if it matches the index file we just downloaded, there is NOT a new version
      nodeList.forEach((element: Element) => {
        if (!foundDiff) {
          let searchVal = element.getAttribute('src');
          searchVal = searchVal.substring(searchVal.lastIndexOf('/'));
          if (this.srcQualifies(searchVal) && text.indexOf(searchVal) === -1) {
            foundDiff = true;
            let sbRef = this.matSnackBar.openFromComponent(NewVersionSnackbarComponent, {duration: this.displayTime});
            sbRef.instance.sbRef = sbRef;
          }
        }
      });

    });

    setTimeout(() => {
      this.checkForNewVersion();
    }, this.checkInterval);

  }

  /**
   * Looks at the list of script starts with names from the versioningScripts array and lets us know if it is a script we want to look at.
   */
  private srcQualifies(src: string): boolean {
    let qualifies: boolean = false;
    this.versioningScripts.forEach((val) => {
      if (src.startsWith(val)) {
        qualifies = true;
      }
    });
    return qualifies;
  }

}
