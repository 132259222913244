import {Inject, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  private isDebug: boolean = false;
  private isInfo: boolean = false;
  private isWarn: boolean = false;
  private isError: boolean = false;

  constructor(@Inject('environment') environment: any,) {
    //provide your environment in your app.module so that this lib can find it.
    let logLevel: string = "DEBUG";
    if (environment && environment.logLevel && environment.logLevel !== '') {
      logLevel = environment.logLevel;
    }
    this.isDebug = (logLevel === 'DEBUG');
    this.isInfo = (logLevel === 'INFO') || this.isDebug;
    this.isWarn = (logLevel === 'WARN') || this.isInfo;
    this.isError = (logLevel === 'ERROR') || this.isWarn;

    console.log (`LogLevel is set to ${logLevel} and isDebug=${this.isDebug}, isInfo=${this.isInfo}, isWarn=${this.isWarn}, and isError=${this.isError}`);
  }

  public debug(message?: any, ...optionalArgs: any[]): void {
    if (this.isDebug) {
      if (optionalArgs.length === 0) {
        console.log(`[DEBUG]: ${message}`);
      } else {
        console.log(`[DEBUG]: ${message}`, optionalArgs);
      }
    }
  }
  public info(message?: any, ... optionalArgs: any[]): void {
    if (this.isInfo) {
      if (optionalArgs.length === 0) {
        console.log(`[INFO]: ${message}`);
      } else {
        console.log(`[INFO]: ${message}`, optionalArgs);
      }
    }
  }
  public warn(message?: any, ... optionalArgs: any[]): void {
    if (this.isWarn) {
      if (optionalArgs.length === 0) {
        console.warn(`[WARN]: ${message}`);
      } else {
        console.warn(`[WARN]: ${message}`, optionalArgs);
      }
    }
  }
  //currently do not allow arguments that can potentially be objects.
  public error(message?: any): void {
    if (this.isError) {
      console.error(`[ERROR]: ${message}`);
    }
  }

}
