<mat-card appearance="outlined"> 
  <mat-card-title>{{user.firstName}} {{user.lastName}}'s Profile</mat-card-title>
  <mat-card-content fxLayout="column" fxLayoutAlign="center start">
    <div fxFlex fxLayout="row" style="width: 25vw;">
      <div fxFlex fxLayout="column">
        <div fxFlex fxLayout="row" class="prop">
          <b fxFlex="25">User Name:</b>
          <a fxFlex>{{user.userName}}</a>
        </div>
        <div fxFlex fxLayout="row" class="prop">
          <b fxFlex="25">Name:</b>
          <a fxFlex>{{user.firstName}} {{user.lastName}}</a>
        </div>
        <div fxFlex fxLayout="row" class="prop">
          <b fxFlex="25">Email:</b>
          <a fxFlex>{{user.email}}</a>
        </div>
      </div>
    </div>
    
    <div fxLayout="row">
      <mat-list fxFlex>
        <div mat-subheader>General Roles</div>
        <mat-list-item *ngFor="let role of groupToUserRoleMap.get('General')">
          {{role}}
        </mat-list-item>
      </mat-list>
      <mat-list fxFlex *ngFor="let code of appCodes">
        <div mat-subheader>{{code.code}} Roles</div>
        <mat-list-item *ngFor="let role of groupToUserRoleMap.get(code.code)">
          {{role}}
        </mat-list-item>
      </mat-list>
    </div>

  </mat-card-content>
</mat-card>
<BR/>
<mat-card appearance="outlined"> 
  <mat-card-title>Preferences</mat-card-title>
  <mat-card-content fxLayout="column">
    <div fxLayout="row" fxLayoutGap="5em">
      <cad-lib-auto-complete
                      fxFlex
                      matTooltip="Select your Theme"
                      placeholder="User Theme"
                      [(selectedData)]="currentTheme"
                      [data]="themeList"
                      displayProperty="description"
                      (valueChanged)="setCurrentThemeVal($event)"
                      >
      </cad-lib-auto-complete>
      <!-- <button fxFlex  mat-button color="primary" (click)="setCurrentThemeVal()">Apply Theme</button> -->

      <div fxFlex fxLayoutAlign="end center">
        <button mat-raised-button color="primary" (click)="resetPrefs()">Reset User Prefs</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>