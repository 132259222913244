import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { AlertService } from '@comm-apps/alert';
import { AutoUnsubscribables, AutoUnsubscriber, cad } from '@comm-apps/common';
import Alert = cad.Alert;

@Component({
  selector: 'cad-lib-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @ViewChild(MatMenuTrigger, { static: true })
  trigger: MatMenuTrigger;
  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  public messages: Array<Alert> = new Array<Alert>();
  public highestSeverity: number = 1;
  public severityClass: string = '';
  public newMessageCount: number = 0;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.subs.newSub = this.alertService.alerts$.subscribe((alert: Alert) => {
      this.messages.unshift(alert);
      this.updateSeverity(alert);
      this.newMessageCount++;
    });
  }

  public deleteAll(): void {
    this.messages = [];
    this.resetIndicators();
  }

  openMessagesMenu() {
    this.resetIndicators();
    this.trigger.openMenu();
  }

  private resetIndicators() {
    //Makes bell look like there are no new messages.
    this.newMessageCount = 0;
    this.highestSeverity = 1;
    this.severityClass = '';
  }

  private updateSeverity(alert: Alert) {
    if (this.severityClass === '') {
      this.severityClass = alert.alertClass;
    }
    if (alert.severity > this.highestSeverity) {
      this.highestSeverity = alert.severity;
      this.severityClass = alert.alertClass;
    }
  }
}
