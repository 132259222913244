import {Inject, Injectable, Optional} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {LogService} from '@comm-apps/log';
import {cad} from "@comm-apps/common";
import Environment = cad.Environment;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public router: Router, private log:LogService, @Inject('environment') @Optional() protected environment: Environment) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Get the auth token from the service.
    const authToken = localStorage.getItem('Auth');
    const runAsToken = localStorage.getItem('X-On-Behalf-Of');

    // Clone the request and set the new header in one step.
    let authReq;
    if (authToken) {
      authReq = req.clone({ withCredentials: true, setHeaders: { Authorization: authToken } });
    } else {
      authReq = req.clone({withCredentials: true});
    }

    if (runAsToken) {
      if (authReq) {
        authReq = authReq.clone({ withCredentials: true, setHeaders: {'X-On-Behalf-Of': runAsToken}});
      } else {
        authReq = req.clone({ withCredentials: true, setHeaders: {'X-On-Behalf-Of': runAsToken}});
      }
    }

    // Only need to send over the auth token once.
    if (!this.isLocal()) {
      localStorage.removeItem('Auth');
    }

    // send cloned request with header to the next handler.
    return next.handle(authReq ? authReq : req).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
              localStorage.removeItem('Auth');
              localStorage.removeItem('X-On-Behalf-Of');
              this.log.error('not authorized to perform this task, please login');
              if (err.status === 401 && this.environment.name != 'DEV') {
                this.router.navigate(['not-authorized']);
              }
            }
          }
        }
      )
    );
  }

  private isLocal(): boolean {
    if (!this.environment) {
      return false;
    }
    if (this.environment.localDevelopment) {
      return this.environment.localDevelopment;
    }
    return false;
  }
}
