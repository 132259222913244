<div class="main-wrapper" fxFlex>
  <div *ngIf="!compact">
    <div fxLayout="row" class="header-wrapper">
      <h3>{{showPercentage ? percentValue + '%': ''}}</h3>
      <label #barTextLabel class="bar-text" [ngClass]="[status, lineBreak ? '' : 'single-line']" [ngStyle]="showPercentage && { 'top': '1.755em' }" fxLayoutAlign="start end">{{text}}</label>
    </div>
    <div class="bar-wrapper">
      <mat-progress-bar [ngClass]="status" [ngStyle]="{'height': barHeight}" mode="determinate" [value]="percentValue" [matTooltip]="tooltip" matTooltipPosition="above"></mat-progress-bar>
    </div>
    <div *ngIf="footer || showDetail" fofxLayout="row" fxLayoutAlign="space-between center" class="footer-wrapper">
      <label class="footer-text">{{footer}}</label>
      <label *ngIf="showDetail">{{value | currency}} / {{max | currency}}</label>
    </div>
  </div>
  <div *ngIf="compact">
    <div fxLayout="row" fxLayoutAlign="start center" [matTooltip]="tooltip" matTooltipPosition="above">
      <mat-icon [ngClass]="status">{{icon}}</mat-icon>
      <label class="compact-text">{{text}}</label>
    </div>
  </div>
</div>
