<div fxLayout="column" fxFlex>
  <mat-radio-group [(ngModel)]="modelValue"
                   fxFlex
                   name="radio_{{placeholder}}{{uuid}}"
                   addToNgForm
                   [disabled]="disabled"
                   [required]="required">
    <mat-radio-button *ngFor="let complexity of data" [value]="complexity[resultProperty]"
                      (change)="radioChange($event)" fxFlex>
      {{complexity[displayProperty]}}
    </mat-radio-button>
  </mat-radio-group>
  <!-- Putting label at the bottom because we want to style using mat-radio-group properties and sibling label, 
    but we can only get next label, not previous.  CSS in this component will do an order neg one to render above-->
  <label>
    {{placeholder}}
    <span class="mat-placeholder-required"
          *ngIf="required"> *</span>
  </label>
</div>
