import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import {LogService} from "@comm-apps/log";
import {Menu} from '@comm-apps/shared-ui';

export enum MenuCssClass {
  Show = 'show',
  Expanded = 'expanded',
}

export enum ElementIdPrefix {
  MenuItem = 'menu-item-',
  SubMenu = 'sub-menu-',
  DropArea = 'drop_area',
}

@Injectable()
export class MenuService {
  constructor(private location: Location, private router: Router, private log: LogService) {}

  public expandActiveSubMenu(menu: Array<Menu>): void {
    const url = this.location.path();
    const routerLink = url;

    let activeMenuItem: Menu = this.findMenuForURL(routerLink, menu);
    this.log.debug("Expanding active menu to menu ", activeMenuItem);

    if (activeMenuItem) {
      while (activeMenuItem.parentId !== 0) {
        const parentMenuItem: Menu = menu.filter((item: Menu): boolean => {
          return item.id === activeMenuItem.parentId
        })[0];
        activeMenuItem = parentMenuItem;
        this.showMenuItem(activeMenuItem.id);
      }
    }
  }

  public showMenuItem(menuId: number): void {
    const menuItem: HTMLElement = document.getElementById(ElementIdPrefix.MenuItem + menuId);
    const subMenu: HTMLElement = document.getElementById(ElementIdPrefix.SubMenu + menuId);

    if (subMenu) {
      if (!(subMenu.classList && subMenu.classList.contains(MenuCssClass.Show))) {
        subMenu.classList.add(MenuCssClass.Show);
        menuItem.classList.add(MenuCssClass.Expanded);
      }
    }
  }

  public toggleMenuItem(menuId: number): void {
    const menuItem: HTMLElement = document.getElementById(ElementIdPrefix.MenuItem + menuId);
    const subMenu: HTMLElement = document.getElementById(ElementIdPrefix.SubMenu + menuId);

    if (subMenu) {
      if (subMenu.classList.contains(MenuCssClass.Show)) {
        subMenu.classList.remove(MenuCssClass.Show);
        menuItem.classList.remove(MenuCssClass.Expanded);
      } else {
        subMenu.classList.add(MenuCssClass.Show);
        menuItem.classList.add(MenuCssClass.Expanded);
      }
    }
  }

  public closeOtherSubMenus(menu: Array<Menu>, menuId: number): void {
    const currentMenuItem: Menu = menu.filter((item: Menu): boolean => {
      return item.id === menuId;
    })[0];

    if (currentMenuItem.hasSubMenu && !currentMenuItem.target) {
      let ids: number[] = this.getMenuIdsInMenuPath(menu, currentMenuItem);

      menu.forEach((item: Menu) => {
        if (!ids.includes(item.id) ) {
          const subMenu: HTMLElement = document.getElementById(ElementIdPrefix.SubMenu + item.id);
          const menuItem: HTMLElement = document.getElementById(ElementIdPrefix.MenuItem + item.id);

          if (subMenu && subMenu.classList.contains(MenuCssClass.Show)) {
            subMenu.classList.remove(MenuCssClass.Show);
            menuItem.classList.remove(MenuCssClass.Expanded);
          }
        }
      });
    }
  }

  private getMenuIdsInMenuPath(menu: Array<Menu>,currentMenuItem: Menu): number[] {
    let ids: number[] = [currentMenuItem.id];
    let activeMenuItem: Menu = currentMenuItem;

    while (activeMenuItem.parentId !== 0) {
      const parentMenuItem: Menu = menu.filter((item: Menu): boolean => {
        return item.id === activeMenuItem.parentId
      })[0];
      activeMenuItem = parentMenuItem;
      ids.push(activeMenuItem.id);
    }

    return ids;
  }

  public findMenuForURL(url: string, menus: Array<Menu>): Menu {
    let menuForUrl: Menu;

    if (url.indexOf("?") > 0) {
      menus.forEach((menu: Menu): void => {
        if (url.indexOf(menu.routerLink)>=0 && this.hasAllParams(menu, url)) {
          menuForUrl = menu;
        }
      });
    } else {
      menus.forEach((menu: Menu): void => {
        if (menu.routerLink === url) {
          menuForUrl = menu;
        }
      });
    }

    return menuForUrl;
  }

  public hasAllParams(menu: Menu, routePath: string): boolean {
    if (!menu.queryParams) {
      return true;
    }

    let hasParams: boolean = true;
    Object.keys(menu.queryParams).forEach((paramName: string): void => {
      let paramKV: string = paramName + "=" + encodeURI(menu.queryParams[paramName]);

      if (routePath.indexOf(paramKV) < 0) {
        hasParams = false;
      }
    });
    return hasParams;
  }
}
