<form #loginForm="ngForm">
  <h1 mat-dialog-title>Please Login</h1>
  <div fxLayout="column">
  <div mat-dialog-content fxLayout="column">
    <mat-form-field>
      <mat-label>User Id</mat-label>
      <input matInput [(ngModel)]="userId" name="userId" type="text" required>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input matInput [(ngModel)]="password" name="pass" type="password" required>
    </mat-form-field>
  </div>
  <div *ngIf="data && data.message">
    <h3 class="warn-color">{{data.message}}</h3>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="right">
    <div fxFlex fxLayoutAlign="end start">
      <button mat-raised-button color="primary" [disabled]="!loginForm.form.valid" type="submit" (click)="login()">Login</button>
      <button mat-raised-button type="cancel" (click)="closeDialog('Cancel')">Cancel</button>
    </div>
  </div>
</div>
</form>
