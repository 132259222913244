import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { emailAddressValidator } from '../validators/email-address.validator';

@Directive({
  selector: '[emailAddress]',
  providers: [{provide: NG_VALIDATORS, useExisting: EmailAddressDirective, multi: true}]
})
export class EmailAddressDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors {
    return emailAddressValidator()(control);
  }

}
