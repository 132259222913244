
import { Component, OnInit, Inject } from '@angular/core';
import { AutoUnsubscriber, AutoUnsubscribables } from '@comm-apps/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '@comm-apps/alert';
import { Subscription } from 'rxjs';
import { ApplicationConfig } from '../../../../core/classes/data-types';
import { ApplicationConfigService } from '../../../../services/application-config.service';

@Component({
  selector: 'car-edit-application-config-dialog',
  templateUrl: './edit-application-config-dialog.component.html',
  styleUrls: ['./edit-application-config-dialog.component.scss']
})
export class EditApplicationConfigDialogComponent implements OnInit {

  public applicationConfig: ApplicationConfig;
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  constructor(
    private applicationConfigService: ApplicationConfigService,
    public dialogRef: MatDialogRef<ApplicationConfig>,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.applicationConfig = JSON.parse(JSON.stringify(data.applicationConfig));

   }

  ngOnInit() {
  }

  save() {
    let sub: Subscription;
    sub = this.applicationConfigService.save(this.applicationConfig).subscribe(message => {
      this.dialogRef.close(message);
    },  (error) => {
      this.alertService.danger('There was an error saving the application configuration', error);
    }, () => sub && sub.unsubscribe ? sub.unsubscribe() : null);
  }

  cancel() {
    this.dialogRef.close(null);
  }

}
