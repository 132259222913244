import { Injectable, NgZone } from '@angular/core';
import { cad } from '@comm-apps/common';
import Alert = cad.Alert;
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as _ from 'lodash-es';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private router: Router, private ngZone: NgZone) {}

  public alerts$: Subject<Alert> = new Subject<Alert>();
  private alertsStore: Alert[] = [];
  private dismissOnTimeoutDefault: number = 6000;
  private verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  private horizontalPosition: MatSnackBarHorizontalPosition = 'center';

  public getAllAlerts(): Alert[] {
    return _.clone(this.alertsStore);
  }

  public success(msg: string, dismissOnTimeout: number = this.dismissOnTimeoutDefault, horizontalPosition: MatSnackBarHorizontalPosition = this.horizontalPosition,
                 verticalPosition: MatSnackBarVerticalPosition = this.verticalPosition): void {
    this.addAlert({
      alertClass: 'alert-color-green',
      dismissOnTimeout: dismissOnTimeout,
      icon: 'done',
      msg: msg,
      severity: 1,
      type: 'success',
      verticalPosition: verticalPosition,
      horizontalPosition: horizontalPosition
    });
  }

  public info(msg: string, href?: string, linkDesc?: string, dismissOnTimeout: number = this.dismissOnTimeoutDefault, horizontalPosition: MatSnackBarHorizontalPosition = this.horizontalPosition,
              verticalPosition: MatSnackBarVerticalPosition = this.verticalPosition): void {
    this.addAlert({
      alertClass: 'alert-color-blue',
      dismissOnTimeout: dismissOnTimeout,
      href :href,
      linkDesc: linkDesc,
      msg: msg,
      severity: 2,
      type: 'info',
      verticalPosition: verticalPosition,
      horizontalPosition: horizontalPosition
    });
  }

  public warning(msg: string, dismissOnTimeout: number = this.dismissOnTimeoutDefault, horizontalPosition: MatSnackBarHorizontalPosition = this.horizontalPosition,
                 verticalPosition: MatSnackBarVerticalPosition = this.verticalPosition): void {
    this.addAlert({
      alertClass: 'alert-color-yellow',
      dismissOnTimeout: dismissOnTimeout,
      msg: msg,
      severity: 3,
      type: 'warning',
      verticalPosition: verticalPosition,
      horizontalPosition: horizontalPosition
    });
  }

  public danger(msg: string, theError: HttpErrorResponse = null, dismissOnTimeout: number = this.dismissOnTimeoutDefault, horizontalPosition: MatSnackBarHorizontalPosition = this.horizontalPosition,
                verticalPosition: MatSnackBarVerticalPosition = this.verticalPosition): void {
    this.addAlert({
      alertClass: 'alert-color-red',
      error: theError,
      dismissOnTimeout: dismissOnTimeout,
      icon: 'error',
      msg: msg,
      severity: 4,
      type: 'error',
      verticalPosition: verticalPosition,
      horizontalPosition: horizontalPosition
    });
  }

  public addAlert(alert: cad.Alert): void {
    _.defaults(alert, {
      date: new Date(),
      dismissOnTimeout: this.dismissOnTimeoutDefault,
      icon: alert.type,
      url: this.router.url
    });

     this.alertsStore.unshift(alert);
     this.alerts$.next(_.clone(alert));

//Just in case, keeping this around
//    this.ngZone.run(() => {}); // force change detection & full render of the DOM -- when there are simulataneous in-flight HTTP requests, Angular can fail to render after the response
  }

}
