/**
 * Created by esarrazin on 5/19/2017.
 */
import {
  Directive, ElementRef, AfterViewInit, Renderer2, OnDestroy, QueryList, ContentChildren
} from '@angular/core';
import { NgModel } from '@angular/forms';
import {AutoUnsubscribables, AutoUnsubscriber} from '@comm-apps/common';

/*
 This directive will append length information to the input assuming that maxLength or minLength are set on the input
 */

@Directive ({
  selector: 'mat-form-field',
  host: {
    '(focusin)': 'gotFocus()',
    '(focusout)': 'lostFocus()',
  }
})
export class LengthHintDirective implements AfterViewInit, OnDestroy {
  @ContentChildren(NgModel) public models: QueryList<NgModel>;

  @AutoUnsubscriber() public subs: AutoUnsubscribables;

  private inputElement: any;
  private lengthHint: any;
  private model: NgModel;
  private listener: Function;
  private hasFocus: boolean = false;

  constructor(
    el: ElementRef,
    private renderer: Renderer2
  ) {
    if (el.nativeElement) {
      this.inputElement = el.nativeElement;
    }
  }

  gotFocus(): void {
    this.hasFocus = true;
    this.appendLengthInfo();
  }

  lostFocus(): void {
    this.hasFocus = false;
    this.removeLengthInfo();
  }

  ngAfterViewInit(): void {
    if (this.models && this.models.first) {
      this.model = this.models.first;
      this.subs.newSub = this.model.valueChanges.subscribe(() => this.appendLengthInfo());
    }
  }

  ngOnDestroy(): void {
    if (this.listener) { this.listener(); }
  }

  removeLengthInfo(): void {
    //We could remove and re-add the hint, but there are fewer dom changes to remove and add classes.
    if (this.inputElement && this.lengthHint) {
      this.renderer.removeClass(this.lengthHint, 'hint-length');
      this.renderer.addClass(this.lengthHint, 'hidden');
    }
  }

  appendLengthInfo(): void {
    if (!this.hasFocus) return;
    let input = this.inputElement.querySelectorAll('input')[0];
    if (!input) {
      input = this.inputElement.querySelectorAll('textarea')[0];
    }
    if (input) {
      let maxLenAttr = input.attributes.maxlength;
      let minLenAttr = input.attributes.minlength;
      if (maxLenAttr || minLenAttr) {
        if (!this.lengthHint) {
          this.lengthHint = this.renderer.createElement('mat-hint');
          this.renderer.appendChild(this.inputElement, this.lengthHint);
          this.renderer.addClass(this.lengthHint, 'hint-length');
          this.listener = this.renderer.listen(input, 'keyup', () => this.appendLengthInfo());
        } else {
          //The hint is already there, but we need to make sure it is not hidden, as we hide on lost focus.
          this.renderer.addClass(this.lengthHint, 'hint-length');
          this.renderer.removeClass(this.lengthHint, 'hidden');
        }
        let msg = (this.model && this.model.value && this.model.value.length ? this.model.value.length.toString() : '0');
        if (maxLenAttr && minLenAttr) {
          msg += '/' +  minLenAttr.nodeValue + '-' + maxLenAttr.nodeValue;
        } else if (maxLenAttr) {
          msg += '/' + maxLenAttr.nodeValue;
        } else {
          msg += '/' + minLenAttr.nodeValue + '+';
        }
        this.lengthHint.innerHTML = msg;
      }
    }
  }
}
