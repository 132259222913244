import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function uniqueValueValidator(takenValues: string[]): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {

        const value = String(control.value).toLowerCase().trim();
        const unique = !takenValues.includes(value);
        return !unique ? {uniqueValue: {value: control.value, message: "Duplicate Value Entered"}}: null;
    }
}
