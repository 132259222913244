import { Injectable } from '@angular/core';
import { AutoUnsubscriber, AutoUnsubscribables } from '@comm-apps/common';
import { HttpHelperService } from '@comm-apps/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Role } from '../core/classes/data-types';


@Injectable({
  providedIn: 'root'
})
export class RoleService {

  public endpoint: string = 'api/role';
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  constructor(public apiHelper: HttpHelperService) {
  }

  public filter( role: Role = {} ) : Observable<Role[]> {
    return this.apiHelper.request<Role[]>(  `${ this.endpoint }/filter`, { body: role } ).pipe(map(
      roles => {
        return roles.sort((a,b) => a.userFullName > b.userFullName ? 1 : a.userFullName === b.userFullName ? a.roleName > b.roleName ? 1 : -1 : -1 );
      }));
  }

  public save( role: Role ) : Observable<Role> {
    return this.apiHelper.request<Role>(`${ this.endpoint }`, { body: role } ).pipe(map(
      result => {
        return result;
      }));
  }

  public delete( role: Role) : Observable<Role> {
    return this.apiHelper.request<Role>(`${ this.endpoint }`, { body: role, method: 'DELETE' } );
  }


}
