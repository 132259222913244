import { Injectable } from '@angular/core';
import {LoginComponent} from "./login.component";
import { MatDialog } from "@angular/material/dialog";
import {AutoUnsubscribables, AutoUnsubscriber} from "@comm-apps/common";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class CadLoginDialogService {

  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  constructor(public dialog: MatDialog) { }

  public login(): Observable<any> {
    const dialogRef = this.dialog.open(LoginComponent, {});
    return dialogRef.afterClosed();
  }
}
