import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: 'embed',
})
export class EmbedDirective implements OnInit {

  @Input() embedSrc: string;

  constructor(public el: ElementRef, public renderer: Renderer2) {
  }

  ngOnInit(): void {
    if (this.embedSrc) {
      this.renderer.setProperty(this.el.nativeElement, 'src', this.embedSrc);
    }
  }


}
