import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cad-lib-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {
  public message: string = 'Something happened.';
  public title: string = 'Notice';
  public ok: string = 'OK';

  constructor(public dialogRef: MatDialogRef<InfoComponent>) {}

  ngOnInit() {}
}
