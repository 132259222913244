import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject} from 'rxjs';
import {CadLoginCallbackService, LoginResult} from './cad-login-service-callback.interface';
import {LogService} from '@comm-apps/log';

@Injectable({
  providedIn: 'root'
})
export class DefaultUserService implements CadLoginCallbackService {

  //Since this Service is Provided AND injected, there are two instances.  We only want one Subject.
  static currentUserHolder$: BehaviorSubject<LoginResult> = new BehaviorSubject<LoginResult>(
    new LoginResult(false, null,null)
  );

  public currentUser$: BehaviorSubject<LoginResult> = DefaultUserService.currentUserHolder$;

  private user: string = 'Joe Blo';

  constructor(private log: LogService) { }

  public loginUser() : Observable<any> {
    this.log.info("Placeholder for login user - provide your implementation in app.module");

    this.currentUser$.next(new LoginResult(true, this.user,null));
    return this.currentUser$.asObservable();
  }
  public logoutUser() : Observable<any> {
    this.log.info("Placeholder for logout user - provide your implementation in app.module");
    return new Observable();
  }

}
