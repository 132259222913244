import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppSettings } from '../../app.settings';
import { MenuService }  from '../../../../../../libs/shared-ui/src/lib/toolbar/menu/menu.service';
import {AutoUnsubscribables, AutoUnsubscriber, cad} from '@comm-apps/common';
import Settings = cad.Settings;
import { verticalMenuItems } from '../components/menu/menu';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';

const SMALL_WIDTH: number = 768;

@Component({
  selector: 'cad-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss'],
  providers: [ MenuService]
})
export class ViewComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenav')
  sidenav: any;
  public settings: Settings;
  public menuOption: string;
  public menuTypeOption: string;
  public isStickyMenu = false;
  public lastScrollTop: number = 0;
  public showBackToTop: boolean = false;
  public scrollItem: CdkScrollable;

    
  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    private sharedMenuService: MenuService,
    private scroll: ScrollDispatcher,
    private changeDetectionRef: ChangeDetectorRef,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    if (window.innerWidth <= 768) {
      this.settings.menu = 'vertical';
      this.settings.sidenavIsOpened = false;
    }
    this.menuOption = this.settings.menu;
    this.menuTypeOption = this.settings.menuType;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 300);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (window.innerWidth <= 768) {
          this.sidenav.close();
        }
      }
    });
    if (this.settings.menu === 'vertical') {
      this.sharedMenuService.expandActiveSubMenu(verticalMenuItems);
    }

    this.subs.newSub = this.scroll.scrolled().subscribe((data: CdkScrollable) => {
      this.onscroll(data);
    });
  }

  public toggleSidenav() {
    this.sidenav.toggle();
  }

  public onscroll(data: CdkScrollable): void {
    this.scrollItem = data;
    if (!this.scrollItem.getElementRef().nativeElement.classList.contains('main-outlet')) return;
    let scrollTop = this.scrollItem.measureScrollOffset('top');

    let shouldShow = (scrollTop > 10);// > PixelValue.RegisterBoxHeight
    if (this.showBackToTop != shouldShow) {
      this.showBackToTop = shouldShow;
      //for some reason change detection is not firing in the scroll events (maybe performance reasons) - do it manually
      this.changeDetectionRef.detectChanges();
    }

    //not sure what this is actually doing...
    if (this.settings.menu === 'horizontal') {
      if (this.settings.fixedHeader) {
        const currentScrollTop: number = scrollTop > 56 ? scrollTop : 0;
        currentScrollTop > this.lastScrollTop ? (this.isStickyMenu = true) : (this.isStickyMenu = false);
        this.lastScrollTop = currentScrollTop;
      } else {
        scrollTop > 56 ? (this.isStickyMenu = true) : (this.isStickyMenu = false);
      }
    }
  }

  public scrollToTop() {
    const scrollDuration: number = 200;

    const scrollInterval: number = <any>setInterval(() => {
      let scrollTop = this.scrollItem.measureScrollOffset('top');
      const scrollStep: number = scrollTop - (scrollTop / (scrollDuration / 20));
      if (scrollTop !== 0) {
        this.scrollItem.scrollTo({top: scrollStep});
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
    if (window.innerWidth <= SMALL_WIDTH) {
      this.scrollItem.scrollTo({top: 0});
    }
  }

  @HostListener('window:resize')
  public onWindowResize(): void {
    if (window.innerWidth <= 768) {
      this.settings.sidenavIsOpened = false;
    } else {
      if (this.settings.menu === 'vertical') {
        this.settings.sidenavIsOpened = true;
      }
    }
  }

  public closeSubMenus() {
    const menu = document.querySelector('.sidenav-menu-outer');
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        const child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }
}
