import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CoreModule} from '../../core/core.module';
import {FormsModule} from '@angular/forms';
import {SharedUiModule} from '@comm-apps/shared-ui';
import {HttpModule} from '@comm-apps/http';
import { MatButtonModule } from '@angular/material/button';
import {RouterModule, Routes} from '@angular/router';
import {ViewComponent} from '../../core/view/view.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {AdminGuard} from '../../guards/admin.guard';
import {AgGridModule} from 'ag-grid-angular';
import {CodeValueComponent} from './code-value/code-value.component';
import { ViewUserActivityComponent } from './view-user-activity/view-user-activity.component';
import { EditCodeValueDialogComponent } from './code-value/edit-code-value-dialog/edit-code-value-dialog.component';
import { RoleComponent } from './role/role.component';
import { ApplicationConfigComponent } from './application-config/application-config.component';
import { EditApplicationConfigDialogComponent } from './application-config/edit-application-config-dialog/edit-application-config-dialog.component';

const adminRoutes: Routes = [
  {
    path: 'restr-admin/admin',
    component: ViewComponent,
    canActivate: [AdminGuard],
    data: { breadcrumb: 'Admin' },
    children: [
      {
        path: '',
        redirectTo: '/admin/codes',
        pathMatch: 'full'
      },
      {
        path: 'app-config',
        component: ApplicationConfigComponent,
        data: { breadcrumb: 'Application Configuration' }
      },
      {
        path: 'role',
        component: RoleComponent,
        data: { breadcrumb: 'User Roles' }
      },
      {
        path: 'view-user-activity',
        component: ViewUserActivityComponent,
        data: { breadcrumb: 'View User Activity', }
      },
      { 
        path: 'codes',
        data: { breadcrumb: 'Codes' },
        children: [
          {
            path: 'codes',
            component: CodeValueComponent,
            data: { breadcrumb: 'Codes' }
          },
        ]
      },
    ]
  }
];

@NgModule({
    imports: [
        RouterModule.forChild(adminRoutes),
        CoreModule,
        FormsModule,
        CommonModule,
        SharedUiModule,
        HttpModule,
        MatButtonModule,
        MatCheckboxModule,
        AgGridModule,
    ],
    declarations: [
        CodeValueComponent,
        EditCodeValueDialogComponent,
        ViewUserActivityComponent,
        ApplicationConfigComponent,
        RoleComponent,
        EditApplicationConfigDialogComponent,
    ]
})
export class AdminModule { }
