import {Injectable} from '@angular/core';
import {HttpHelperService} from '@comm-apps/http';
import {map, Observable, of} from 'rxjs';
import { AllUser } from '../core/classes/data-types';
import { CACHE_AGE_MINS } from '../core/enums/enums';
import * as _ from 'lodash-es';

@Injectable({
  providedIn: 'root'
})
export class AllUserService {
  private endpoint: string = 'public-api/all-user';

  constructor(private apiHelper: HttpHelperService) {
  }

  findAll(): Observable<AllUser[]> {
    return this.apiHelper.requestOrCache(CACHE_AGE_MINS.ALL_USER, `${this.endpoint}/all`).pipe(
      map(allUsers => this.setFullNamesAndSort(allUsers))
    );
    
  }

  find(search: string): Observable<AllUser[]> {
    return this.apiHelper.requestOrCache(CACHE_AGE_MINS.ALL_USER, `${this.endpoint}?search=${search}`).pipe(
      map(allUsers => this.setFullNamesAndSort(allUsers))
    );
  }

  private setFullNamesAndSort(allUsers: AllUser[]) {
    allUsers.forEach(allUser => {
      allUser.fullName = `${allUser.firstName} ${allUser.lastName}`;
    })
    return _.sortBy(allUsers, allUser => allUser.lastName.toLowerCase());
  }

}
