<div fxLayout="row">
  <mat-form-field fxFlex [floatLabel]="floatLabel">
    <mat-label>{{placeholder}}</mat-label>
    <mat-chip-grid style="padding-top: 15px;" #chipList aria-label="Selection" addToNgForm [required]="required" [(ngModel)]="itemStringList" name="chipList_{{uuid}}">
      <mat-chip-row
        *ngFor="let item of itemStringList"
        selectable="false"
        [removable]="!disabled"
        (removed)="remove(item)"
        (focus)="getOffMe($event)">
        {{item}}
        <mat-icon *ngIf="!disabled" matChipRemove>cancel</mat-icon>
      </mat-chip-row>  
      
    </mat-chip-grid>  
    <input type="text"
      #inputField
      matInput
      name="autoCom_{{placeholder}}{{uuid}}"
      [matChipInputFor]="chipList"
      [placeholder]="(disabled?'Not Editable':'Choose...')"
      [disabled]="disabled"
      [(ngModel)]="searchText"
      (ngModelChange)="filterData()"
      (keydown.enter)="$event.preventDefault()"
      (keydown.arrowDown)="changing=true"
      [matAutocomplete]="autocompleteRef"
      (blur)="blur($event)"
      (focus)="onFocus($event)"
    />
    <button type="button" [tabIndex]="-1" mat-button *ngIf="searchText && searchText !== '' && !disabled" matSuffix mat-icon-button aria-lable="Clear" (click)="clear()">
      <mat-icon>clear</mat-icon>
    </button>
    <mat-autocomplete #autocompleteRef="matAutocomplete" panelWidth="{{panelWidth}}" (optionSelected)="selectData($event)"
                      [displayWith]="displayWith" [autoActiveFirstOption]="true">
      <mat-option *ngFor="let theData of filteredData" [value]="theData">{{ displayProperty ? theData[displayProperty] : theData}}</mat-option>
    </mat-autocomplete>

  </mat-form-field>
</div>