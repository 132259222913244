<button mat-icon-button [matMenuTriggerFor]="userMenu" #userMenuTrigger="matMenuTrigger">
  <mat-icon>account_circle</mat-icon>
</button>

<mat-menu #userMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu user-menu">
  <!--<span (mouseleave)="userMenuTrigger.closeMenu()">-->

  <a mat-menu-item *ngIf="!userName" (click)="login()">
    <mat-icon>power_settings_new</mat-icon>
    <span>Log in</span>
  </a>
  <a mat-menu-item *ngIf="userName" routerLink="{{profilePath}}">
    <mat-icon>person</mat-icon>
    <span>{{userName}}'s Profile</span>
  </a>
  <a mat-menu-item *ngIf="runAsUserOption && userName && !runAsUserName" (click)="runAsUser()">
    <mat-icon>person_add</mat-icon>
    <span>Run As User</span>
  </a>
  <a mat-menu-item *ngIf="userName && runAsUserName" (click)="stopRunAsUser()">
    <mat-icon>person_add_disabled</mat-icon>
    <span>Stop Run As User</span>
  </a>
  <a mat-menu-item *ngIf="userName && settingsPath" routerLink="{{settingsPath}}">
    <mat-icon>settings</mat-icon>
    <span>Settings</span>
  </a>
  <a mat-menu-item *ngIf='helpPath' routerLink="{{helpPath}}">
    <mat-icon>help</mat-icon>
    <span>Help</span>
  </a>
  <a mat-menu-item *ngIf='!helpPath' (click)="help()">
    <mat-icon>help</mat-icon>
    <span>Help</span>
  </a>
  <div *ngIf="userName" class="divider"></div>
  <a mat-menu-item *ngIf="userName" (click)="logout()">
    <mat-icon>power_settings_new</mat-icon>
    <span>Log out</span>
  </a>
  <!--</span>-->
</mat-menu>
