import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cad-lib-save',
  templateUrl: './save.component.html',
  styleUrls: ['./save.component.scss']
})
export class SaveComponent implements OnInit {
  public message: string = 'Do you want to save the changes you made?';
  public title: string = 'Save Changes?';
  public yes: string = 'Save';
  public no: string =  `Don't Save`;
  public cancel: string = 'Cancel';

  constructor(public dialogRef: MatDialogRef<SaveComponent>) {}

  ngOnInit() {}
}
