import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {AutoUnsubscribables, AutoUnsubscriber} from '../../mixins/auto-unsubscriber.mixin';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  constructor() {
    this.subs.newSub = this.isSpinning$.subscribe(isSpininng => this._isSpinning = isSpininng);
   }

  private _isSpinning: boolean = false;
  @AutoUnsubscriber() private subs: AutoUnsubscribables;
  public isSpinning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this._isSpinning);
  public pendingRequests = [];

  public addRequest() : void {
    this.pendingRequests.push(1);
    if(!this._isSpinning) {
      this.isSpinning$.next(true);
    }
  }

  public completeRequest() : void {
    this.pendingRequests.pop();
    if(this._isSpinning && this.pendingRequests.length === 0) {
      this.isSpinning$.next(false);
    }
  }

}
