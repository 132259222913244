import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';
import {ViewComponent} from './core/view/view.component';
import { SimpleViewComponent } from './core/simple-view/simple-view.component';
import { AppGuard } from './guards/app/app.guard';
import { AdminGuard } from './guards/admin.guard';

export const routes: Routes = [
      {
        path: '',
        component: SimpleViewComponent,
        loadChildren: () => import('./pages/request-forms/request.module').then(m => m.RequestModule),
      },
      {
        path: 'restr-user',
        canActivate: [AppGuard],
        component: ViewComponent,
        loadChildren: () => import('./pages/restricted/restricted.module').then(m => m.RestrictedModule),
      },
      {
        path: 'restr-admin',
        canActivate: [AdminGuard],
        component: ViewComponent,
        loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule),
      },
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes, {
 // preloadingStrategy: PreloadAllModules // <- comment this line for activate lazy load
  // useHash: true
});
