<mat-sidenav-container [autosize]="true">
  <mat-sidenav *ngIf="settings.menu == 'vertical'" [opened]="settings.sidenavIsOpened" [mode]="'side'" #sidenav
               class="sidenav mat-elevation-z6">
    <cad-sidenav></cad-sidenav>
  </mat-sidenav>
  <mat-sidenav-content>
    <cad-toolbar (toggle)="toggleSidenav()"></cad-toolbar>
    <div id="main-content" class="inner-sidenav-content transition-2"
         [class.horizontal-menu-hidden]="isStickyMenu" fxLayout="column">
      <cad-lib-spinner></cad-lib-spinner>
      <div fxFlex fxLayoutAlign="start start" fxLayout="row">
        <cad-lib-breadcrumb [name]="settings.name" fxFlex="100"></cad-lib-breadcrumb>
      </div>
      <div class="main-outlet" cdkScrollable fxFlex="100">
        <router-outlet></router-outlet>
      </div>
    </div>

    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition-2"
         (click)="scrollToTop();">
      <mat-icon>arrow_upward</mat-icon>
    </div>

  </mat-sidenav-content>
</mat-sidenav-container>
