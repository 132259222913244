import { EditCodeValueDialogComponent } from './edit-code-value-dialog/edit-code-value-dialog.component';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AutoUnsubscriber, AutoUnsubscribables, AggridService } from '@comm-apps/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ConfirmationComponent } from 'libs/shared-ui/src/lib/dialogs/confirmation/confirmation.component';
import { AlertService } from '@comm-apps/alert';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {FilterPredicate} from "../../../../../../../libs/common/src/lib/classes/filter-predicate";
import {ActivatedRoute} from "@angular/router";
import { CodeDataService } from '../../../services/code-data.service';
import {CodeData, CARUser, WebserviceError} from '../../../core/classes/data-types';
import { LogService } from '@comm-apps/log';
import { ColumnApi } from 'ag-grid-community';
import { CheckboxEditorComponent } from 'libs/shared-ui/src/lib/table/checkbox-editor/checkbox-editor.component';
import { CARUserService } from '../../../services/user.service';
import { Roles } from '../../../models/roles.enum';
import * as _ from 'lodash-es'

@Component({
  selector: 'car-code-value',
  templateUrl: './code-value.component.html',
  styleUrls: ['./code-value.component.scss']
})
export class CodeValueComponent implements OnInit, OnDestroy {

   @AutoUnsubscriber() private subs: AutoUnsubscribables;

   public rowData: CodeData[];
   public selectedRow: CodeData;
   public codeType: string;
   public userType = false;
   private user: CARUser;

   public dirtyRowSet: Set<CodeData> = new Set<CodeData>();
   private gridApi;
   private gridColumnApi;

   public gridOptions = {
    getRowId: (data) => data.data.id,
  }
 
   public columnDefs = [
     {headerName: 'Type', field: 'type'},
     {headerName: 'Code', field: 'code'},
     {headerName: 'Description', field: 'description', type: ['editable']},
     {headerName: 'SortOrder', field: 'sortOrder', type: ['editable']},
     {headerName: 'Enabled', field: 'enabled', type: ['editable','checkbox']},
     {headerName: 'Updater', field: 'updater',},
     {headerName: 'Updated', field: 'updateDt', type:['dateColumn']},
   ];
 
   public defaultColDef = {
     flex: 1,
     filter: true,
     sortable: true,
     resizable: true,
   };
 
   public columnTypes = this.gridService.columnTypes;

   public frameworkComponents = this.gridService.frameworkComponents;

  constructor(
    private codeValueService: CodeDataService,
    breakpointObserver: BreakpointObserver,
    public dialog: MatDialog,
    private alertService: AlertService,
    private gridService: AggridService,
    private log: LogService,
    private activatedRoute: ActivatedRoute,
    private userService: CARUserService
  ) {

  }

  ngOnInit() {
    this.subs.newSub = combineLatest([this.activatedRoute.data, this.userService.currentUser$]).subscribe(([data, user]) => {
      this.codeType = data.codeType;
      this.userType = data.userType;
      this.user = user;
      this.fetchCodeValues();
    });
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.windowEventFunction);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    window.addEventListener('resize', this.windowEventFunction);

    this.gridService.bestFit(this.gridColumnApi, this.gridApi);
  }

  windowEventFunction = () => {
    setTimeout(() => {
      this.gridService.bestFit(this.gridColumnApi, this.gridApi);
    });
  }

  private fetchCodeValues() {
    let findCodesObs = this.codeValueService.findAll();
    if (this.codeType) {
      findCodesObs = this.codeValueService.findByType(this.codeType);
    }

    this.subs.newSub = findCodesObs.subscribe(codeValues => {
      this.selectedRow = undefined;
      this.rowData = codeValues;
      this.dirtyRowSet.clear();
    }, (err: WebserviceError): void => {
      this.log.error(err);
      this.alertService.danger('Could not retrieve Codes.');
    });
  }

  public newCodeValue() {
    const dialogRef = this.dialog.open(EditCodeValueDialogComponent, {
      height: '440px',
      width: '400px',
      data: { codeValue: {enabled:true}, codeType: this.codeType, userType: this.userType, codeValueTypes: _.uniq(_.map(this.rowData, 'type')) }
    });
    let sub: Subscription;
    sub = dialogRef.afterClosed().subscribe((result : CodeData) => {
      if(result) {
        this.gridApi.applyTransaction({add:[result]});
      }
    }, null, () => sub && sub.unsubscribe ? sub.unsubscribe() : null);
  }


  public delete( item: CodeData) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      disableClose: false
    });
    dialogRef.componentInstance.message = 'Are you sure you want to delete?';
    dialogRef.componentInstance.title = 'Delete?';
    let sub: Subscription;
    sub = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let aSub: Subscription;
        aSub = this.codeValueService.delete(item)
        .subscribe(codeValue => {
          this.alertService.success('The code value was deleted successfully.');
          this.fetchCodeValues();
        }, (error) => {
        }, () => aSub && aSub.unsubscribe ? aSub.unsubscribe() : null);
      }
    }, null , () => sub && sub.unsubscribe ? sub.unsubscribe() : null );
  }
  public isDirty(): boolean {
    return this.dirtyRowSet.size > 0;
  }

  public cellValueChanged(event): void {
    this.dirtyRowSet.add(event.node.data);
  }

  public onRowSelected(event) {
    if (!event.node.selected) return;  //Get the unslected even here too - ignore that
    this.selectedRow = event.data;
  }

  public modelUpdated(event): void {
    if (this.gridApi) {
      const selectedNodes = this.gridApi.getSelectedNodes();
      if (selectedNodes.length === 1) this.selectedRow = selectedNodes[0].displayed ? selectedNodes[0].data : undefined;
    }
  }

  public save(): void {
    this.gridApi.stopEditing();
    setTimeout((prams) => {
      this.performSave();
    });
  }

  private performSave(): void {
    this.subs.newSub = (this.codeValueService.saveAll(Array.from(this.dirtyRowSet)).subscribe((result) => {
      this.log.debug('saved ', result.length);
      const recordStr = result.length === 1 ? ' code record.' : ' code records.';
      this.alertService.success('Saved ' + result.length + recordStr);
      this.dirtyRowSet.clear();
    }));
  }

}
