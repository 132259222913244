import { Component } from '@angular/core';

import { AppSettings } from './app.settings';
import {cad, RouteHelperService, ThemeService} from '@comm-apps/common';
import Settings = cad.Settings;
import {SnackbarService} from '@comm-apps/alert';
import { UserPrefsService } from './services/user-prefs.service';
import { UserPrefs } from './core/classes/data-types';

@Component({
  selector: 'comm-apps-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Customer Access Request';
  public settings: Settings;

  constructor(
    public appSettings: AppSettings,
    private snackService: SnackbarService, //If you want a snackbar to show alertService messages, otherwise remove.
    private themeService: ThemeService,
    private userPrefService: UserPrefsService,
    private routeHelper: RouteHelperService, //inject so that it is watching the routes.
  ) {
    this.routeHelper.init();
    this.settings = this.appSettings.settings;
    this.userPrefService.userPrefs$.subscribe((prefs: UserPrefs) => {
      if (prefs && prefs.theme) {
        this.themeService.updateTheme(prefs.theme, appSettings.settings)
      }
    });
    
  }
}
