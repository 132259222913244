import {
    AfterViewInit,
    Component,
    ViewChild,
    ViewContainerRef,
  } from '@angular/core';
  import { ICellEditorAngularComp } from 'ag-grid-angular';
  import { ICellEditorParams } from 'ag-grid-community';
  
  // backspace starts the editor on Windows
  const KEY_BACKSPACE = 'Backspace';
  const KEY_DELETE = 'Delete';
  const KEY_F2 = 'F2';
  const KEY_ENTER = 'Enter';
  const KEY_TAB = 'Tab';
  const KEY_CONTROL = 'Control'
  const KEY_PERIOD = '.'
  
  @Component({
    selector: 'double-cell',
    templateUrl: './double-editor.component.html',
    styleUrls: ['./double-editor.component.scss']
  })
  export class DoubleEditorComponent implements ICellEditorAngularComp, AfterViewInit {
    private params: any;
    public value!: string;
    public highlightAllOnFocus = true;
    private cancelBeforeStart = false;

    public maxDecimals = 2;
  
    @ViewChild('input', { read: ViewContainerRef })
    public input!: ViewContainerRef;
  
    agInit(params: ICellEditorParams): void {
      this.params = params;
      this.setInitialState(this.params);
  
      // only start edit if key pressed is a number, not a letter
      const eventKey = (<any>params).eventKey;
      this.cancelBeforeStart = !!(
        eventKey &&
        eventKey.length === 1 &&
        '1234567890.'.indexOf(eventKey) < 0
      );
    }
  
    setInitialState(params: ICellEditorParams) {
      let startValue;
      let highlightAllOnFocus = true;
      const eventKey = (<any>params).eventKey;

      if (params.colDef.cellEditorParams.maxDecimals) {
        this.maxDecimals = params.colDef.cellEditorParams.maxDecimals;
      }
  
      if (eventKey === KEY_BACKSPACE) {
        // if backspace or delete pressed, we clear the cell
        startValue = '';
      } else if (eventKey && eventKey.length === 1) {
        // if a letter was pressed, we start with the letter
        startValue = eventKey;
        highlightAllOnFocus = false;
      } else {
        // otherwise we start with the current value
        startValue = params.value;
        if ((<any>params).eventKey === KEY_F2) {
          highlightAllOnFocus = false;
        }
      }
  
      this.value = startValue;
      this.highlightAllOnFocus = highlightAllOnFocus;
    }
  
    getValue(): number | null {
      const value = this.value;
      return value === '' || value == null ? null : parseFloat(value);
    }
  
    isCancelBeforeStart(): boolean {
      return this.cancelBeforeStart;
    }
  
    isCancelAfterEnd(): boolean {
      return !this.isValidNumber(this.getValue().toString());
      // return false;
      // const value = this.getValue();
      // return value != null && value > 1000000;
    }
  
    onKeyDown(event: any): void {
      if (this.isLeftOrRight(event) || this.isBackspace(event)) {
        event.stopPropagation();
        return;
      }
  
      if (!this.pastingKeyRelated(event) && !this.finishedEditingPressed(event) && !this.isNumericKey(event)) {
        if (event.preventDefault) event.preventDefault();
      } else if (this.areDecimalDigitsFull(event)) {
        if (event.preventDefault) event.preventDefault();
      }
    }

    private areDecimalDigitsFull(event: any): boolean {
      if (!this.value) return false;  //no value - good, type away

      const start = this.input.element.nativeElement.selectionStart
      const end = this.input.element.nativeElement.selectionEnd
      let test = this.value.toString().substring(0, start) + event.key + this.value.toString().substring(end, this.value.toString().length)

      return !this.isValidNumber(test);
    }

    private isValidNumber(test: string) {
      const decimalIndex = test.indexOf(".");

      if (decimalIndex < 0) return true;  //no decimals and you are not entering a decimal - good
      if (decimalIndex >= 0 && test.indexOf(".", decimalIndex + 1) > 0 ) return false;  //two decimals entered - bad

      if (test.length - decimalIndex > this.maxDecimals + 1) {
        return false;  //you have entered your maximum decimals - bad
      }
      return true;

    }
  
    // dont use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {
      window.setTimeout(() => {
        this.input.element.nativeElement.focus();
        if (this.highlightAllOnFocus) {
          this.input.element.nativeElement.select();
  
          this.highlightAllOnFocus = false;
        } else {
          // when we started editing, we want the caret at the end, not the start.
          // this comes into play in two scenarios:
          //   a) when user hits F2
          //   b) when user hits a printable character
          const length = this.input.element.nativeElement.value
            ? this.input.element.nativeElement.value.length
            : 0;
          if (length > 0) {
            this.input.element.nativeElement.setSelectionRange(length, length);
          }
        }
  
        this.input.element.nativeElement.focus();
      });
    }
  
    private isCharNumeric(charStr: string): boolean {
      return !!/[\.\d-]/.test(charStr);
    }
  
    private isNumericKey(event: any): boolean {
      const charStr = event.key;
      return this.isCharNumeric(charStr);
    }
  
    private isBackspace(event: any) {
      return event.key === KEY_BACKSPACE || event.key === KEY_DELETE;
    }
  
    private isLeftOrRight(event: any) {
      return ['ArrowLeft', 'ArrowRight'].indexOf(event.key) > -1;
    }
  
    private finishedEditingPressed(event: any) {
      const key = event.key;
      return key === KEY_ENTER || key === KEY_TAB;
    }

    private pastingKeyRelated(event: any) {
      const key = event.key;
      return key === KEY_CONTROL || event.ctrlKey;
    }
  }