import {
  Component,
  ElementRef,
  HostListener,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { isNil } from 'lodash-es';
import { LogService } from '@comm-apps/log';

interface FsDocument extends HTMLDocument {
  mozFullScreenElement?: Element;
  msFullscreenElement?: Element;
  msExitFullscreen?: () => void;
  mozCancelFullScreen?: () => void;
}

interface FsDocumentElement extends HTMLElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
}

@Component({
  selector: 'cad-lib-fullscreen',
  encapsulation: ViewEncapsulation.None,
  template: `
    <button mat-icon-button class="full-screen">
      <mat-icon *ngIf="!toggle" #expand>fullscreen</mat-icon>
      <mat-icon *ngIf="toggle" #compress>fullscreen_exit</mat-icon>
    </button>
  `
})
export class FullScreenComponent {
  toggle = false;
  @ViewChild('expand')
  expand: ElementRef;
  @ViewChild('compress')
  compress: ElementRef;

  constructor(private log: LogService ){}

  requestFullscreen(elem) {
    const fsDocElem = <FsDocumentElement>elem;
    if (fsDocElem.requestFullscreen) {
      fsDocElem.requestFullscreen();
    } else if (fsDocElem.mozRequestFullScreen) {
      fsDocElem.mozRequestFullScreen();
    } else if (fsDocElem.msRequestFullscreen) {
      fsDocElem.msRequestFullscreen();
    } else {
      this.log.warn('Fullscreen API is not supported.');
    }
  }

  exitFullscreen() {
    const fsDoc = <FsDocument>document;
    if (fsDoc.exitFullscreen) {
      fsDoc.exitFullscreen();
    } else if (fsDoc.mozCancelFullScreen) {
      fsDoc.mozCancelFullScreen();
    } else if (fsDoc.msExitFullscreen) {
      fsDoc.msExitFullscreen();
    } else {
      this.log.warn('Fullscreen API is not supported.');
    }
  }

  @HostListener('click')
  getFullscreen() {
    if (this.expand) {
      this.requestFullscreen(document.documentElement);
    }
    if (this.compress) {
      this.exitFullscreen();
    }
  }

  @HostListener('window:resize')
  onFullScreenChange() {
    const fsDoc = <FsDocument>document;
    const fsDocAny: any = fsDoc;
    const fullscreenElement =
      fsDocAny.fullscreenElement ||
      fsDoc.mozFullScreenElement ||
      fsDoc.msFullscreenElement;
    this.toggle = !isNil(fullscreenElement);
  }
}
