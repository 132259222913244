import { SpinnerService, AutoUnsubscriber, AutoUnsubscribables } from '@comm-apps/common';
import { Component, OnInit, } from '@angular/core';

@Component({
  selector: 'cad-lib-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  @AutoUnsubscriber() private subs: AutoUnsubscribables;
  public isSpinning: boolean = false;

  constructor(
    private spinnerService: SpinnerService,
  ) {
    this.subs.newSub = this.spinnerService.isSpinning$.subscribe(isSpinning => {
      //This is to help with ExpressionChangedAfterItHasBeenCheckedError since we can receive two messages back to back.
      //setTimeout will cause this to sometimes set the spinner out of order, but the promise maintains order better.
      Promise.resolve().then(() => {
        this.isSpinning = isSpinning;
      });
    });
  }

  ngOnInit() {
  }
}
