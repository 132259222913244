import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {LicenseManager} from 'ag-grid-enterprise';


LicenseManager.setLicenseKey('CompanyName=SHI International Corp._on_behalf_of_Energy Transfer LP,LicensedGroup=ITCA,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-032948,SupportServicesEnd=4_November_2023_[v2]_MTY5OTA1NjAwMDAwMA==f3d5c3a1c336c8b6364a94c749ff85e1');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
