import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {Ng2CacheModule} from 'ng2-cache';
@NgModule({
  imports: [
    CommonModule,
    Ng2CacheModule,
  ],
  providers: [],
  exports: [
    Ng2CacheModule,
  ]
})
export class HttpModule {}
