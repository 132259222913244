import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {cad} from "@comm-apps/common";
import { Subscription } from 'rxjs';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import TableState = cad.TableState;
import * as _ from 'lodash-es';

@Component({
  selector: 'cad-lib-table-layout',
  templateUrl: './table-layout.component.html',
  styleUrls: ['./table-layout.component.scss']
})
export class TableLayoutComponent implements OnInit {
  public saveMode = false;
  public tableStateNames: string[] = [];
  public name = 'My Layout';

  constructor(public dialogRef: MatDialogRef<TableLayoutComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) {
    this.saveMode = !!data.saveMode;
    if (data.tableStates) this.tableStateNames = data.tableStates.map(tableState => tableState.name);
    if (this.tableStateNames.length > 0) this.name = data.currTableState || '';
  }

  ngOnInit() {}

  ok() {
    if (this.saveMode && this.tableStateNames.includes(this.name)) {
      const dialogRef = this.dialog.open(ConfirmationComponent, {
        disableClose: false
      });
      dialogRef.componentInstance.message = `Are you sure you want to overwrite the data for the existing table layout ${this.name}?`;
      dialogRef.componentInstance.title = 'Overwrite Existing Table Layout?';
      let sub: Subscription;
      sub = dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close({ name: this.name });
        }
      }, null , () => sub && sub.unsubscribe ? sub.unsubscribe() : null );
    }
    else this.dialogRef.close({ name: this.name });
  }

  cancel() {
    this.dialogRef.close(null);
  }

  delete() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      disableClose: false
    });
    dialogRef.componentInstance.message = `Are you sure you want to delete the table layout ${this.name}?`;
    dialogRef.componentInstance.title = 'Delete Table Layout?';
    let sub: Subscription;
    sub = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close({ name: this.name, delete: true });
      }
    }, null , () => sub && sub.unsubscribe ? sub.unsubscribe() : null );
  }

}
