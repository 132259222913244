import { OverlayContainer } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { cad } from '../../common.module';

export class Theme {
  constructor(
    public description: string,
    public themeName: string,
    public tableTheme: string
  ) { }
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  public static themes: Map<string, Theme> = new Map<string, Theme>([
    ['Indigo',          new Theme('Indigo',           'indigo-light', 'ag-theme-alpine')],
    ['Teal',            new Theme('Teal',             'teal-light',   'ag-theme-alpine')],
    ['Red',             new Theme('Red',              'red-light',    'ag-theme-alpine')],
    ['Dark Blue',       new Theme('Dark Blue',        'blue-dark',    'ag-theme-alpine-dark')],
    // ['Dark Blue Wide',  new Theme('Dark Blue Wide',   'blue-dark',    'ag-theme-alpine-extraspace')],
    ['Dark Green',      new Theme('Dark Green',       'green-dark',   'ag-theme-alpine-dark-green')],
    ['Dark Pink',       new Theme('Dark Pink',        'pink-dark',    'ag-theme-alpine-dark-pink')],
  ]);

  public currentTheme$: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(ThemeService.themes[3]);

  constructor(
    private overlayContainer: OverlayContainer) { }

  /**
   * Attempts to update your theme.
   * 
   * @param themeName 
   * @param appSettings Your application settings - this will be mutated to have the new themeName in it.
   */
  public updateTheme(themeDescription: string, appSettings: cad.Settings): void {
    let theme: Theme = ThemeService.themes.get(themeDescription);

    if (theme) {
      appSettings.tableTheme = theme.tableTheme;

      this.overlayContainer.getContainerElement().classList.remove(appSettings.themeList[0]);
      this.overlayContainer.getContainerElement().classList.add(theme.themeName);

      appSettings.themeList = [theme.themeName, theme.tableTheme];

      this.currentTheme$.next(theme);
    }
  }


}
