<div class="phone-input">
	<mat-form-field class="mff">
		<mat-label>{{placeholder}}</mat-label>
        <div class="phone-input-container" fxLayout='row' fxLayoutAlign='center center'>
            <input matInput class="phone-input-element area"
                size="3"
                maxLength="3"
                addToNgForm
                name="area_{{placeholder}}"
                [(ngModel)]="area"
                [required]="required"
                aria-label="Area code"
                (ngModelChange)="areaUpdated()"
                (keydown)="verifyKeys($event)"
            />
            <span #span class="phone-input-spacer">&ndash;</span>
            <input matInput class="phone-input-element exchange"
                maxLength="3"
                size="3"
                addToNgForm
                name="exchange_{{placeholder}}"
                [(ngModel)]="exchange"
                [required]="required"
                aria-label="Exchange code"
                (ngModelChange)="exchangeUpdated()"
                (keydown)="verifyKeys($event)"
            />
            <span #span class="phone-input-spacer">&ndash;</span>
            <input matInput class="phone-input-element subscriber"
                maxLength="4"
                size="4"
                addToNgForm
                name="subscriber_{{placeholder}}"
                [(ngModel)]="subscriber"
                [required]="required"
                aria-label="Subscriber number"
                (ngModelChange)="subscriberUpdated()"
                (keydown)="verifyKeys($event)"
            />
            <span *ngIf="hasExtension" #span class="phone-input-spacer">Ext.</span>
            <input *ngIf="hasExtension" matInput class="phone-input-element extension"
                maxLength="5"
                size="5"
                addToNgForm
                name="extension_{{placeholder}}"
                [(ngModel)]="extension"
                aria-label="Extension"
                (ngModelChange)="extensionUpdated()"
                (keydown)="verifyKeys($event)"
            /> 
            <div class="phone-link"><a href="tel:{{_value}}"><mat-icon matSuffix>phone</mat-icon></a></div>
        </div>
	</mat-form-field>
</div>