import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {AuthorizationService} from '../../services/authorization.service';

@Directive({
  selector: '[commAppsHideIfUnauthorized]'
})
export class HideIfUnauthorizedDirective implements OnInit {

  @Input('commAppsHideIfUnauthorized') permission: any; // Required permission passed in

  constructor(private el: ElementRef, private authorizationService: AuthorizationService) {
  }

  ngOnInit() {
    if (!this.authorizationService.hasPermission(this.permission)) {
      this.el.nativeElement.style.display = 'none';
    }
  }

}
