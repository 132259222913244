<mat-toolbar color="primary"
    [class.mini-toolbar]="(settings.menuType == 'mini')"
    [class.opened-toolbar]="(settings.menuType != 'mini')">
  <cad-lib-title-bar [fxLayoutAlign]="(settings.menuType != 'mini') ? 'space-between center' : 'center center'"
                     class="sidenav-header"
                     [title]="settings.shortName" [sidenavIsOpened]="settings.sidenavIsOpened"
                     [(menuType)]="settings.menuType"></cad-lib-title-bar>
</mat-toolbar>

<div id="sidenav-menu-outer" class="sidenav-menu-outer">
  <span *ngIf="!menuItems">loading....</span>
  <cad-vertical-menu [menuItems]="menuItems" [menuParentId]="0" [settings]="settings" [userActions]="userRoles" [routerLinkActiveOptions]="{exact:false}"></cad-vertical-menu>
</div>
