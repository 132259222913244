import { CdkDragEnd } from '@angular/cdk/drag-drop';
import { Component, OnInit, Output, EventEmitter, ElementRef, AfterViewInit, Input, Renderer2 } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LogService } from '@comm-apps/log';
import * as _ from 'lodash-es';


@Component({
  selector: 'cad-lib-dialog-wrapper',
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss']
})
export class DialogWrapperComponent implements OnInit, AfterViewInit {

  @Output() closeClick = new EventEmitter<Event>();
  @Input() title: string = '';
  @Input() closeVisible = true;
  @Input() maximizeVisible = false;
  @Input() maximizedByDefault = false;
  @Input() scrollable = true;
  @Input() dialogRef: MatDialogRef<any>;

  private percentageHeight = 100;
  public maximized = false;
  public dragPosition = {x: 0, y: 0};
  private restoredDragPosition = {x: 0, y: 0};

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private log: LogService
  ) { }

  ngOnInit(): void {
      this.setDefaultHeight();
      if (!this.dialogRef && this.maximizeVisible) {
        this.log.warn('It is required to pass the dialogRef when enabling the maximize button on the dialog wrapper.');
        this.maximizeVisible = false;
      }
      if (!this.maximizeVisible) {
        this.maximizedByDefault = false;
      }
      if (this.maximizedByDefault) {
        this.maximize();
      }
  }

  ngAfterViewInit(): void {
    this.elRef.nativeElement.parentElement.parentElement.classList.add('cad-lib-dialog');
  }

  //Sometimes, the default defined height on the component is less than 100%. In order for the mat-dialog-content to scroll properly without hiding the buttons at
  //the bottom in the default state while also not having them too high in the maximized state, we need to change the height of the dialog-wrapper depending on
  //the state. We achieve this by storing the default height upon initialization in this function and applying that height or 100% in setHeight() depending on the state.
  setDefaultHeight() {
    const componentElement = this.elRef.nativeElement;
    const parentElement = componentElement.parentElement;

    if (parentElement) {
      const componentHeight = componentElement.offsetHeight;
      const parentHeight = parentElement.offsetHeight;

      if (parentHeight > 0) {
        this.percentageHeight = (componentHeight / parentHeight) * 100;
      }
    }
  }
  
  setHeight() {
    const componentElement = this.elRef.nativeElement;
    const parentElement = componentElement.parentElement;

    if (parentElement) {
      let desiredHeightPx = parentElement.clientHeight;
      if (!this.maximized) {
        desiredHeightPx *= (this.percentageHeight / 100);
      }
      this.renderer.setStyle(componentElement, 'height', `${desiredHeightPx}px`);
    }
  }

  cancel(event?: Event): void {
    this.closeClick.emit(event);
  }

  maximize(): void {
    this.dialogRef.updateSize('100vw', '100vh');
    this.dragPosition = {x: 0, y: 0};
    this.maximized = true;
    this.setHeight();
  }

  restore(): void {
    const dialogConfig = this.dialogRef._containerInstance._config;
    this.dialogRef.updateSize(dialogConfig.width, dialogConfig.height);
    this.dragPosition = this.restoredDragPosition;
    this.maximized = false;
    this.setHeight();
  }

  onDragEnded(event: CdkDragEnd) {
    this.restoredDragPosition.x += event.distance.x;
    this.restoredDragPosition.y += event.distance.y;
  }

}
