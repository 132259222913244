<div>
  <h1>We’re sorry! Something went wrong and CAR could not redirect you to the requested screen.</h1>
</div>
<br/>
<mat-divider></mat-divider>
<br/>
<div>
  <h2>Please help us troubleshoot by following the steps below:</h2>
  <BR/>
    <ol type="1">
      <h3>
      <li>If you see a menu to the left, please continue to use the application using the option of your choice.</li>
      <li>If you do NOT see menu options to the left, click on the User Menu (user icon on the upper right corner of the screen) and click on Log In. When prompted, enter your network username and password to log in. </li>
      <li>If neither option works, you may not have been authorized to use this application. 
      Access to this application is granted through MyID. 
      Search for "CAR" and select the appropriate Role (options are User, Administrator). </li>
    </h3>
    </ol>
  
  <BR/><BR/>
  <h2>
    For additional assistance, please contact the Service Desk at <a href="mailto:ServiceDesk@energytransfer.com?subject=CAR Access Request&body=I would like to request the following access:%0D%0A%0D%0A(Please let us know what access you need.)">ServiceDesk@energytransfer.com</a>
  </h2>
</div>
