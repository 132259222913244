<div id="app" class="app"
     [ngClass]="settings.themeList"
     [class.fixed-header]="settings.fixedHeader"
     [class.horizontal-menu]="settings.menu == 'horizontal'"
     [class.compact]="settings.menuType == 'compact'"
     [class.mini]="settings.menuType == 'mini'">
  <router-outlet></router-outlet>
  <div id="app-spinner" [class.hide]="!settings.loadingSpinner">
    <mat-spinner color="primary"></mat-spinner>
    <h4>loading...</h4>
  </div>
  <cad-lib-version-checker></cad-lib-version-checker>
</div>

