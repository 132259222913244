<div (click)="dismiss()">
  <div fxLayout="row">
    <div class="alert_message">
      <p>A newer version of the application is available.  Please save your work and <a onclick="window.location.reload()">refresh</a> the page.</p>
      <p><a onclick="window.location.reload()">Refresh Now</a></p>
    </div>
    <div><B>warning</B>
    </div>
  </div>
</div>
