import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '@comm-apps/alert';
import { AggridService, AutoUnsubscribables, AutoUnsubscriber } from '@comm-apps/common';
import { LogService } from '@comm-apps/log';
import { ConfirmationComponent } from 'libs/shared-ui/src/lib/dialogs/confirmation/confirmation.component';
import { CheckboxEditorComponent } from 'libs/shared-ui/src/lib/table/checkbox-editor/checkbox-editor.component';
import { Subscription } from 'rxjs';
import { ApplicationConfig, WebserviceError } from '../../../core/classes/data-types';
import { ApplicationConfigService } from '../../../services/application-config.service';
import { EditApplicationConfigDialogComponent } from './edit-application-config-dialog/edit-application-config-dialog.component';

@Component({
  selector: 'car-application-config',
  templateUrl: './application-config.component.html',
  styleUrls: ['./application-config.component.scss']
})
export class ApplicationConfigComponent implements OnInit, OnDestroy {

  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  public rowData: ApplicationConfig[];
  public selectedRow: ApplicationConfig;

  public dirtyRowSet: Set<ApplicationConfig> = new Set<ApplicationConfig>();
  private gridApi;
  private gridColumnApi;

  public gridOptions = {
    getRowId: (data) => data.data.id,
  }

  public columnDefs = [
    {headerName: 'Name', field: 'name'},
    {headerName: 'Value', field: 'value', type: ['editable']},
    {headerName: 'Type', field: 'type'},
  ];

  public defaultColDef = {
    flex: 1,
    filter: true,
    sortable: true,
    resizable: true,
  };

  public columnTypes = this.gridService.columnTypes;

   public frameworkComponents = this.gridService.frameworkComponents;


 constructor(
   private applicationConfigService: ApplicationConfigService,
   breakpointObserver: BreakpointObserver,
   public dialog: MatDialog,
   private alertService: AlertService,
   private gridService: AggridService,
   private log: LogService,
 ) {

 }

 ngOnInit() {
    this.fetchApplicationConfigs();
 }

 ngOnDestroy() {
   window.removeEventListener('resize', this.windowEventFunction);
 }

 onGridReady(params) {
   this.gridApi = params.api;
   this.gridColumnApi = params.columnApi;

   window.addEventListener('resize', this.windowEventFunction);

   this.gridService.bestFit(this.gridColumnApi, this.gridApi);
 }

 windowEventFunction = () => {
  setTimeout(() => {
    this.gridService.bestFit(this.gridColumnApi, this.gridApi);
  });
 }
 
 private fetchApplicationConfigs() {
    this.subs.newSub = this.applicationConfigService.findAll().subscribe(messages => {
      this.selectedRow = undefined;
      this.rowData = messages;
      this.dirtyRowSet.clear();
    }, (err: WebserviceError): void => {
      this.log.error(err);
      this.alertService.danger('Could not retrieve Application Configs.');
    });
 }

 public new() {
   const dialogRef = this.dialog.open(EditApplicationConfigDialogComponent, {
     height: '350px',
     width: '400px',
     data: { applicationConfig: {} }
   });
   let sub: Subscription;
   sub = dialogRef.afterClosed().subscribe((result : ApplicationConfig) => {
     if(result) {
      this.gridApi.applyTransaction({add:[result]});
     }
   }, null, () => sub && sub.unsubscribe ? sub.unsubscribe() : null);
 }

 public delete( item: ApplicationConfig) {
   const dialogRef = this.dialog.open(ConfirmationComponent, {
     disableClose: false
   });
   dialogRef.componentInstance.message = 'Are you sure you want to delete?';
   dialogRef.componentInstance.title = 'Delete?';
   let sub: Subscription;
   sub = dialogRef.afterClosed().subscribe(result => {
     if (result) {
       let aSub: Subscription;
       aSub = this.applicationConfigService.delete(item)
       .subscribe(message => {
        this.alertService.success('The application configuration was deleted successfully.');
         this.fetchApplicationConfigs();
       }, (error) => {
       }, () => aSub && aSub.unsubscribe ? aSub.unsubscribe() : null);
     }
   }, null , () => sub && sub.unsubscribe ? sub.unsubscribe() : null );
 }
 public isDirty(): boolean {
   return this.dirtyRowSet.size > 0;
 }

 public cellValueChanged(event): void {
   this.dirtyRowSet.add(event.node.data);
 }

 public onRowSelected(event) {
  if (!event.node.selected) return;  //Get the unslected even here too - ignore that
  this.selectedRow = event.data;
}

public modelUpdated(event): void {
  if (this.gridApi) {
    const selectedNodes = this.gridApi.getSelectedNodes();
    if (selectedNodes.length === 1) this.selectedRow = selectedNodes[0].displayed ? selectedNodes[0].data : undefined;
  }
}

 public save(): void {
   this.gridApi.stopEditing();
   setTimeout((prams) => {
     this.performSave();
   });
 }

 private performSave(): void {
   this.subs.newSub = (this.applicationConfigService.saveAll(Array.from(this.dirtyRowSet)).subscribe((result) => {
     this.log.debug('saved ', result.length);
     const recordStr = result.length === 1 ? ' application configuration.' : ' application configurationss.';
     this.alertService.success('Saved ' + result.length + recordStr);
     this.dirtyRowSet.clear();
   }));
 }

}
