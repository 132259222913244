import { Directive, Optional, OnInit, OnDestroy } from '@angular/core';
import {NgModel, NgForm} from '@angular/forms';

/**
 * This directive of part of the solution to forms with dynamic controls and controls nested
 * inside multiple levels of content projection not adding their child controls to itself for
 * validation and saving purposes.
 *
 * Problem:
 *
 *    When an HTML form element wraps one or more form controls, it automatically adds those
 *    controls to itself and aggregates their validation status into boolean valid property for
 *    iteself as well as other meta data such as dirty, pristine, submitted, etc...  It also
 *    makes the control values available with they name attribute as the key.
 *
 *    This only works when a form element DIRECTLY wraps one or more form components.  When then
 *    form is projected or dynamically added content, this will not work.  In our case we have
 *    both projected content and dyanmically added content.
 *
 * Working Example:
 *
 *    <form #theForm="ngForm">
 *      <input name="test" [(ngModel)]="test" />
 *    </form>
 *
 *    In this example the NgForm directive will have one form control registered to itself named
 *    'test'.  If the test control is invalid, the form will be invalid, if test is dirty, the form
 *    is dirty.  If test.value === 'form test', then theForm.value === { test: (...some control here with a value of 'form test') }
 *
 * Failing example:
 *
 *    @Component({
 *      selector: 'test-form',
 *      templateUrl: `
 *        <form #theForm="ngForm">
 *          <ng-content></ng-content>
 *        </form>
 *    })
 *    export class TestFormComponent {}
 *
 *    later in some template...
 *
 *    <test-form>
 *      <input name="test" [(ngModel)]="test" />
 *    </test-form>
 *
 *    In the above example the NgForm directive will have 0 form controls registered with it, the validity will
 *    always be valid, it will always be pristine and the value will always be {} because it has no form controls
 *
 *    One way around the above example would be to use ContentChildren(NgModel) to get a QueryList of NgModels that
 *    can be added to the form.  Unfortunately for our use case this does not work because our code looks more like this:
 *
 * Our Example:
 *
 *    @Component({
 *      selector: 'test-form',
 *      templateUrl: `
 *        <form #theForm="ngForm">
 *          <ng-content></ng-content>
 *        </form>
 *    })
 *    export class TestFormComponent {}
 *
 *    later in some template...
 *
 *    <test-form>
 *      <ng-container
 *      ....
 *      </ng-container>
 *    </test-form>
 *
 *    When dynamic component outlets like this are used, their contents are not added to the injector, meaning it is
 *    impossible to get a reference to the form controls inside the form element.
 *
 * Example Usage in Partial:
 *
 *    Inside some form partial...
 *
 *    <mat-form-field>
 *      <input matInput addToNgForm [(ngModel)]="test" name="test" />
 *    </mat-form-field>
 *
 * Adding [addToNgForm] to the above input element allows it to register itself with it's parent form.
 */
@Directive({
  selector: '[addToNgForm]',
})
export class AddToNgFormDirective implements OnInit, OnDestroy {

  constructor(
    @Optional() private ngForm: NgForm,
    @Optional() private model: NgModel,
  ) {}

  public ngOnInit(): void {
    if (this.model && this.model.name) {
      if (this.ngForm) {
        this.ngForm.addControl(this.model);
      }
    } else {
      console.error('No name on addToNgForm control', this);
    }
  }

  public ngOnDestroy(): void {
    if (this.model && this.model.name && this.ngForm) {
      this.ngForm.removeControl(this.model);
    }
  }
}
