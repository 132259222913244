import { OverlayContainer } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DEFAULT_TIMEOUT, HttpModule } from '@comm-apps/http';
import { LOGIN_SERVICE, SharedUiModule, ToolsModule } from '@comm-apps/shared-ui';
import { AgGridModule } from 'ag-grid-angular';
import { PipesModule } from '../../../../libs/shared-ui/src/lib/theme/pipes/pipes.module';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { AppSettings } from './app.settings';
import { SidenavComponent } from './core/components/sidenav/sidenav.component';
import { ToolbarComponent } from './core/components/toolbar/toolbar.component';
import { CoreModule } from './core/core.module';
import { localHttpInterceptorProviders } from './core/http-interceptors';
import { ViewComponent } from './core/view/view.component';
import { AdminModule } from './pages/admin/admin.module';
import { NotAuthorizedComponent } from './pages/restricted/not-authorized/not-authorized.component';
import { UserProfileComponent } from './pages/restricted/user-profile/user-profile.component';
import { CARUserService } from './services/user.service';
import {PdfJsViewerModule} from 'ng2-pdfjs-viewer';
import { HelpComponent } from './pages/restricted/help/help.component';
import { SimpleViewComponent } from './core/simple-view/simple-view.component';
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, RecaptchaSettings } from "ng-recaptcha";

@NgModule({
  declarations: [
    AppComponent,
    ViewComponent,
    SimpleViewComponent,
    SidenavComponent,
    UserProfileComponent,
    ToolbarComponent,
    NotAuthorizedComponent,
    HelpComponent,
  ],
  imports: [
    AgGridModule,
    AdminModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    routing,
    CoreModule,
    PipesModule,
    PdfJsViewerModule,
    SharedUiModule,
    ToolsModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    ReactiveFormsModule,
    HttpModule,
  ],
  providers: [
    AppSettings,
    localHttpInterceptorProviders,
    {
      provide: 'baseURL',
      useValue: environment.baseUrl
    },
    {
      provide: 'environment',
      useValue: environment
    },
    {
      provide: DEFAULT_TIMEOUT,
      useValue: 60000
    },
    {
      provide: LOGIN_SERVICE,
      useClass: CARUserService
    },
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY, 
    //   useValue: "6LczHmgpAAAAAPZf-Lz5NV5M3vTRd6egxgWqzv9M"
    // },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: "6LczHmgpAAAAAPZf-Lz5NV5M3vTRd6egxgWqzv9M"
      } as RecaptchaSettings
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  customIcons: Array<[string, string]> = [['cad-logo', 'assets/img/app/white-logo.svg']];
  constructor(overlayContainer: OverlayContainer, settings: AppSettings, iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    overlayContainer.getContainerElement().classList.add('app');
    overlayContainer
      .getContainerElement()
      .classList.add(settings.settings.themeList[0]);

    this.customIcons.forEach(([iconName, iconPath]) => {
      iconRegistry.addSvgIcon(
        iconName,
        sanitizer.bypassSecurityTrustResourceUrl(iconPath)
      );
    });
  }
}
