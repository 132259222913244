
import { Component, OnInit, Inject } from '@angular/core';
import { AutoUnsubscriber, AutoUnsubscribables } from '@comm-apps/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '@comm-apps/alert';
import { Subscription } from 'rxjs';
import { AllUser, CodeData } from '../../../../core/classes/data-types';
import { CodeDataService } from '../../../../services/code-data.service';
import { CARUserService } from '../../../../services/user.service';
import { SearchUserDialogComponent } from '../../../../core/components/search-user-dialog/search-user-dialog.component';

@Component({
  selector: 'car-edit-code-value-dialog',
  templateUrl: './edit-code-value-dialog.component.html',
  styleUrls: ['./edit-code-value-dialog.component.scss']
})
export class EditCodeValueDialogComponent implements OnInit {

  public codeValue: CodeData;
  public userType = false;
  public disableCodeType: boolean = false;
  public codeValueTypes: string[] = [];
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  constructor(
    private codeValueService: CodeDataService,
    public dialogRef: MatDialogRef<CodeData>,
    private alertService: AlertService,
    private userService: CARUserService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.codeValue = JSON.parse(JSON.stringify(data.codeValue));
    this.codeValueTypes = JSON.parse(JSON.stringify(data.codeValueTypes));

    if (data.codeType) {
      this.codeValue.type = data.codeType;
      this.disableCodeType = true;
    }
    this.userType = data.userType;

   }

  ngOnInit() {
  }

  compare(a: number | string | Date , b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  save() {
    let sub: Subscription;
    sub = this.codeValueService.save(this.codeValue).subscribe(codeValue => {
      this.dialogRef.close(codeValue);
    },  (error) => {
      this.alertService.danger('There was an error saving the code value', error);
    }, () => sub && sub.unsubscribe ? sub.unsubscribe() : null);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  searchUser() {
    const dialogRef = this.dialog.open(SearchUserDialogComponent, {
      height: '220px',
      width: '400px',
      data: { codeType: this.codeValue.type }
    });
    let sub: Subscription;
    sub = dialogRef.afterClosed().subscribe((result : AllUser) => {
      if(result) {
        this.codeValue.code = result.userId;
        this.codeValue.description = result.fullName;
      }
    }, null, () => sub && sub.unsubscribe ? sub.unsubscribe() : null);
  }

}
