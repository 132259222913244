import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AutoUnsubscribables, AutoUnsubscriber, RouteHelperService} from '@comm-apps/common';
import { Roles } from '../../../models/roles.enum';
import {CARUserService} from '../../../services/user.service';

@Component({
  selector: 'car-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit {
  constructor(private userSvc: CARUserService, private router: Router, private routeHelper: RouteHelperService) {}

  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  ngOnInit() {
    let reRouted: number = 0;

    this.subs.newSub =  this.userSvc.currentUser$.subscribe(user => {
      if (user && user.userName && reRouted < 3) {   
        //Make sure we are flagging this so it does not happen again.  Sometimes this will call over and over 
        //(since we are in a subject) before this component is destroyed and we can get in an infinite loop
        reRouted++;

        if (this.userSvc.isUserAnyRole([Roles.USER])) {
          let prevRoute:string = this.routeHelper.getPreviousUrl();
          let item = 2;
          while ((prevRoute == '/not-authorized' || prevRoute == '/wait') && item < this.routeHelper.getHistory().length) {
            prevRoute = this.routeHelper.getHistory()[item];
            item++;
          }
          this.routeHelper.goToRoute(prevRoute);
        }
      }
    });
  }
}
