import {Injectable} from '@angular/core';
import {HttpHelperService} from '@comm-apps/http';
import {Observable} from 'rxjs';
import { ApplicationConfig } from '../core/classes/data-types';

@Injectable({
  providedIn: 'root'
})
export class ApplicationConfigService {

  private endpoint: string = 'api/application-config';

  constructor(private apiHelper: HttpHelperService) {
  }

  find(applicationConfig: ApplicationConfig): Observable<ApplicationConfig[]> {
    return this.apiHelper.request(`${this.endpoint}/${applicationConfig.id}`);
  }

  findAll(): Observable<ApplicationConfig[]> {
    return this.apiHelper.request(`${this.endpoint}/all`)
  }

  save(applicationConfig: ApplicationConfig): Observable<ApplicationConfig[]> {
    return this.apiHelper.request(`${this.endpoint}`, {body: applicationConfig, method: 'POST'})
  }

  public saveAll(applicationConfigs: ApplicationConfig[]): Observable<ApplicationConfig[]> {
    return this.apiHelper.request(`${this.endpoint}/save-all`, {body: applicationConfigs, method: 'POST'});
  }

  public delete(applicationConfig: ApplicationConfig): Observable<ApplicationConfig> {
    return this.apiHelper.request(`${this.endpoint}/${applicationConfig.id}`, { method: 'DELETE'});
  }
}
