<div fxLayout="row">
  <mat-form-field fxFlex>
    <mat-label>{{placeholder}}</mat-label>
    <input type="text"
           #inputField
           matInput
           addToNgForm
           name="autoCom_{{placeholder}}{{uuid}}"
           [required]="required"
           [disabled]="disabled"
           [(ngModel)]="searchText"
           (ngModelChange)="filterData()"
           (keydown.enter)="$event.preventDefault()"
           (keydown.arrowDown)="changing=true"
           [matAutocomplete]="autocompleteRef"
           (blur)="blur($event)"
           (focus)="onFocus($event)"
           />
    <button type="button" [tabIndex]="-1" mat-icon-button *ngIf="searchText && searchText !== '' && !disabled" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
      <mat-icon>clear</mat-icon>
    </button>
    <mat-autocomplete #autocompleteRef="matAutocomplete" panelWidth="{{panelWidth}}" (optionSelected)="selectData($event)"
                      [displayWith]="displayWith" [autoActiveFirstOption]="true">
      <mat-option *ngFor="let theData of filteredData" [value]="theData">{{ displayProperty ? theData[displayProperty] : theData}}</mat-option>
    </mat-autocomplete>

  </mat-form-field>
</div>
