import { Injectable } from '@angular/core';
import { AlertService } from './alert.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AutoUnsubscribables, AutoUnsubscriber, cad } from '@comm-apps/common';
import Alert = cad.Alert;

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  constructor(
    private alertService: AlertService,
    private snackBar: MatSnackBar
  ) {
    this.subs.newSub = this.alertService.alerts$.subscribe((alert: Alert) => {
      this.snackBar.open(alert.msg, alert.type, {
        duration: alert.dismissOnTimeout,
        horizontalPosition: alert.horizontalPosition,
        verticalPosition: alert.verticalPosition
      });
    });
  }
}
