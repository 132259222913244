export class UserActivitySummary {
  public id: number;
  public userId: string;
  public activityStartDt: Date;
  public activityEndDt: Date;
  public durationMins: number;
}
export class UserActivity {
  public id: number;
  public userId: string;
  public activityDt: Date;
  public action: string;
}

export class UserActivitySearch {
  public id: number;
  public userId: string;
  public from: Date;
  public to: Date;
}
