<h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragFreeDragPosition]="dragPosition" [cdkDragDisabled]="maximized" (cdkDragEnded)="onDragEnded($event)">
    <mat-toolbar role="toolbar" class="task-header" color="primary">
      <span fxFlex>{{title}}</span>
      <span class="fx-spacer"></span>
      <div fxLayout="row">
        <div *ngIf="maximizeVisible">
          <button mat-icon-button (click)="maximize()" *ngIf="!maximized">
            <mat-icon mat-list-icon>check_box_outline_blank</mat-icon>
          </button>
          <button mat-icon-button (click)="restore()" *ngIf="maximized">
            <mat-icon mat-list-icon class="restore">filter_none</mat-icon>
          </button>
        </div>
        <button mat-icon-button (click)="cancel($event)" *ngIf="closeVisible">
            <mat-icon mat-list-icon>close</mat-icon>
        </button>
      </div>
      
    </mat-toolbar>
  </h1>
  <div fxLayout="column" class="content" [ngClass]="scrollable ? 'scrollable' : ''">
    <ng-content></ng-content>
  </div>