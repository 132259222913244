import { Component, Input, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot, ActivationEnd,
  NavigationEnd,
  Router
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as _ from 'lodash-es';

@Component({
  selector: 'cad-lib-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
  //Using a static for the breadcrumbHolder because we have multiple module route configurations that cause this component to destroy and re-init after the
  // NavigationEnd has occurred and we get the breadcrumbs off the route - at this time the component is destroyed and we loose the values here.  static fixes.
  private static breadcrumbHolder: any;

  @Input() name: string;

  public pageTitle: string;
  public breadcrumbs: {
    name: string;
    url: string;
  }[] = [];

  constructor(
    public router: Router,
    public title: Title,
    public route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    if (BreadcrumbComponent.breadcrumbHolder) {
      this.breadcrumbs = BreadcrumbComponent.breadcrumbHolder;
    } else {
      this.breadcrumbs = [];
    }

    this.init(this.route.snapshot);

    this.router.events.subscribe(event => {
      //Pulling from activationEnd seems to be the best snapshot, not activated route.
      if (event instanceof ActivationEnd) {
        this.init(event.snapshot);
      }
    });
  }

  public closeSubMenus() {
    const menu = document.querySelector('.sidenav-menu-outer');
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        const child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

  private init(snapshot: any): void {
    this.breadcrumbs = [];
    this.parseRoute(snapshot);
    this.setTitle();
  }

  private setTitle(): void {
    this.pageTitle = '';
    this.breadcrumbs.forEach(breadcrumb => {
      this.pageTitle += ' > ' + breadcrumb.name;
    });
    this.title.setTitle(this.name + this.pageTitle);
  }

  private parseRoute(node: ActivatedRouteSnapshot) {
    if (node.data['breadcrumb']) {
      if (node.url.length) {
        this.build(node);
      }
    }
    if (node.firstChild) {
      this.parseRoute(node.firstChild);
    }
  }

  private build(node: ActivatedRouteSnapshot): void {
    this.generateBreadcrumbs(node.data['breadcrumb'], this.router.url);
  }

  private generateBreadcrumbs(crumb: string, url: string): void {
    this.breadcrumbs.push({
      name: crumb,
      url: '/' + url
    });
    BreadcrumbComponent.breadcrumbHolder = _.clone(this.breadcrumbs);
  }
}
