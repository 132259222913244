import { AlertService } from '@comm-apps/alert';
import {Component, Inject, OnInit, Optional} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {AutoUnsubscribables, AutoUnsubscriber} from '@comm-apps/common';
import {CadLoginCallbackService, LOGIN_SERVICE, LoginResult} from './cad-login-service-callback.interface';
import {DefaultUserService} from './default-user.service';

@Component({
  selector: 'cad-lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,
    private defaultLoginService: DefaultUserService,
    private alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject(LOGIN_SERVICE) @Optional() protected loginService: CadLoginCallbackService
  ) {
    if (this.loginService == null) {
      this.loginService = this.defaultLoginService;
    }
  }

  public userId: string;
  public password: string;

  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  ngOnInit() {
    //If login is available, then you are not logged in properly.  Allow us to clear this out in case
    // we just cancel.  This will help eliminate inability to SSO when a wrong id/pass combo is entered
    localStorage.removeItem('Auth');

    this.subs.newSub = this.dialogRef.backdropClick().subscribe(event => {
      this.closeDialog('Cancel');
    });

    this.subs.newSub = this.dialogRef.keydownEvents().subscribe(event => {
      if (event.code === 'Escape') {
        this.closeDialog('Cancel');
      }
    })
  }

  public login(): void {
    this.data = {};

    localStorage.setItem('Auth', 'Basic ' + btoa(this.userId + ':' + this.password));
    this.loginService.loginUser().subscribe(result => {
      if (result.success) {
        this.closeDialog(result.userName);
      } else {
        if (result.errorMessage) {
          this.data.message = result.errorMessage;
        }
      }
    });
  }

  closeDialog(rv: string): void {
    this.dialogRef.close(rv);
  }
}
