import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, Input, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[numeric]',
  providers: [
    DecimalPipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumericDirective),
      multi: true
    }
  ]
})
export class NumericDirective implements ControlValueAccessor {
  @Input() commas: boolean = true;
  @Input() minDecimals: number = 0;
  @Input() maxDecimals: number = 0;

  private onChange: (value: any) => void;
  private onTouched: () => void;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private decimalPipe: DecimalPipe
  ) {}

  writeValue(value: any): void {
    this.renderer.setProperty(this.el.nativeElement, 'value', this.getFormattedValue(value));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void{
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  };

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.onChange(this.clearMarkup(value));
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string): void {
    this.formatAsNumeric(value);
    this.onTouched();
  }

  private formatAsNumeric(value: any): void {
    const numericValue = parseFloat(this.clearMarkup(value));
    if (!isNaN(numericValue)) {
      const formattedValue = this.getFormattedValue(numericValue.toString());
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
      this.onChange(numericValue);
    }
  }

  private getFormattedValue(val: string | null): string | null {
    if (val === null || val === undefined) {
      return null;
    }
    let formattedValue = this.decimalPipe.transform(this.clearMarkup(val), `1.${this.minDecimals}-${this.maxDecimals}`);
    return formattedValue;
  }

  private clearMarkup(input: any): string {
    return typeof input === 'string' ? (<any>input).replaceAll(',', '') : input;
  }
}
