<mat-toolbar color="primary" class="flex-p-x">
  <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
    <div fxLayout="row" fxLayoutAlign="center center">
      <button *ngIf="settings.menu == 'vertical'" mat-icon-button (click)="toggleSidenav()">
        <mat-icon>menu</mat-icon>
      </button>
      <cad-lib-title-bar *ngIf="settings.menu == 'none'"
                         [fxLayoutAlign]="(settings.menuType != 'mini') ? 'space-between center' : 'center center'"
                         class="sidenav-header"
                         [title]="settings.shortName" [sidenavIsOpened]="settings.sidenavIsOpened"
                         [(menuType)]="settings.menu"></cad-lib-title-bar>
    </div>
    <div fxFlex="fill" fxFill fxLayout="row" fxLayoutAlign="end center">
      <div fxFlex="fill" fxLayoutAlign="start center" fxLayout="row">
        <div *ngIf="!smallScreen" fxFlex class="settings-name">{{settings.name}} <mat-divider [vertical]="true"></mat-divider></div>
        <div *ngIf="smallScreen" fxFlex class="settings-name">{{settings.shortName}} <mat-divider [vertical]="true"></mat-divider></div>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" fxShow="false" fxShow.gt-xs class="search-bar">
        <span class="env-name">{{ env.name === 'PRODUCTION' ? '' : env.name }}</span>
      </div>
      <div fxFlex fxLayoutAlign="end center">
        <cad-lib-fullscreen></cad-lib-fullscreen>
        <cad-lib-notification fxShow="false" fxShow.gt-xs></cad-lib-notification>
        <cad-lib-user-menu
          [userName]="userName"
          [runAsUserOption]=canRunAs
          profilePath="/restr-user/user-profile"
          helpPath=""
          (helpClicked)="helpClicked($event)"
          (loginComplete)="loginComplete($event)"
          (logoutComplete)="logoutComplete($event)"
          (runAsComplete)="runAsComplete($event)"
          (stopRunAsComplete)="stopRunAsComplete(currentUser())">
        </cad-lib-user-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
