import { Component, OnDestroy } from '@angular/core';
import { ICellEditorAngularComp, ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'checkbox-editor',
  styleUrls: ['./checkbox-editor.component.scss'],
  templateUrl: './checkbox-editor.component.html',
})
export class CheckboxEditorComponent implements ICellRendererAngularComp, ICellEditorAngularComp {
  public params: any;
  private checked: boolean;

  agInit(params: any): void {
    this.params = params;
  }

  checkedHandler(event) {
    if (this.params.colDef.editable) {
      this.checked = event.checked;
      let colId = this.params.column.colId;
      this.params.node.setDataValue(colId, this.checked);
      // this.params.value = this.checked;
    }
  }

  getValue() {
    return this.params.value;
  }

  refresh(params: any): boolean {
      return false;
  }
}
