import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CSVBuilderService {

  constructor() { }

  public downloadFileUnknownHeader(fileName: string, data: any[]):void {
    this.downloadFile(fileName, Object.keys(data[0]), data);
  }

  // public downloadFile(fileName: string, header: string[], data: any[]):void {
  //   const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
  //   let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
  //   csv.unshift(header.join(','));
  //   let csvArray = csv.join('\r\n');
  //
  //   let a = document.createElement('a');
  //   let blob = new Blob([csvArray], {type: 'text/csv' });
  //   let url = window.URL.createObjectURL(blob);
  //
  //   a.href = url;
  //   a.target = "_blank";
  //   a.download = fileName + ".csv";
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  //   a.remove();
  // }

  public downloadFile(fileName: string, header: string[], data: any[]):void {
    this.downloadFileNice(fileName, header, header, data);
  }
  public downloadFileNice(fileName: string, displayHeader: string[], header: string[], data: any[]):void {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    //hacky way to allow you to put fields with field.subField (index of .).  Currently this only allows 1 deep and no undefined check.
    let csv = data.map(row => header.map(fieldName => 
      (fieldName.indexOf(".") > 0
        ? JSON.stringify(row[fieldName.split(".")[0]][fieldName.split(".")[1]], replacer)
        : JSON.stringify(row[fieldName], replacer))
      ).join(','));
    csv.unshift(displayHeader.join(','));
    let csvArray = csv.join('\r\n');

    let a = document.createElement('a');
    let blob = new Blob([csvArray], {type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);

    a.setAttribute('href', url);
    a.setAttribute('download', fileName + ".csv");
    a.setAttribute('target', '_blank');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
