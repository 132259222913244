import { Injectable } from '@angular/core';
import { cad } from '@comm-apps/common';
import Settings = cad.Settings;

@Injectable()
export class AppSettings {
  public settings = new Settings(
    'Customer Access Request', //name
    'CAR', //short Name
    true, //loadingSpinner
    true, //fixedHeader
    true, //sidenavIsOpened
    false, //sidenavUserBlock
    'vertical', //none , vertical
    'default', //default, compact, mini
    ['indigo-light', 'ag-theme-alpine'],
    'ag-theme-alpine',
    '../assets/img/app/white-logo.svg'
  );

}
