<cad-lib-dialog-wrapper title="Search User" (closeClick)="cancel()" fxLayout="column">
  <form #form="ngForm">
    <div mat-dialog-content fxFlex fxLayout="column" >
      <cad-lib-auto-complete
        placeholder="User"
        [required]="true"
        [data]="allUsers"
        displayProperty="fullName"
        [sort]="false"
        [(selectedData)]="user">
      </cad-lib-auto-complete>
    </div>
</form>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="left">
  <div fxFlex fxLayoutAlign="end">
    <button type="submit" mat-raised-button color="primary" (click)="ok()" [disabled]="!form.form.valid" >OK</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>
</div>
</cad-lib-dialog-wrapper>

