import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

@Directive({
  selector: 'button[click]'
})
export class PreventDoubleClickDirective {
  private disableTime = 1000;
  
  @Input() preventDoubleClick = true; //set to false in non-backend-interfacing buttons (like cycling through initiatives on EPM)

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('click', ['$event'])
  clickEvent(event: Event) {
    if (!this.preventDoubleClick) return;
    //setTimeout ensures any manual disabled setting that occurs on click is processed before this logic is processed
    setTimeout(() => {
      if (!this.el.nativeElement.disabled) {
        this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'true');
  
        timer(this.disableTime).pipe(take(1)).subscribe(() => {
          this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
        });
      }
    });
  }
}



