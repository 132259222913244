import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { emailListValidator } from '../validators/email-list.validator';

@Directive({
  selector: '[emailList]',
  providers: [{provide: NG_VALIDATORS, useExisting: EmailListDirective, multi: true}]
})
export class EmailListDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors {
    return emailListValidator()(control);
  }

}
