import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  template: `
    <div class="custom-button">
      <span class="status-text">{{ text }}</span>
      <button mat-button (click)="onClick($event)" class="icon-button">
        <mat-icon>{{ icon }}</mat-icon>
      </button>
    </div>
  `,
  styles: [`
    .custom-button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
    }
    .status-text {
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .icon-button {
      margin-left: auto;
      padding: 0;
      box-sizing: border-box;
      min-width: 0;
    }
  `],
})
export class ButtonStringRendererComponent implements ICellRendererAngularComp {
  params: any;
  icon: string;
  text: string;

  agInit(params: any): void {
    this.params = params;
    this.icon = params.icon;
    this.text = params.text;
  }

  onClick(event: Event) {
    event.stopPropagation();
    if (this.params.onClick instanceof Function) {
      this.params.onClick(this.params.data);
    }
  }

  refresh(): boolean {
    return true;
  }
}
