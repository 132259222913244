import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'cad-lib-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  public message: string = 'Are you sure?';
  public title: string = 'Are you sure?';
  public ok: string = 'Yes';
  public cancel: string = 'No';

  constructor(public dialogRef: MatDialogRef<ConfirmationComponent>) {}

  ngOnInit() {}
}
