import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'cad-lib-email-renderer',
  templateUrl: './email-renderer.component.html',
  styleUrls: ['./email-renderer.component.scss']
})
export class EmailRendererComponent implements OnInit {

  @Input()
  public emailAddr:string;

  @Input()
  public description: string;

  constructor() {

  }

  ngOnInit() {
    this.description = (this.description ? this.description : this.emailAddr);
  }

}
