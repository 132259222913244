import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatFormField } from '@angular/material/form-field';


@Component({
  selector: 'cad-lib-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss']
})
export class PhoneComponent implements OnInit {

  public area: string;
  public exchange: string;
  public subscriber: string;
  public extension: string;

  @Input()
  public hasExtension: boolean = false;

  @Input()
  public required: boolean = false;

  @Input()
  public placeholder: string;

  public _value: string;
  private validKeys = ['0','1','2','3','4','5','6','7','8','9', 'Backspace', 'Delete', 'Tab', 'ArrowRight', 'ArrowLeft'];
  private numbers = ['0','1','2','3','4','5','6','7','8','9'];

  @ViewChildren('span') 
  private spans: QueryList<ElementRef>;

  @ViewChild(MatFormField)
  private fromField: MatFormField;

  @Output()
  public valueChange = new EventEmitter<string>();

  @Input()
  public set value(data: string) {
    this._value = data;
    if (data) {
      this.area = data.substr(0, 3)
      this.exchange = data.substr(3, 3);
      this.subscriber = data.substr(6, 4);
      if (data.substr(10, 2) == ',,') {
        this.extension =data.substr(12, 5);
      } else {
        this.extension = data.substr(10, 5);
      }
      if (this.extension) {  //do we really want to infer this?
        this.hasExtension = true;
      }
    }

  }

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.showHideDash();
    });
  }

  public areaUpdated(): void {
    this.buildNumber();
  }

  public exchangeUpdated(): void {
    this.buildNumber();
  }

  public subscriberUpdated(): void {
    this.buildNumber();
  }

  public extensionUpdated(): void {
    this.buildNumber();
  }

  public verifyKeys(event): boolean {
    let valid: boolean = this.validKeys.includes(event.key);
    let elem = event.srcElement;

    if (valid) {
      if (this._value && this.numbers.includes(event.key)) {
        if (elem.value && elem.maxLength == elem.value.length) {
          this.focusNext(elem);
        }
      }
      if (event.key == 'Backspace') {
        if (!elem.value) {
          this.focusPrev(elem);
        }
      }
    }
    
    return valid;
  }

  private focusNext(element) {
    if (element.nextElementSibling && element.nextElementSibling.nextElementSibling) {
      element.nextElementSibling.nextElementSibling.focus();
    }
  }

  private focusPrev(element) {
    if (element.previousElementSibling && element.previousElementSibling.previousElementSibling) {
      element.previousElementSibling.previousElementSibling.focus();
    }
  }

  private buildNumber(): void {
    
    this._value = (this.area ? this.area : '') + 
      (this.exchange ? this.exchange : '') + 
      (this.subscriber ? this.subscriber : '') +
      (this.extension ? ',,' + this.extension : '');

    this.valueChange.emit(this._value);
    this.showHideDash();
  }

  private showHideDash() {
    this.spans.forEach((item: ElementRef) => {
      item.nativeElement.className = (this._value ? 'phone-input-spacer-visible' : 'phone-input-spacer');
    });
  }

}
