import { Injectable } from '@angular/core';
import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { finalize, tap } from 'rxjs/operators';
import { SpinnerService } from '@comm-apps/common';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
  constructor(private spinnerService: SpinnerService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    this.spinnerService.addRequest();
    return next.handle(req).pipe(
      tap(
        null,
        null
      ),
      finalize(() => {
        this.spinnerService.completeRequest();
      })
    );
  }
}
