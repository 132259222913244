<div fxFlex fxLayout="column" fxLayoutGap="1.5em">
  <h1>CAR Help</h1>

  <mat-card appearance="outlined">
    <mat-card-header fxLayout="row">
      <mat-card-title>Links</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ul>
        <li>Item 1
        </li>
        <li>Item 2
        </li>
        <li>Item 3
        </li>
      </ul>
        
    </mat-card-content>
  </mat-card>

</div>
