import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import * as _ from 'lodash-es';

@Component({
  selector: 'cad-lib-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit {

  @Input()
  public placeholder: string;

  public _data: any[] = [];
  public _selectedData: any;

  //This input indicates that I can autoselect the first item if there is only one item in the list (must also be a required field)
  //NOTE: Currently, a race condition occurs where if this is listed after [data] on an instance, it won't have any effect. To ensure it works properly, make sure it comes before [data].
  @Input()
  public selectIfOneItem: boolean = true;

  //property to display from the list of objects.  If left undefined this would most likely mean your source list is just strings.
  @Input()
  public displayProperty: string;

  //property that determines whether to sort the list of objects. If a displayProperty is set, the data will be sorted by that.
  @Input()
  public sort = true;

  //function to determine whether an item going to be displayed in the autocomplete list. If true is shows up, if false, it does not.
  @Input() selectableFunc: (obj: any) => boolean;

  //Use this input if your list of object is just supposed to return a primitive property, if undefined we will set the object from the list in the selectedData
  @Input()
  public resultProperty: string;

  @Input()
  public restrictToList: boolean = true;

  //hard code the width of the autocomplete field
  @Input()
  public panelWidth: string = "";

  //Will auto size the autocomplete field to the internal data if true
  @Input()
  public autoWidth: boolean = true;

  //This input indicates that I have to start typing before I show what is in the list.
  @Input()
  public mustStartTyping: boolean = false;

  @Output()
  public selectedDataChange = new EventEmitter<any>();

  @Output()
  public valueChanged = new EventEmitter<any>();

  @Output()
  public focus = new EventEmitter<any>();

  @Input()
  public required: boolean = false;

  @Input()
  public disabled: boolean = false;

  @ViewChild('inputField', { read: MatAutocompleteTrigger, static: true }) triggerAutocompleteInput: MatAutocompleteTrigger;
  @ViewChild('inputField', { static: true }) inputField: ElementRef;

  public searchText: string = '';
  public filteredData: any[] = [];
  public changing: boolean = false;
  private maxOptionsRendered = 500;

  public uuid: string = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  constructor() { }

  @Input()
  public set selectedData(data: any) {
    this._selectedData = data;
    //this is for setting default data if cycling through from an external component (ex: Initiative Dialog on EPM)
    if (this.selectIfOneItem && this.filteredData.length === 1 && !this._selectedData) {
      this.setData(this.filteredData[0]);
    }

    this.searchText = this.getDisplayTextFromSelection();
  }

  @Input()
  set data(data: any[]) {
    this._data = data ? data : [];
    if (this._data.length > 0) {
      if (this.selectIfOneItem) {
        this.filteredData = Object.assign([], this._data).slice(0, this.maxOptionsRendered);
         setTimeout(() => {  //just assigned the data and make sure we set the data on next cycle to avoid ExpressionChangedAfterItHasBeenCheckedError
          this.filteredData = Object.assign([], this.getSelectableData()).slice(0, this.maxOptionsRendered);
          if (this.filteredData.length === 1 && !this._selectedData) {
            this.setData(this.filteredData[0]);
          }
          
         });
      } else {
        setTimeout(() => { if (this.sort) this.displayProperty && this._data[0][this.displayProperty] ? this._data = _.sortBy(this._data, [this.displayProperty]) : this._data.sort(); });
        if (this.mustStartTyping === false) {
          setTimeout(() => { this.filteredData = Object.assign([], this.getSelectableData()).slice(0, this.maxOptionsRendered); });
        }
      }
      this.searchText = this.getDisplayTextFromSelection();  //one more time in case this data came late.
      this.setAutoWidth();
    }
    else {
      setTimeout(() => { this.filteredData = Object.assign([], this.getSelectableData()).slice(0, this.maxOptionsRendered); });
    }
  }

  ngOnInit() {
    this.searchText = this.getDisplayTextFromSelection();
  }

  public setAutoWidth(): void {
    if (this.autoWidth === true) {
      let max: number = 5;
      this._data.forEach((element: any) => {
        let val = this.getDisplayText(element);
        if (val && val.length > max) {
          max = val.length;
        }
      });

      this.panelWidth = max + 'em';
    }
  }

  public filterData(): void {
    this.changing = true;
    if (!this.searchText || !this.searchText.length) {
      this.searchText = '';
    }

    this.filteredData = this.getSelectableData().filter((dataElement: any): boolean => {
      if (!dataElement || !this.getDisplayText(dataElement)) {
        return false;
      }
      if (!this.searchText || !this.searchText.toLowerCase) {
        return true;
      }

      return (this.getDisplayText(dataElement).toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1);
    });
    this.filteredData = this.filteredData.slice(0, this.maxOptionsRendered);
  }

  private getSelectableData(): any[] {
    return this.selectableFunc && typeof this.selectableFunc === 'function' ? this._data.filter(item => this.selectableFunc(item)) : this._data;
  }

  public selectData(evt: MatAutocompleteSelectedEvent): void {
    this.setData(evt.option.value);
  }

  private setData(data: any): void {
    if (data && this.resultProperty && data[this.resultProperty]) {
      data = data[this.resultProperty];
    }

    this.changing = false;
    this._selectedData = data;
    this.searchText = this.getDisplayTextFromSelection();
    this.selectedDataChange.emit(this._selectedData);
    this.valueChanged.emit(this._selectedData);
  }

  public blur(event?: FocusEvent): void {
    if (this.changing && this.filteredData.length > 0) {
      if (this.searchText && this.searchText !== '') {
        //attempt to look at the highlighted value and select on tab instead of just enter.
        if (this.triggerAutocompleteInput.activeOption && this.triggerAutocompleteInput.activeOption.value) {
          this.setData(this.triggerAutocompleteInput.activeOption.value)
        } else {
          this.setData(this.filteredData[0]);
        }
      //recently adding this to replace commented code below.  We are having issues where someone deletes the text in the autocomplete field
      //and tabs off (they could use the 'x' button, but don't).  Since we do not have this else code, it appears that the field is empty, but 
      //it is not because it triggers none of the clear functionality and the data really is never saved.  The only other option we have here is
      //to set the searchText back to the 'getDisplayTextFromSelection()' which effectively reverts what they just did.  This seems more appropriate
      //as they are probably intending to delete/clear the field.  Tested in EPM/Opdata and base App.
      } else {
        this.clear();

        //Commenting this out for now - we have an issue with typing something in and deleting it all, tabing off and the first item in the
        //list getting selected.  This was commented out to remove that behavior.  We wanted and noticed this in the EPM project.  It affected
        //the new autocomplete-multi as well as noticing it was undesired on regular autocomplete fields.  We verified in opdata that this was
        //also weird and unwanted.
      // } else {
        // if (this.triggerAutocompleteInput.activeOption && this.triggerAutocompleteInput.activeOption.value) {
        //   this.setData(this.triggerAutocompleteInput.activeOption.value)
        // } else {
        //   this.setData(undefined);
        // }
      }
    } else if (this.changing && !this.restrictToList && this.searchText !== '') {
      this.setData(this.searchText);
      //Added this else on EPM project.  Noticed that if autocomplete had a value in it and then you typed something not in the list, it would be "accepted"
      //it would not update the model, but the text in the search would stay as you typed and the model would be unchanged.  we have 2 good choices, clear out the model 
      //if an an invalid seach text is entered or revert the typed text to the current model description.  I chose to clear it out.
    } else if (this.changing && this.filteredData.length == 0 && this.restrictToList) {
      this.clear();
    }

    if (this.restrictToList && this._selectedData == undefined) {
      this.searchText = undefined;
    }
  }

  public onFocus(event: FocusEvent) {
    this.focus.emit(event);
  }

  public clear(): void {
    setTimeout(() => {
      this.inputField.nativeElement.value = '';
      this.searchText = '';
      this.filterData();
      this.setData(undefined);
    });
  }

  //It is important to use this arrow function method from the template as we need to bring along the context
  get displayWith() {
    return (val) => this.getDisplayText(val);
  }

  private getDisplayTextFromSelection() {
    let data = this._selectedData;

    if (data) {
      if (this.resultProperty && this._data) {
        let result = this._data.find(element => element[this.resultProperty] == data);
        // if (!result && typeof data == 'string') {  //data really should not be this way, we should not have to support here.
        //   result = this._data.find(element => element[this.resultProperty].toString().toLowerCase() == data.toString().toLowerCase());
        // }
        if (result && result[this.displayProperty]) {
          return result[this.displayProperty];
        }
      } else if (this.displayProperty && data[this.displayProperty]) {
        return data[this.displayProperty];
      }
      return data.toString();
    }
    return "";
  }

  public getDisplayText(data: any): string {
    if (data && this.displayProperty && data[this.displayProperty]) {
      return data[this.displayProperty];
    }
    if (data) {
      return data.toString();
    }
    return "";
  }

}
