import {Observable} from "rxjs";
import {InjectionToken} from "@angular/core";

export const LOGIN_SERVICE = new InjectionToken<number>('loginService');

export class LoginResult {
  constructor(success: boolean, userName: string, message: string) {
    this.success = success;
    this.errorMessage = message;
    this.userName = userName;
  }

  success: boolean = false;
  errorMessage?: string;
  userName?: string
}

export interface CadLoginCallbackService {
  loginUser(): Observable<any>;
  logoutUser(): Observable<any>;
}
