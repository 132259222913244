<div fxFlex class="card-container">
  <mat-card appearance="outlined" fxFlex fxLayout="column">
    <mat-card-header fxLayout="row">
      <mat-card-title>
        Roles
      </mat-card-title>
      <!-- <button mat-mini-fab class="fab-button" (click)="newRole()" [disabled]="!isAppAdmin">
        <mat-icon>add</mat-icon>
      </button> -->
    </mat-card-header>
    <mat-card-content fxFlex fxLayout="column">
      <div fxLayout="column" class="outer">
        <!--(rowSelected)="rowSelected($event)"-->
        <div fxFlex fxFill fxLayout="row">
            <div fxFlex style="overflow: hidden; flex-grow: 1">
                <ag-grid-angular
                [gridOptions]="gridOptions"
                [animateRows]="true"
                [columnDefs]="columnDefs"
                [columnTypes]="columnTypes"
                [defaultColDef]="defaultColDef"
                (gridReady)="onGridReady($event)"
                [enableCellChangeFlash]="true"
                [rowData]="rowData"
                rowSelection="single"
                (rowSelected)="onRowSelected($event)"
                (cellValueChanged)="cellValueChanged($event)">
                </ag-grid-angular>
            </div>
        </div>
    </div>
    </mat-card-content>
  </mat-card>
</div>
