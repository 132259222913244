import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {CadLoginDialogService} from '@comm-apps/shared-ui';
import {CARUserService} from '../../services/user.service';
import {LogService} from '@comm-apps/log';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {

  constructor(private userService: CARUserService, private router: Router, private loginSvc: CadLoginDialogService, private log: LogService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    if (this.userService.isLoggedIn()) {
      return of(true);
    }

    return this.userService.loginUser().pipe(
      map(user => {
        if (!user || !user.userName) {
          this.loginSvc.login();
        }
        this.log.debug ('Login looks good, we are letting you in', user);
        return true;
      }),
      catchError(() => {
          this.router.navigate(['/not-authorized']);
          return of(false)
        }
      ));
  }
}
