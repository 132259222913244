import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { numberListValidator } from '../validators/number-list.validator';

@Directive({
  selector: '[numberList]',
  providers: [{provide: NG_VALIDATORS, useExisting: NumberListDirective, multi: true}]
})
export class NumberListDirective implements Validator {

  validate(control: AbstractControl): ValidationErrors {
    return numberListValidator()(control);
  }

}
