import { Injectable } from '@angular/core';
import {ActivatedRoute, ActivationEnd, Router, RoutesRecognized} from '@angular/router';
import {filter} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
/*
Import me as a service and call init from you app when you initialize
 */
export class RouteHelperService {
  private history = [];
  private MAX_SIZE: number = 20;

  public currentRouteData$: BehaviorSubject<any> = new BehaviorSubject<any>({});

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  /**
   * Init should only be called once and any subs called here <i>should</i> not need to be unsubed.
   */
  public init(): void {

    this.router.events
    //Not using NavigationEnd as we want to capture routes that are also blocked by the RouteGuard for purposes of authentication and then going back to originating URL.
      // .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(filter(event => event instanceof RoutesRecognized))
      .subscribe(({urlAfterRedirects}: RoutesRecognized) => {
        this.history = [urlAfterRedirects, ...this.history];
        this.history = this.history.slice(0, this.MAX_SIZE);
      });

    //sub for watching current Route Data.
    this.router.events
      .pipe(filter(event => event instanceof ActivationEnd))
      .subscribe((event: ActivationEnd) => {
        let snapshot = event.snapshot;

        while (snapshot.firstChild) {
          snapshot = snapshot.firstChild;
        }

        this.currentRouteData$.next(snapshot.data);
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[1] || '';
  }

  public getCurrentUrl(): string {
    return this.history[0] || '';
  }

  public getCurrentUrlWithoutParams(): string {
    return this.getCurrentUrl().split('?')[0];
  }

  public getQueryParam(param: string): any {
    return this.activatedRoute.snapshot.queryParamMap.get(param);
  }

  public gotoPrevious() {
    this.goToRoute(this.getPreviousUrl());
  }

  public goToRoute(route: string) {
    this.router.navigate([route]);
  }

  /**
   * Similar to a refresh, but without loading the application from scratch - basically we go to a route and then back to another.
   * @param tempRoute - temporary route you wish to go to.  This needs to be defined, should be easy to load and may be seen
   */
  public reGoToRoute(tempRoute: string) {
    let current: string =  this.getCurrentUrlWithoutParams();
    const queryParams: any = {};
    this.activatedRoute.snapshot.queryParamMap.keys.forEach(key => {
      queryParams[key] = this.activatedRoute.snapshot.queryParamMap.get(key);
    });
    this.router.navigate([tempRoute], { skipLocationChange: true }).then(() => {
      this.history.shift(); //remove tempRoute
      this.router.navigate([current], { queryParams });
  });
  }
}

