import {Component, Inject, OnInit} from '@angular/core';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import {cad} from '@comm-apps/common';
import Settings = cad.Settings;
import Environment = cad.Environment;

@Component({
  selector: 'cad-lib-new-version-snackbar',
  templateUrl: './new-version-snackbar.component.html',
  styleUrls: ['./new-version-snackbar.component.scss']
})
export class NewVersionSnackbarComponent implements OnInit {
  public sbRef: MatSnackBarRef<NewVersionSnackbarComponent>;

  public dismiss(): void {
    this.sbRef.dismiss();
  }

  constructor(@Inject('environment') public env: Environment) { }

  ngOnInit() {
  }

}
