import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import { AppSettings } from '../../../app.settings';
import {AutoUnsubscribables, AutoUnsubscriber, cad, RouteHelperService} from '@comm-apps/common';
import Settings = cad.Settings;
import {LogService} from '@comm-apps/log';
import {CARUserService} from '../../../services/user.service';
import {CAREnvironment} from '../../classes/car-environment';
import {CARUser} from '../../classes/data-types';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Roles } from '../../../models/roles.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'cad-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
  @Output()
  toggle = new EventEmitter();
  public toggleSearchBar = false;
  public userName: string;
  public searching: boolean = false;
  public canRunAs: boolean = false;

  public smallScreen: boolean = false;

  public settings: Settings;

  @AutoUnsubscriber()
  private subs: AutoUnsubscribables;

  constructor(
    public appSettings: AppSettings,
    private log: LogService,
    private userService: CARUserService,
    private breakpointObserver: BreakpointObserver,
    private routeHelper: RouteHelperService,
    private router: Router,
    @Inject('environment') public env: CAREnvironment,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.subs.newSub = this.userService.currentUser$.subscribe((user: CARUser): void => {
      if (!this.userName) {
        this.setName(user);
        this.setCanRunAs(user);
      }
      this.setName(user);
    });

    this.subs.newSub = this.breakpointObserver.observe('(max-width: 475px)').subscribe(result => {
      if (result.matches) {
        this.smallScreen = true;
      } else {
        this.smallScreen = false;
      }
    });  }

  toggleSidenav(): void {
    this.toggle.emit();
  }

  public loginComplete(user: string): void {
    //This is a good place to let your user service know, if you have one
    this.log.debug(`(Toolbar)User logged in - ${user} - we have stored their basic credentials in the http-helper service for external calls.`);
  }
  public logoutComplete(user: string): void {
    //This is a good place to let your user service know, if you have one
    this.log.debug(`(Toolbar)User logged out - ${user}`);
  }
  public actionPerformed(action: string) {
    this.log.debug(`An action was performed, you may want to do something - Action:${action}`);
  }

  public startSearch(): void {
    this.searching = true;
    setTimeout(() =>{this.searching = false;}, 2000);
  }

  private setName(user: CARUser): void {
    if (user.lastName) {
      this.userName = user.firstName + " " + user.lastName;
    } else {
      this.userName = user.userName
    }
  }

  private setCanRunAs(user:CARUser) {
    this.canRunAs = this.env.runAs && user.roles.indexOf(Roles.ADMIN) >= 0
  }

  public runAsComplete(user: string) {
    this.log.info('Run as user ' + user);
    this.userService.loginUser();
  }

  public stopRunAsComplete(event: any) {
    this.log.info('Stopping run as');
    this.userService.logoutUser();
    this.userService.loginUser();
  }

  public currentUser(): string {
    return this.userService.currentUser$.getValue().userName;
  }

  public helpClicked(event: any): void {
    const helpBaseUrl = 'https://sunocoinc.sharepoint.com/teams/etp-it-pmo/SitePages';
    const mainHelpUrl = `${helpBaseUrl}/Main.aspx`;
    switch (this.routeHelper.getCurrentUrlWithoutParams()) {
      case '/not-authorized':
        this.showHelp(`${helpBaseUrl}/Security.aspx`);
        break;
      case '/my-items':
      case '/all-projects':
      case '/active-items':
        switch (this.routeHelper.getQueryParam('tab')) {
          case 'Dashboard':
            this.showHelp(`${helpBaseUrl}/Dashboard.aspx`);
            break;
          case 'Charter':
            this.showHelp(`${helpBaseUrl}/CharterEntry.aspx`);
            break;
          case 'Status':
            this.showHelp(`${helpBaseUrl}/Status.aspx`);
            break;
          case 'Related Initiatives':
            this.showHelp(`${helpBaseUrl}/RelatedInitiatives.aspx`);
            break;
          case 'Change Request':
            this.showHelp(`${helpBaseUrl}/ChangeRequests.aspx`);
            break;
          case 'Risk':
            this.showHelp(`${helpBaseUrl}/Risks.aspx`);
            break;
          case 'Issues':
            this.showHelp(`${helpBaseUrl}/Issues.aspx`);
            break;
          case 'Documents':
            this.showHelp(`${helpBaseUrl}/Documents.aspx`);
            break;
          case 'Budget Tracking':
            this.showHelp(`${helpBaseUrl}/BudgetTracking.aspx`);
            break;
          case 'Workflow':
            this.showHelp(`${helpBaseUrl}/Workflow.aspx`);
            break;
          case 'PMO':
            this.showHelp(`${helpBaseUrl}/PMO.aspx`);
            break;
          default:
            this.showHelp(mainHelpUrl);
            break;
        }
        break;
      case '/initiatives':
      case '/finance-dash':
      default:
        this.showHelp(mainHelpUrl);
        break;
    }
  }

  showHelp(url): void {
    window.open(url, '_blank');
  }

}
