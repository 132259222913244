<cad-lib-dialog-wrapper title="Application Configuration" (closeClick)="cancel()" fxLayout="column">
  <form #form="ngForm" (ngSubmit)="save()">
    <div mat-dialog-content fxFlex fxLayout="column" >
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input
          matInput
          required
          maxlength="50"
          name="name"
          [(ngModel)]="applicationConfig.name"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Value</mat-label>
        <textarea
          matInput
          required
          maxlength="4000"
          name="value"
          [(ngModel)]="applicationConfig.value"
        >
        </textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Type</mat-label>
        <input
          matInput
          required
          maxlength="50"
          name="type"
          [(ngModel)]="applicationConfig.type"
        />
      </mat-form-field>
    </div>
</form>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="left">
  <div fxFlex fxLayoutAlign="end">
    <button type="submit" mat-raised-button color="primary" (click)="save()" [disabled]="!form.form.valid" >Save</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>
</div>
</cad-lib-dialog-wrapper>

