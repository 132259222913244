import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'cad-lib-run-as-user',
  templateUrl: './run-as-user.component.html',
  styleUrls: ['./run-as-user.component.scss']
})
export class RunAsUserComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<RunAsUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public userId: string;

  ngOnInit() {}

  public login(): void {
    localStorage.setItem('X-On-Behalf-Of', this.userId);
    this.closeDialog(this.userId);
  }

  closeDialog(rv: string): void {
    this.dialogRef.close(rv);
  }

}
