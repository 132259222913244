
import { Component, Inject, OnInit } from '@angular/core';
import { AutoUnsubscriber, AutoUnsubscribables } from '@comm-apps/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { combineLatest, mergeMap, of } from 'rxjs';
import * as _ from 'lodash-es';
import { AllUser } from '../../classes/data-types';
import { CodeDataService } from '../../../services/code-data.service';
import { AllUserService } from '../../../services/all-user.service';

@Component({
  selector: 'car-search-user-dialog',
  templateUrl: './search-user-dialog.component.html',
  styleUrls: ['./search-user-dialog.component.scss']
})
export class SearchUserDialogComponent implements OnInit {

  public allUsers: AllUser[];
  public user: AllUser;
  private codeType: string;
  @AutoUnsubscriber() private subs: AutoUnsubscribables;

  constructor(
    private codeValueService: CodeDataService,
    public dialogRef: MatDialogRef<AllUser>,
    private allUserService: AllUserService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      if (data && data.codeType) {
        this.codeType = data.codeType;
      }
   }

  ngOnInit() {
    this.loadAllUsers();
  }

  ok() {
    this.dialogRef.close(this.user);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  private loadAllUsers() {
    this.subs.newSub = this.allUserService.findAll().pipe(
      mergeMap(allUsers => combineLatest([of(allUsers), this.codeType ? this.codeValueService.findByType(this.codeType) : of([])]))
    ).subscribe(([allUsers, codeValues]) => {
      const codeValueStrs = codeValues.map(codeValue => codeValue.code);
      this.allUsers = _.reject(allUsers, user => codeValueStrs.includes(user.userId));
    });
  }

}
