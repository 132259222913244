import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function validateEmail(emailString: string): boolean {
  const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const value = String(emailString).toLowerCase().trim();
  return regex.test(value);
}

export function emailAddressValidator(): ValidatorFn {
  return (control:AbstractControl) : ValidationErrors | null => {
        let valid = validateEmail(control.value);
        return (!valid && control.value?.length > 0) ? {emailAddress: {value: control.value, message: 'Not a valid email'}}: null;
    }


}
