import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AlertService } from '@comm-apps/alert';
import { Router } from '@angular/router';
import { CacheService } from 'ng2-cache';

@Injectable({
  providedIn: 'root'
})
export class HttpHelperService {
  private baseUrl: string;
  private httpOptions: {
    headers: HttpHeaders;
    observe: string;
    responseType: string;
    reportProgress: boolean;
    body: {};
    withCredentials: boolean;
    quiet: string;
    search: string;
    params: {};
  };

  constructor(
    private httpClient: HttpClient,
    public alerts: AlertService,
    @Inject('baseURL') endpointUrl: string,
    private router: Router,
    private cacheService: CacheService
  ) {
    this.baseUrl = endpointUrl;
  }

  static getRequestMethod(options: any): string {
    return options && options.method
      ? options.method
      : options.body === undefined
        ? 'GET'
        : 'POST';
  }

  static createDefaultOptions(options: any): any {
    return {
      headers: options.headers ? options.headers : new HttpHeaders(),
      observe: 'body',
      responseType: options.responseType ? options.responseType : 'json',
      reportProgress: options.reportProgress ? options.reportProgress : false,
      body: options.body ? options.body : undefined,
      withCredentials: options.withCredentials
        ? options.withCredentials
        : false,
      quiet: options.quiet ? options.quiet : undefined,
      params: options.search ? options.search : undefined
    };
  }

  public request<T = any>(endpoint: string, options: any = {}): Observable<T> {
    const finalOptions = HttpHelperService.createDefaultOptions(options);
    const requestMethod = HttpHelperService.getRequestMethod(options);
    const fullUrl = this.getFullUrl(endpoint);
    return this.httpClient
      .request(requestMethod, fullUrl, finalOptions)
      .pipe(catchError(this.handleError));
  }

  public requestOrCache<T = any>(ageMins: number, endpoint: string, options: any = {}): Observable<T> {
    const finalOptions = HttpHelperService.createDefaultOptions(options);
    const requestMethod = HttpHelperService.getRequestMethod(options);
    const fullUrl = this.getFullUrl(endpoint);

    if (options.body) {
      throw Error("Cannot cache items that have a body.");
    }

    let val: any = this.getCache(fullUrl);
    if (val && !(val instanceof Array && val.length == 0)) {
      return of(val);
    }

    return this.httpClient
      .request(requestMethod, fullUrl, finalOptions)
      .pipe(catchError(this.handleError))
      .pipe(map(result => {
          if (result) this.setCache(fullUrl, result, ageMins);
          return result;
      }));
  }

  public clearCache(endpoint: string) {
    const fullUrl = this.getFullUrl(endpoint);
    this.cacheService.remove(fullUrl);
  }

  private setCache(key: string, val: any, mins: number): void {
    this.cacheService.set(key, val, {maxAge: mins * 60});
  }

  private getCache(key: string): void {
    return this.cacheService.get(key);
  }

  public getFullUrl(request: string): string {
    return this.baseUrl + request;
  }

  private routeToNoAuth(): void {
    for (let childRoute of this.router.config[0].children) {
      if (childRoute.path === 'not-authorized') {
        this.router.navigateByUrl('/not-authorized');
      }
    }
  }

  private handleError = (error: HttpErrorResponse) => {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.

      console.error('An error occurred:', error.error.message);
      this.alerts.danger(error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if (error.status) {
        switch (error.status) {
          case 401:
          case 403:
            this.alerts.danger(
              'You were not authorized to complete the action'
            );
            this.routeToNoAuth();
            break;
          case 404:
            this.alerts.danger('The action selected was not found');
            break;
          case 500:
            if (error.error.message) {
              this.alerts.danger(error.error.message, error);
            } else if (error.error instanceof Blob) {
              let reader = new FileReader();
              reader.addEventListener("loadend",()  =>  {
                let message = JSON.parse(reader.result.toString()).message;
                this.alerts.danger(message, error);
              });
              reader.readAsText(error.error);
            } else if (error.message) {
              this.alerts.danger(error.message, error);
            }
            break;
          default:
            this.alerts.danger('An unhandled error occurred ' + error.status);
        }
      } else {
        this.alerts.danger('An unhandled error occurred ' + error.status);
      }
    }
    // return an observable with a user-facing error message
    throw error;
    return new Observable<any>();
    // return throwError(
    //   'Something bad happened; please try again later.');
  };
}
