import { Component } from '@angular/core';
import { AppSettings } from '../../app.settings';
import { cad } from '@comm-apps/common';
import Settings = cad.Settings;


@Component({
  selector: 'cad-simple-view',
  templateUrl: './simple-view.component.html',
  styleUrls: ['./simple-view.component.scss'],
})
export class SimpleViewComponent {

  public settings: Settings;

  constructor(
    public appSettings: AppSettings,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    }, 300);

  }
}
