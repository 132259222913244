import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatRadioChange, MatRadioGroup} from "@angular/material/radio";

@Component({
  selector: 'cad-lib-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit {
  // Label above the component
  @Input()
  public placeholder: string = '';
  @Input()
  public disabled: boolean = false;
  @Input()
  public required: boolean = false;
  public modelValue: any;
  @Input()
  public data: any[] = [];
  @Input()
  public resultProperty: string;
  @Input()
  public displayProperty: string;
  @Input()
  public set selectValue(value: any) {
    this.modelValue = value;
  }
  @Output()
  selectValueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  public useTrueFalse: boolean = false;

  @ViewChild(MatRadioGroup) 
  public matRadioGroup: MatRadioGroup;

  public uuid: string = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  constructor() {
  }

  ngOnInit(): void {
    this.setUpTrueFalse(this.useTrueFalse);
  }

  radioChange(event: MatRadioChange) {
    this.selectValueChange.emit(event.value)
  }

  setUpTrueFalse(status: boolean): void {
    if (status) {
      this.data = [{
        code: true,
        description: "Yes"
      },
        {
          code: false,
          description: "No"
        }];
      this.resultProperty = 'code';
      this.displayProperty = 'description';
    }
  }

}
