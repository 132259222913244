import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LogService } from '@comm-apps/log';
import { AppGuard } from './app/app.guard';
import { map } from 'rxjs/operators';
import { CARUserService } from "../services/user.service";
import { Roles } from '../models/roles.enum';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private userSvc: CARUserService, private log: LogService, private router: Router, private appGuard: AppGuard) { }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    this.log.debug("-- Checking the user Roles");

    return this.appGuard.canActivate(next, state).pipe(map(res => {
      if (res) {
        let canNav = this.userSvc.currentUser$.getValue().roles.indexOf(Roles.ADMIN) >= 0 ||
          this.userSvc.currentUser$.getValue().roles.indexOf(Roles.CODE_ADMIN) >= 0;
       
        if (!canNav) {
          this.log.warn("This user does not have permissions to view the admin section.");
          this.router.navigate(['/not-authorized']);
        }
        return canNav;
      }
      return res;
    }));
  }
}
