import {CAREnvironment} from "../app/core/classes/car-environment";

export const environment: CAREnvironment = {
  production: true,
  localDevelopment: false,
  baseUrl: '',
  logLevel: 'ERROR',
  name: 'PROD',
  runAs: false,
};
