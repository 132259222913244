import { Injectable } from '@angular/core';
import {HttpHelperService} from '@comm-apps/http';
import {Observable} from 'rxjs';
import {UserActivity, UserActivitySearch, UserActivitySummary} from '../core/classes/user-activity';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {
  constructor(private apiHelper: HttpHelperService) { }

  public search(userActivitySearch: UserActivitySearch): Observable<UserActivitySummary[]> {
    return this.apiHelper.request('api/user-activity/filter', { body: userActivitySearch });
  }

  public userDetail(userId: string, from: Date, to: Date): Observable<UserActivity[]> {
    let search: UserActivitySearch = new UserActivitySearch();
    search.userId = userId;
    search.from = from;
    search.to = to;

    return this.apiHelper.request('api/user-activity/actions', { body: search });
  }
}
