import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef, HostListener, Renderer2} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: 'input[currency]',
  providers: [
    CurrencyPipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyDirective),
      multi: true
    }
  ]
})
export class CurrencyDirective implements ControlValueAccessor {
  private onChange: (value: any) => void;
  private onTouched: () => void;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private currencyPipe: CurrencyPipe
  ) {}

  writeValue(value: any): void {
    this.renderer.setProperty(this.el.nativeElement, 'value', this.getFormattedValue(value));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void{
    this.renderer.setProperty(this.el.nativeElement, 'disabled', isDisabled);
  };

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.onChange(this.clearCurrencyMarkup(value));
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value: string): void {
    this.formatAsCurrency(value);
    this.onTouched();
  }

  private formatAsCurrency(value: string | null): void {
    const numericValue = parseFloat(this.clearCurrencyMarkup(value));
    if (!isNaN(numericValue)) {
      const formattedValue = this.getFormattedValue(numericValue.toString());
      this.renderer.setProperty(this.el.nativeElement, 'value', formattedValue);
      this.onChange(numericValue);
    }
  }
  
  private getFormattedValue(val: string | null): string | null {
    if (val === null || val === undefined) {
      return null;
    }
    let formattedValue = this.currencyPipe.transform(this.clearCurrencyMarkup(val), '$');
    return formattedValue;
  }

  private clearCurrencyMarkup(input: any): string {
    return typeof input === 'string' ? (<any>input).replaceAll('$', '').replaceAll(',', '') : input;
  }
}
