<cad-lib-dialog-wrapper title="Code Value" (closeClick)="cancel()" fxLayout="column">
  <form #form="ngForm">
    <div mat-dialog-content fxFlex fxLayout="column" >
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <mat-label>Code</mat-label>
          <input
          matInput
          required
          maxlength="25"
          name="code"
          [(ngModel)]="codeValue.code"
          />
          <button type="button" *ngIf="userType" mat-icon-button aria-label="Search User" matSuffix (click)="searchUser()">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input
          matInput
          required
          maxlength="100"
          name="codeDescription"
          [(ngModel)]="codeValue.description"
        />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Sort Order</mat-label>
        <input
          matInput
          required
          type="number"
          name="sortOrder"
          [(ngModel)]="codeValue.sortOrder"
        />
      </mat-form-field>

      <mat-checkbox matInput name="enabled" labelPosition="before" [(ngModel)]="codeValue.enabled">Enabled</mat-checkbox><br/>

      <cad-lib-auto-complete
        placeholder="Code Value Type"
        [required]="true"
        [disabled]="disableCodeType"
        [data]="codeValueTypes"
        [(selectedData)]="codeValue.type">
      </cad-lib-auto-complete>

    </div>
</form>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="left">
  <div fxFlex fxLayoutAlign="end">
    <button type="submit" mat-raised-button color="primary" (click)="save()" [disabled]="!form.form.valid" >Save</button>
    <button mat-raised-button (click)="cancel()">Cancel</button>
  </div>
</div>
</cad-lib-dialog-wrapper>

